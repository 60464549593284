import {Box, Typography} from "@mui/material";
import React from "react";

const HelpBox = () => {

    const helpers = [
        '• Wpisz znak <b>#</b>, jeśli chcesz wybrać pole MARC.',
        '• Dostępne <b>operatory logiczne</b>: <b>=</b> (równa się), <b>!=</b> (różne od), <b>{\'>\'}</b> (większe), <b>{\'>=\'}</b> (większe lub równe), <b>{\'<\'}</b> (mniejsze), <b>{\'<=\'}</b> (mniejsze lub równe), <b>*</b> (zawiera wartość), <b>!*</b> (nie zawiera wartości), <b>exists</b> (zawiera pole), <b>not_exists</b> (nie zawiera pola).',
        '• Możesz wpisać je z klawiatury samodzielnie lub wybrać znak <b>?</b>, aby wybrać operator z listy.',
        '• <b>Ciagi znaków</b> muszą być zawarte w pojedynczych cudzysłowach: <b>\'\'</b> ',
        '• <b>Daty</b> należy podawać <b>bez cudzysłowów</b> w formacie <b>RRRR-MM-DD</b>.',
        '• Każdy warunek musi być zawarty w <b>nawiasach klamrowych oddzielonych spacją</b>.',
        '• Przykładowe zapytanie 1: <b>{ 380_a * \'E-booki\' }</b>',
        '• Przykładowe zapytanie 2: <b>{ { publication_date > 2020 } & { publication_date < 2022 } }</b>',
        '• Przykładowe zapytanie 3: { 245_a = \'Lem\' } & { 380_a * \'Książki\' }',
        '• Przykładowe zapytanie 4: { 246 not_exists }'
    ]

    return <Box className={'help-box'}>
        {helpers.map((helper, idx) => <Typography key={idx} variant={'caption'}
                                                  className={'editor-rules'}
                                                  dangerouslySetInnerHTML={{__html: helper}}/>)}

    </Box>
}
export default HelpBox