import {Grid} from "@mui/material";
import {ATTRIBUTES_ORDER, DescriptorInfo, HIDDEN_FIELDS, SPECIFIC_FIELDS} from "consts/khw-consts";
import {formatValue, translateKey} from "utils/fieldsFormatters";
import React, {useContext} from "react";
import {LanguageContext} from "context/LanguageContext";
import {cleanCoords} from "utils/cleanCoords";
import {renderSubjectCategoryLink} from "../../utils/renderUrls";

const BoxAccordion = React.lazy(() => import("components/common/BoxAccordion"));
const InfoGridRow = React.lazy(() => import("components/descriptorInfo/InfoGridRow"));

interface DescriptorDetailInfoCardProps {
    data: DescriptorInfo
}

const DescriptorDetailInfoCard = ({data}: DescriptorDetailInfoCardProps) => {
    const {language} = useContext(LanguageContext);
    const {jsonFieldValues} = data

    const renderMissingRows = () => Object.keys(jsonFieldValues).filter(jsonField => !ATTRIBUTES_ORDER.includes(jsonField) && !HIDDEN_FIELDS.includes(jsonField) && !SPECIFIC_FIELDS.includes(jsonField)).map(renderRow)

    const renderRows = () => ATTRIBUTES_ORDER.map(attribute => renderRow(attribute))

    const renderRow = (attribute: string) => {
        const jsonField = jsonFieldValues[attribute]
        if (!jsonField) return
        const label = translateKey(attribute, language)
        const {simpleValue, complexValues, arrayValue} = formatValue(attribute, jsonField)
        if (complexValues) {
            return <InfoGridRow key={attribute} label={label} complexValues={complexValues}/>
        } else if (arrayValue) {
            if (attribute === 'subjectCategory'){
                return <InfoGridRow key={attribute} label={label} arrayValue={arrayValue} linkPattern={renderSubjectCategoryLink}/>
            }
            return <InfoGridRow key={attribute} label={label} arrayValue={arrayValue}/>
        }
        return <InfoGridRow key={attribute} label={label} simpleValue={simpleValue}/>

    }

    const renderSpecificFields = () => {
        return SPECIFIC_FIELDS.filter(field => Object.keys(jsonFieldValues).includes(field)).map(field => {
            if (field === 'location_points') { // here another specific fields can be added as ifs
                const label = translateKey(field, language)
                const [longitude, latitude] = cleanCoords(jsonFieldValues[field] as string[])
                const valuesArray = [`${translateKey('latitude', language)}: ${latitude}`, `${translateKey('longitude', language)}: ${longitude}`]
                return <InfoGridRow key={field} label={label} arrayValue={valuesArray}/>
            }
            return <></>
        })
    }

    return <BoxAccordion header={'detailInfo'} showBorderedCard
                         tooltipText={translateKey('detailInfoTooltip', language)}>
        <Grid container rowSpacing={0} columnSpacing={2} className={'detail-grid'}>

            {renderRows()}
            {renderMissingRows()}
            {renderSpecificFields()}
        </Grid>
    </BoxAccordion>

}

export default DescriptorDetailInfoCard