import {Box, Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Grid} from "@mui/material";
import React, {useContext} from "react";
import {SectionProps} from "./Section1";
import {SectionHeaderTypography} from "./common/SectionHeaderTypography";
import {SectionTypography} from "./common/SectionTypography";
import {translateKey} from "utils/fieldsFormatters";
import {LanguageContext} from "context/LanguageContext";

export const Section2 = ({title}: SectionProps) => {
    const {language} = useContext(LanguageContext);
    const sectionParagraphsBeforeTable = ['documentationSection2_1', 'documentationSection2_2']
    const sectionParagraphsAfterTable = ['documentationSection2_3', 'documentationSection2_4', 'documentationSection2_5']

    const createData = (
        id: string,
        marc: string,
    ) => {
        return {id: translateKey(id, language), marc};
    }

    const rows = [
        createData('tableRow1', '1XX'),
        createData('tableRow2', '378, 4XX'),
        createData('tableRow3', '001, 009, 010, 022, 024'),
        createData('tableRow4', '670, 675, 680'),
    ];


    const renderTable = () => <Grid item xs={12}>
        <TableContainer>
            <Table sx={{minWidth: 650}} aria-label="table-1">
                <TableHead>
                    <TableRow>
                        <TableCell className={'table-header'}>{translateKey('index', language)}</TableCell>
                        <TableCell className={'table-header'}>{translateKey('MARCField', language)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell>{row.id}</TableCell>
                            <TableCell>
                                {row.marc}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>


    return <section>

        <Box className={'docs-section'}>
            <SectionHeaderTypography>{title}</SectionHeaderTypography>
            {sectionParagraphsBeforeTable.map((paragraph, idx) =>
                <SectionTypography key={`section-2-${idx}`}>{translateKey(paragraph, language)}</SectionTypography>)}

            {renderTable()}
            {sectionParagraphsAfterTable.map((paragraph, idx) =>
                <SectionTypography key={`section-2-2-${idx}`}>{translateKey(paragraph, language)}</SectionTypography>)}

        </Box>
    </section>
}