import {Box, LinearProgress, Typography} from "@mui/material";
import {Link, Element} from 'react-scroll'

import React, {lazy, Suspense, useContext} from "react";
import {useWindowHeight} from "@react-hook/window-size/throttled";
import {Section1, SectionProps} from "./Section1";
import {Section2} from "./Section2";
import {Section3} from "./Section3";
import {Section4} from "./Section4";
import {Section5} from "./Section5";
import {Section6} from "./Section6";
import {Section7} from "./Section7";

import {translateKey} from "utils/fieldsFormatters";
import {LanguageContext} from "context/LanguageContext";
import SEO from "../common/SEO";

const BreadcrumbsRow = lazy(() => import("components/common/BreadcrumbsRow"));
const EmptyDivider = lazy(() => import("components/common/EmptyDivider"));

const Documentation = () => {
    const {language} = useContext(LanguageContext);
    const height = useWindowHeight()

    const CONTENTS_HEADERS = ['documentationHeader1', 'documentationHeader2', 'documentationHeader3', 'documentationHeader4', 'documentationHeader5', 'documentationHeader6', 'documentationHeader7']
    const CONTENTS_SECTIONS = [Section1, Section2, Section3, Section4, Section5, Section6, Section7]

    const onKeyDown = (eventKey: string, idx: number) => {
        if (eventKey === 'Enter') {
            const el = document.getElementById(`section-${idx}`)
            if (el) {
                el.focus()
            }
        }
    }
    const renderContentsSection = (idx: number, header: string, section: ({title}: SectionProps) => JSX.Element) =>
        <Element name={`section-${idx}`} className={'contents-element'} key={idx} tabIndex={0} id={`section-${idx}`}
        >
            {React.createElement(section, {title: header})}
        </Element>

    const renderContentsLink = (idx: number, title: string) => <Link activeClass="active" to={`section-${idx}`}
                                                                     onKeyDown={(e) => onKeyDown(e.key, idx)}
                                                                     key={idx} tabIndex={0}
                                                                     spy={true} smooth={true} duration={250}
                                                                     containerId="sectionContainer"
                                                                     className={'docs-link'}>
        {title}
    </Link>

    return <div>
        <SEO description={'Przeglądaj dokumentację serwisu Deskryptory Biblioteki Narodowej.'}/>
        <Box className={'left-margin-8'}>
            <EmptyDivider size={'small'}/>
            <Suspense fallback={<Box sx={{width: '100%'}}>
                <LinearProgress/>
            </Box>}><BreadcrumbsRow content={translateKey('Documentation', language)}/></Suspense>
        </Box>

        <div className={'docs-container'}>
            <Typography className={'contents-typography'}
                        component={'h2'}>{translateKey('contents', language)}</Typography>

            <div className={'row '}>
                <div className="fixed-docs">
                    {CONTENTS_HEADERS.map((header, idx) => renderContentsLink(idx, translateKey(header, language)))}
                </div>
                <Element name={'sections'} className="docs-content" id="sectionContainer"
                         style={{height: 0.7 * height}}>
                    <Typography textAlign={'left'} variant={'h1'}
                                className={'h1-header-no-margin'}>{translateKey('Documentation', language)}</Typography>
                    {CONTENTS_SECTIONS.map((section, idx) => renderContentsSection(idx, translateKey(CONTENTS_HEADERS[idx], language), section))}
                </Element>
            </div>
        </div>
    </div>
}

export default Documentation