import {Box, LinearProgress, Link, List, ListItem} from "@mui/material";

import React, {Suspense, useContext, lazy} from "react";
import {translateKey} from "utils/fieldsFormatters";
import {LanguageContext} from "context/LanguageContext";
import terms from "assets/pdf/dbn_terms_pl_en.pdf"
import policy from "assets/pdf/dbn_policy_pl_en.pdf"
import accesibilityInfo from "assets/pdf/DD_KHW_DBN.pdf"
import SEO from "../common/SEO";

const BreadcrumbsRow = lazy(() => import("components/common/BreadcrumbsRow"));
const EmptyDivider = lazy(() => import("components/common/EmptyDivider"));

const Sitemap = () => {
    const {language} = useContext(LanguageContext);

    const sitesUrls = [
        {url: '/', name: 'Home'},
        {url: '/descriptors', name: 'Descriptors List'},
        {url: '/documentation', name: 'Documentation'},
        {url: terms, name: 'terms'},
        {url: policy, name: 'privacyPolicy'},
        {url: accesibilityInfo, name: 'accessibilityStatement'}
    ]

    return <Box>
        <SEO description={'Mapa serwisu Deskryptory Biblioteki Narodowej.'}/>
        <EmptyDivider size={'small'}/>
        <Suspense fallback={<Box sx={{width: '100%'}}>
        <LinearProgress/>
        </Box>}><BreadcrumbsRow content={translateKey('Sitemap', language)}/></Suspense>
        <h1 className='screen-reader-text'>{translateKey('sitemapScreenReaderHeader', language)}</h1>
        <List style={{marginLeft: 16}}>
            {sitesUrls.map(siteUrl => <ListItem sx={{listStyleType: 'disc', display: "list-item"}}
                                                key={siteUrl.name}><Link
                href={siteUrl.url}
                className={'sitemap-link'}>{translateKey(siteUrl.name, language)}</Link></ListItem>)}
        </List>
    </Box>

}


export default Sitemap