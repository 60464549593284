import {Box, Button, Link, Typography} from "@mui/material";
import {ESRecord, RESULTS_OFFSET, SearchEngineContext} from "consts/search-engine-consts";
import {DataGrid, GridColDef, plPL, enUS} from '@mui/x-data-grid';
import {MentionItem} from "react-mentions";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {LanguageContext} from "context/LanguageContext";
import {translateKey} from "utils/fieldsFormatters";
import {GridRenderCellParams} from "@mui/x-data-grid/models/params/gridCellParams";
import ExportGridToolbar from "../export/ExportGridToolbar";
import MARC21RecordDialog from "../../descriptorInfo/dialogs/MARC21RecordDialog";
import {useCookies} from "react-cookie";


interface SearchResultsListProps {
    hits: ESRecord[]
    mentionFields: MentionItem[]
    total: number
    loadMoreData: () => void
    page: number
}


const SearchResultsList = ({hits, mentionFields, total, loadMoreData, page}: SearchResultsListProps) => {
    const {language} = useContext(LanguageContext);
    const [openDialog, setOpenDialog] = useState(false)
    const [nlpId, setNlpId] = useState('')
    const [pageSize, setPageSize] = useState(10)
    const [cookies] = useCookies(['searchContext'])

    const onMARC21BtnClick = (id: string) => {
        setOpenDialog(true)
        setNlpId(id)
    }
    const renderBNDatabaseBtn = useMemo(() => (params: GridRenderCellParams) => {
        return <Box className={'column'}>
            <Link
                color="primary"
                onClick={() => onMARC21BtnClick(params.row['001'])}
                className={'button-link'}
            >
                {translateKey('seeInMARC21Format', language)}
            </Link>
            <Link
                color="primary"
                href={cookies.searchContext === SearchEngineContext.AUTHORITIES ? `${process.env.REACT_APP_KHW_DESCRIPTOR_DETAILS_URL}/${params.row['001']}` : `https://katalogi.bn.org.pl/discovery/fulldisplay?docid=alma${params.row['mms_id']}&context=L&vid=48OMNIS_NLOP:48OMNIS_NLOP&lang=pl`}
                target={'_blank'}
            >
                {translateKey(cookies.searchContext === SearchEngineContext.AUTHORITIES ? 'seeInDatabase' : 'seeInBNCatalogue', language)}
            </Link>

        </Box>
    }, [language, cookies.searchContext])

    const baseColumnsForCatalogues: GridColDef[] = useMemo(() => [
        {
            field: '001',
            headerName: translateKey('nlpId', language),
            width: 200,
            valueGetter:(params) => `(PL)${params.row['001']}`

        },
        {
            field: 'title',
            headerName: translateKey('title', language),
            width: 300,
        },

        {
            field: 'creator',
            headerName: translateKey('creator', language),
            width: 300,
        },
        {
            field: 'form_of_work',
            headerName: translateKey('formOfWork', language),
            width: 400,
            valueFormatter: (params) => params.value.join(' | ')

        },
    ], [language]);

    const baseColumnsForAuthorities: GridColDef[] = useMemo(() => [
        {
            field: '001',
            headerName: translateKey('nlpId', language),
            width: 200,
            valueGetter:(params) => `(PL)${params.row['001']}`
        },
        {
            field: '1XX',
            width: 200,
            valueGetter: (params) => params.row['100'] ?? params.row['110'] ?? params.row['111'] ?? params.row['130'] ?? params.row['148'] ?? params.row['150'] ?? params.row['151'] ?? params.row['155']
        },
    ], [language]);

    const actionColumns: GridColDef[] = useMemo(() => [
        {
            field: 'actions',
            headerName: translateKey('actions', language),
            width: 250,
            renderCell: renderBNDatabaseBtn,

        },
    ], [language, renderBNDatabaseBtn])

    const [columns, setColumns] = useState<GridColDef[]>([...(cookies.searchContext === SearchEngineContext.AUTHORITIES ? baseColumnsForAuthorities : baseColumnsForCatalogues), ...actionColumns])

    const getTranslations = () => language === 'pl' ? {
        footerTotalVisibleRows: (visibleCount: number, totalCount: number) =>
            `${visibleCount.toLocaleString()} z ${totalCount.toLocaleString()}`,
        ...plPL.components.MuiDataGrid.defaultProps.localeText,

    } : {...enUS.components.MuiDataGrid.defaultProps.localeText}

    useEffect(() => {
            if (mentionFields.length > 0) {
                const newColumns = mentionFields.map(mField => {
                        return {
                            field: mField.id,
                            headerName: mField.display,
                            width: 150
                        }
                    }
                )
                setColumns([...(cookies.searchContext === SearchEngineContext.AUTHORITIES ? baseColumnsForAuthorities : baseColumnsForCatalogues), ...newColumns, ...actionColumns])

            }
        }, [mentionFields, baseColumnsForCatalogues, baseColumnsForAuthorities, actionColumns, cookies.searchContext]
    )


    const renderList = () =>
        <DataGrid

            rows={hits}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 30, 50, 100]}
            onPageSizeChange={(pageSize) => setPageSize(pageSize)}
            getRowId={(row) => row['001'][0]}
            autoHeight
            style={{
                background: 'white', boxShadow: 'rgba(32, 33, 36, 0.28) 0px 1px 6px 0px'
            }}
            components={{
                Toolbar: total ? ExportGridToolbar : null,
            }}
            localeText={getTranslations()}
        />

    return <Box>
        <Box className={'row-space-between pagination-info'}>
            <Box>
                <Typography>{translateKey('foundRecords', language)}: {total} </Typography>
                <Typography>{translateKey('fetchedRecordsToTable', language)}: {hits.length}</Typography>
            </Box>
            <Button variant={'outlined'} onClick={loadMoreData}
                    disabled={hits.length >= total}>{translateKey('loadNext', language)} {RESULTS_OFFSET} {translateKey('recordsInAccusative', language)}</Button>
        </Box>
        {renderList()}
        <MARC21RecordDialog nlpId={nlpId} open={openDialog} bibs
                            onClose={() => setOpenDialog(false)}/>
    </Box>
}

export default SearchResultsList