import {Box, Dialog, DialogContent, LinearProgress, Typography} from "@mui/material"
import {translateKey} from "utils/fieldsFormatters";
import React, {Suspense, useContext, useEffect, useState} from "react";
import {LanguageContext} from "context/LanguageContext";
import {downloadRecord} from "services/khwApi";
import {downloadSearchEngineRecord} from "services/searchEngineApi";
import {useCookies} from "react-cookie";
import {SearchEngineContext} from "consts/search-engine-consts";
import {BNDataSourceName} from "consts/khw-consts";

const DialogTitleWithCloseIcon = React.lazy(() => import("../../common/DialogTitleWithCloseIcon"));

interface MARC21RecordDialogProps {
    nlpId: string
    sourceName?: BNDataSourceName
    open: boolean
    onClose: () => void
    bibs?: boolean
}

const MARC21RecordDialog = ({nlpId, sourceName, open, onClose, bibs}: MARC21RecordDialogProps) => {
    const {language} = useContext(LanguageContext);
    const [recordData, setRecordData] = useState('')
    const [cookies] = useCookies(['searchContext'])

    const formatData = (data: string) => {
        const lines = data.split('\n')
        // indicators formatting

        const field001value = lines.find(line => line.slice(0, 3) === '001')?.slice(4) ?? ''
        const field009value = lines.find(line => line.slice(0, 3) === '009')?.slice(3) ?? ''

        let withIndicators = lines.slice(1).map((line: string) => {
                const field = line.slice(0, 3)
                let content = line.slice(3);
                if (line[0] === '0' && line[1] === '0') {
                    if (line[2] === '1') { //replacing 001 with 009 field
                        content = field009value
                    } else if (line[2] === '9') {
                        content = ` (PL)${field001value}`
                    }
                    return `${field}${content}` // control fields do not have indicators
                } else {

                    // handling fields with indicators
                    let indicators = line.slice(4, 6)
                    let content = line.slice(6)
                    indicators = indicators.replaceAll(' ', '_')
                    return field + ' ' + indicators + content
                }
            }
        )

        // adding bold to MARC21 fields
        let formattedLines = withIndicators.map(line => `<b>${line.slice(0, 3)}</b> ${line.slice(3)}`).join('\n')
        // adding spaces to subfields
        const regex = /[$][0-9a-z]/g
        formattedLines = formattedLines.replace(regex, ' $& ')

        return `${lines[0]}\n${formattedLines}`
    }

    useEffect(() => {
        if (nlpId !== '') {
            if (bibs) {
                downloadSearchEngineRecord(nlpId, 'MARC_PRETTY_PRINT', cookies.searchContext ?? SearchEngineContext.DBN).then(response => {
                    setRecordData(formatData(response.data))
                }).catch(error => console.log(error))
            } else {

                downloadRecord(nlpId, 'MARC_PRETTY_PRINT', sourceName).then(response => {
                    setRecordData(formatData(response.data))
                }).catch(error => console.log(error))
            }
        }
    }, [nlpId, bibs, cookies.searchContext, sourceName])


    return <Dialog open={open} onClose={onClose}  fullWidth
                   maxWidth={'md'}>
        <Suspense fallback={<Box sx={{width: '100%'}}>
            <LinearProgress/>
        </Box>}><DialogTitleWithCloseIcon title={translateKey('MARC21Record', language)} onClose={onClose}/></Suspense>
        <DialogContent>
            <Typography dangerouslySetInnerHTML={{__html: recordData}}
                        fontWeight={500}/>
        </DialogContent>


    </Dialog>
}

export default MARC21RecordDialog