import {translateKey} from "utils/fieldsFormatters";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl, FormHelperText,
    Input, InputAdornment,
    InputLabel,
} from "@mui/material";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {LanguageContext} from "context/LanguageContext";
import {LoadingButton} from "@mui/lab";

const DialogTitleWithCloseIcon = React.lazy(() => import("../../common/DialogTitleWithCloseIcon"));

interface FileNameDialogProps {
    open: boolean
    onClose: () => void
    onSave: (name: string) => void
}

const FileNameDialog = ({open, onClose, onSave}: FileNameDialogProps) => {
    const {language} = useContext(LanguageContext);
    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(true)
    const [helperText, setHelperText] = useState('')


    useEffect(() => {
        setLoading(false)
    }, []);

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target
        setError(!value)
        setName(value)
        setHelperText(!value ? translateKey('nameCannotBeEmpty', language) : '')
    }

    const onClick = () => {
        if (name) {
            setLoading(true)
            onSave(name)
        }

    }
    return <Dialog open={open} onClose={onClose} onBackdropClick={onClose} fullWidth
                   maxWidth={'sm'} disableEnforceFocus>
        <DialogTitleWithCloseIcon title={translateKey('saveFileAs', language)}
                                  onClose={onClose}/>
        <DialogContent>
            <Box className={'save-metadata-box'}>
                <FormControl variant="standard" style={{width: '75%'}} error={error}>
                    <InputLabel htmlFor="name-input">{translateKey('filename', language)}</InputLabel>
                    <Input id="name-input" value={name} onChange={onInputChange}
                           endAdornment={<InputAdornment position="end">.csv</InputAdornment>}/>
                    <FormHelperText>{helperText}</FormHelperText>

                </FormControl>
            </Box>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={loading} onClick={onClick}
                           disabled={error}>{translateKey('save', language)}</LoadingButton>
        </DialogActions>
    </Dialog>
}
export default FileNameDialog