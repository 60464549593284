import {Box, Typography} from "@mui/material";
import {MentionsInput, Mention, MentionItem, SuggestionDataItem} from 'react-mentions'
import React from "react";
import classNames from 'styles/mentions.module.css'
import {CONDITIONS_DATA, SearchEngineAutocompleteOption} from "consts/search-engine-consts";

interface SearchQueryEditorProps {
    fields: SearchEngineAutocompleteOption[]
    _006Chars: SearchEngineAutocompleteOption[]
    _007Chars: SearchEngineAutocompleteOption[]
    _008Chars: SearchEngineAutocompleteOption[]
    leaderChars: SearchEngineAutocompleteOption[]
    query: string
    onUpdateQuery: (newQuery: string, mentions: MentionItem[]) => void
}

const SearchQueryEditor = ({
                               fields,
                               _006Chars,
                               _007Chars,
                               _008Chars,
                               leaderChars,
                               query,
                               onUpdateQuery,
                           }: SearchQueryEditorProps) => {

    const handleChange = (event: { target: { value: string } }, newValue: string, newPlainTextValue: string, mentions: MentionItem[]) => {
        onUpdateQuery(newValue, mentions)
    }

    const filterFields = (query: string) => {
        if (query.includes('006') && !!_006Chars) {
            return _006Chars.filter(field => field.id.includes(query) || field.display.toLowerCase().includes(query.toLowerCase()))
        } else if (query.includes('007') && !!_007Chars) {
            return _007Chars.filter(field => field.id.includes(query) || field.display.toLowerCase().includes(query.toLowerCase()))
        } else if (query.includes('008') && !!_008Chars) {
            return _008Chars.filter(field => field.id.includes(query) || field.display.toLowerCase().includes(query.toLowerCase()))
        } else if (query.includes('lea') && !!leaderChars) {
            return leaderChars.filter(field => field.id.includes(query) || field.display.toLowerCase().includes(query.toLowerCase()))
        }
        return fields.filter(field => field.id.includes(query) || field.display.toLowerCase().includes(query.toLowerCase()))
    }

    return <Box>

        <MentionsInput
            value={query}
            onChange={handleChange}
            allowSuggestionsAboveCursor
            className="mentions"
            classNames={classNames}
            allowSpaceInQuery

        >

            <Mention
                trigger="#"
                markup={'#[__id__]'}
                data={filterFields}
                className={classNames.mentions__mention}
                appendSpaceOnAdd
                renderSuggestion={(suggestion: SuggestionDataItem, search: string, highlightedDisplay: React.ReactNode, index: number, focused: boolean) => <>
                    <Typography className={'autocomplete-option'}>Pole: <b>{suggestion.id}</b></Typography>
                    <Typography className={'autocomplete-option'}>{suggestion.display}</Typography>
                </>}
                displayTransform={(id, display) => {
                    return id
                }}

            />


            <Mention
                trigger={'?'}
                markup={'?[__id__]'}

                data={CONDITIONS_DATA}
                className={classNames.mentions__mention}
                appendSpaceOnAdd
                displayTransform={(id, display) => id}
                renderSuggestion={(suggestion: SuggestionDataItem, search: string, highlightedDisplay: React.ReactNode, index: number, focused: boolean) => <>
                    <Typography className={'autocomplete-option'}><b>{suggestion.id}</b> {suggestion.display}
                    </Typography>
                </>}
            />

        </MentionsInput>
    </Box>
}

export default SearchQueryEditor