import {Box, Typography} from "@mui/material";
import {translateKey} from "utils/fieldsFormatters";
import {useContext} from "react";
import {LanguageContext} from "context/LanguageContext";

const PageNotFound = () => {
    const {language} = useContext(LanguageContext);

    return <Box display={"flex"} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}
                paddingTop={10}>
        <Typography fontSize={20}> HTTP 404</Typography>
        <Typography fontSize={25}>{translateKey('pageNotFound', language)}</Typography></Box>
}
export default PageNotFound