import {
    Box,
    Checkbox,
    Dialog,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    LinearProgress,
    Radio,
    RadioGroup,
    SelectChangeEvent,
    Button,
    DialogActions,
    FormHelperText,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow, TableCell, TableBody, IconButton, Snackbar, Alert
} from "@mui/material";
import {translateKey} from "utils/fieldsFormatters";
import React, {ChangeEvent, useCallback, useContext, useEffect, useState} from "react";
import {LanguageContext} from "context/LanguageContext";
import {
    CustomDatasetRow,
    ExportMetadataGroups,
    ExportMetadataObject, FileToDownloadType,
    METADATA_SET,
    RECORDS_NUMBER, SearchEngineContext
} from "consts/search-engine-consts";
import {fetchFieldsForExport, fetchHistory, saveDataset} from "services/searchEngineApi";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {CircleOutlined} from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {LoadingButton} from "@mui/lab";
import {saveAs} from 'file-saver';
import FileNameDialog from "./FileNameDialog";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import {useCookies} from "react-cookie";

const DialogTitleWithCloseIcon = React.lazy(() => import("../../common/DialogTitleWithCloseIcon"));
const SaveMetadataDialog = React.lazy(() => import("./SaveMetadataDialog"));

interface ExportDialogProps {
    open: boolean
    onClose: () => void
    onExport: (recordsNumber: RECORDS_NUMBER, selectedFields: string[], filename: string) => void
    exportInProgress: boolean
    fileToDownloadData: FileToDownloadType | null
}


const ExportDialog = ({open, onClose, onExport, exportInProgress, fileToDownloadData}: ExportDialogProps) => {
    const {language} = useContext(LanguageContext);

    const [recordsNumber, setRecordsNumber] = useState<string>('')
    const [metadataSet, setMetadataSet] = useState<string>('')
    const [allMetadata, setAllMetadata] = useState<ExportMetadataGroups>({})
    const [selectedMetadata, setSelectedMetadata] = useState<string[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({metadataSet: false, recordsNumber: false})
    const [helperText, setHelperText] = useState({metadataSet: '', recordsNumber: ''})
    const [openSaveMetadataDialog, setOpenSaveMetadataDialog] = useState(false)
    const [openFileNameDialog, setOpenFileNameDialog] = useState(false)
    const [savedMetadataSets, setSavedMetadataSets] = useState<CustomDatasetRow[]>([])
    const [fileName, setFileName] = useState<string>('')
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [cookies] = useCookies(['searchContext', 'uuid']);

    const handleChangeRecordsNumber = (event: SelectChangeEvent) => {
        setRecordsNumber(event.target.value as string)
        setError({...error, recordsNumber: false})
        setHelperText({...helperText, recordsNumber: ''})
    }

    const downloadMetadata = useCallback(() => {
        setLoading(true)
        fetchFieldsForExport(language, cookies.searchContext ?? SearchEngineContext.DBN).then(response => {
            setAllMetadata(response.data)
            setLoading(false)

        }).catch(error => {
            console.log(error)
            setLoading(false)
        })
    }, [language, cookies.searchContext])

    useEffect(() => {
        downloadMetadata()
    }, [language, downloadMetadata])

    const downloadSavedMetadata = () => {
        setLoading(true)
        fetchHistory(cookies.uuid).then(response => {
            setSavedMetadataSets(response.data.CUSTOM_DATASETS)
            setLoading(false)
        }).catch(err => {
            console.log(error)
            setLoading(false)
        })
    }

    const handleChangeMetadataSet = async (event: SelectChangeEvent) => {
        const {value} = event.target
        setMetadataSet(event.target.value as string)
        if (value === METADATA_SET.default) {
            setSelectedMetadata([])

        } else if (value === METADATA_SET.custom && Object.keys(allMetadata).length === 0) {
            downloadMetadata()
        } else if (value === METADATA_SET.saved) {
            downloadSavedMetadata()
        }
        setError({...error, metadataSet: false})
        setHelperText({...helperText, metadataSet: ''})


    }
    const addFieldToSelectedMetadata = (value: string) => {
        const selected = [...selectedMetadata]
        selected.push(value)
        setSelectedMetadata(selected)
    }

    const removeFieldFromSelectedMetadata = (value: string) => {
        const filtered = selectedMetadata.filter(x => x !== value);
        setSelectedMetadata(filtered)
    }


    const handleChangeMetadataField = (event: ChangeEvent<HTMLInputElement>) => {

        const {checked, value} = event.target
        if (checked) {
            addFieldToSelectedMetadata(value)
        } else {
            removeFieldFromSelectedMetadata(value)
        }
    }

    const isFormValid = () => {
        let isValid = true
        let metadataSetError = false, recordsNumberError = false
        let recordsNumberHelperText = '', metadataHelperText = ''
        if (!metadataSet) {
            metadataSetError = true
            metadataHelperText = translateKey('Choose metadata set', language)
            isValid = false
        }
        if (!recordsNumber) {
            recordsNumberError = true
            recordsNumberHelperText = translateKey('chooseRecordsNumber', language)
            isValid = false


        }
        setError({recordsNumber: recordsNumberError, metadataSet: metadataSetError})
        setHelperText({recordsNumber: recordsNumberHelperText, metadataSet: metadataHelperText})
        return isValid
    }

    const generateCsv = (name: string) => {
        if (isFormValid()) {

            onExport(recordsNumber as RECORDS_NUMBER, selectedMetadata, name)
        }
    }

    const onClickGenerate = () => {
        setOpenFileNameDialog(true)


    }

    const onClickSaveMetadataSet = () => {
        if (selectedMetadata.length) {
            setOpenSaveMetadataDialog(true)
        }
    }

    const onSaveNewDataset = (name: string, selectedFields: string[]) => {
        saveDataset(name, selectedFields, cookies.uuid).then(response => {
            setOpenSaveMetadataDialog(false)
            setOpenSnackbar(true)
        }).catch(err =>
            console.log(err))
    }

    const onSelectSavedDataset = (fields: string[]) => {
        setSelectedMetadata(fields)
        if (isFormValid()) {
            setOpenFileNameDialog(true)
        }

    }

    const onSaveFileName = (name: string) => {
        setOpenFileNameDialog(false)
        setFileName(name)
        generateCsv(name)
    }


    const renderRecordsNumberRadioGroup = () => <FormControl style={{padding: 16}} error={error.recordsNumber}>
        <FormLabel id="records-number-radio-buttons-group-label"
                   className={' bold-text'}>{translateKey('recordsNumber', language)}</FormLabel>
        <RadioGroup
            aria-labelledby="records-number-radio-buttons-group-label"
            value={recordsNumber}
            name="radio-buttons-group"
            onChange={handleChangeRecordsNumber}
        >
            {Object.entries(RECORDS_NUMBER).map(([key, value]) => <FormControlLabel key={key} value={value}
                                                                                    control={<Radio/>}
                                                                                    label={translateKey(value, language)}/>)}
        </RadioGroup>
        <FormHelperText>{helperText.recordsNumber}</FormHelperText>
    </FormControl>

    const renderMetadataSetRadioGroup = () => <FormControl style={{padding: 16}} error={error.metadataSet}>
        <FormLabel id="metadata-set-radio-buttons-group-label"
                   className={' bold-text'}>{translateKey('metadataSet', language)}</FormLabel>
        <RadioGroup
            aria-labelledby="metadata-set-radio-buttons-group-label"
            value={metadataSet}
            name="radio-buttons-group"
            onChange={handleChangeMetadataSet}
        >
            {Object.entries(METADATA_SET)
                .map(([key, value]) =>
                    <FormControlLabel key={key} value={value} control={<Radio/>}
                                      label={translateKey(value, language)}/>)}
        </RadioGroup>
        <FormHelperText>{helperText.metadataSet}</FormHelperText>

    </FormControl>


    const renderLoader = () => <Box sx={{width: '100%'}}>
        <LinearProgress/>
    </Box>


    const renderNestedAccordion = (item: ExportMetadataObject) => {
        return <Accordion className={'metadata-accordion'}
        >
            <AccordionSummary title={translateKey('fieldWithSubfieldsInfo', language)}

                              expandIcon={
                                  <ExpandMoreIcon
                                  />}><Typography>{item.id}
            </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    <Grid item md={12}
                          key={item.id}>{renderSimpleCheckbox(item, true)}</Grid>
                    {item.subfields?.map(field => <Grid item md={6}
                                                        key={field.id}>{renderSimpleCheckbox(field)}</Grid>)}
                </Grid>
            </AccordionDetails>
        </Accordion>
    }


    const renderSimpleCheckbox = (item: ExportMetadataObject, bolded: boolean = false) =>
        <FormControlLabel key={item.id} name={item.id} style={{width: 150}} className={bolded ? 'bolded' : ''}
                          title={item.display}
                          control={<Checkbox onChange={handleChangeMetadataField} value={item.id}
                                             icon={<CircleOutlined/>}
                                             checkedIcon={<CheckCircleIcon/>}/>}
                          label={item.id}/>


    const renderMetadataCheckboxes = () => {
        return <FormGroup>
            {<Button className={'save-metadata-btn'} variant={'text'} onClick={onClickSaveMetadataSet}
                     disabled={selectedMetadata.length === 0}>{translateKey('saveNewMetadataSet', language)}</Button>}
            {Object.entries(allMetadata).map(([key, items]) => (
                <Accordion className={'metadata-accordion'} key={key}>
                    <AccordionSummary
                        expandIcon={
                            <ExpandMoreIcon/>}><Typography>{key} - {translateKey(key, language)}</Typography></AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            {items.map(item => item.subfields && item.subfields?.length > 0 ?
                                <Grid item md={4} key={item.id}>{renderNestedAccordion(item)}</Grid> :
                                <Grid item md={4} key={item.id}>{renderSimpleCheckbox(item)}</Grid>)}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}

        </FormGroup>
    }

    const renderSavedMetadataSets = () => <TableContainer className={'metadata-table'}>
        <Table aria-label="saved-metadata-table">
            <TableHead>
                <TableRow>
                    <TableCell>{translateKey('name', language)}</TableCell>
                    <TableCell>{translateKey('fieldsList', language)}</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {savedMetadataSets && savedMetadataSets.map((row) => (
                    <TableRow
                        key={row.name}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell component="th" scope="row" width={100}>
                            {row.name}
                        </TableCell>
                        <TableCell>{row.fields.join(', ')}</TableCell>
                        <TableCell width={25}><IconButton onClick={() => onSelectSavedDataset(row.fields)}
                                                          color={'primary'}
                                                          title={translateKey('chooseAndDownloadSet', language)}><FileDownloadIcon/></IconButton></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>


    const renderFileToDownloadActions = () => {
        if (!!fileToDownloadData) {
            const {status, link} = fileToDownloadData
            const isReady = status === 'SUCCESS'
            return <Box className={'row-space-between'}>
                <Typography>Generowanie pliku: {fileName}.csv | Status: {status}</Typography>
                <LoadingButton loading={!isReady} loadingPosition={'start'} startIcon={<FileDownloadIcon/>}
                               className={'download-loading-btn'}
                               onClick={() => saveAs(link, `${fileName}.csv`)}>{translateKey('download', language)}</LoadingButton>
            </Box>
        }
    }
    return <Dialog open={open} onClose={onClose} onBackdropClick={onClose} fullWidth
                   maxWidth={'md'} disableEnforceFocus>
        <DialogTitleWithCloseIcon title={translateKey('exportAsCsv', language)}
                                  onClose={onClose}/>
        <DialogContent>
            {renderRecordsNumberRadioGroup()}
            {renderMetadataSetRadioGroup()}
            {loading && renderLoader()}
            {metadataSet === METADATA_SET.custom && renderMetadataCheckboxes()}
            {metadataSet === METADATA_SET.saved && renderSavedMetadataSets()}
        </DialogContent>
        <DialogActions>
            {metadataSet !== METADATA_SET.saved && !fileToDownloadData &&
                <LoadingButton onClick={onClickGenerate} loading={exportInProgress} loadingPosition={'start'}
                               startIcon={<SystemUpdateAltIcon/>}
                               disabled={(selectedMetadata.length === 0 && metadataSet !== METADATA_SET.default) || !recordsNumber}>{translateKey('generateFile', language)}</LoadingButton>}
            {!!fileToDownloadData && renderFileToDownloadActions()}

        </DialogActions>

        <SaveMetadataDialog open={openSaveMetadataDialog} onClose={() => setOpenSaveMetadataDialog(false)}
                            onSave={onSaveNewDataset} selectedFields={selectedMetadata}/>
        <FileNameDialog open={openFileNameDialog} onClose={() => setOpenFileNameDialog(false)}
                        onSave={onSaveFileName}/>
        <Snackbar
            anchorOrigin={{vertical: "top", horizontal: 'right'}}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
        ><Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{width: '100%'}} variant={'filled'}>
            {translateKey('successfullySavedDataset', language)}
        </Alert></Snackbar>
    </Dialog>
}


export default ExportDialog