import {
    Box,
    IconButton, Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip, Typography,
} from "@mui/material"
import React, {useCallback, useContext, useEffect, useState} from "react";
import {fetchHistory} from "services/searchEngineApi";
import {FileHistoryRow} from "consts/search-engine-consts";
import {LanguageContext} from "context/LanguageContext";
import {translateKey} from "utils/fieldsFormatters";
import {RefreshTwoTone} from "@mui/icons-material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {saveAs} from "file-saver";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ErrorIcon from '@mui/icons-material/Error';
import {useCookies} from "react-cookie";

const FilesQueueBox = () => {
    const [data, setData] = useState<FileHistoryRow[] | null>([])
    const [lastUpdate, setLastUpdate] = useState('')
    const {language} = useContext(LanguageContext);
    const [page, setPage] = useState(0)
    const rowsPerPage = 5
    const [intervalId, setIntervalId] = useState<any | null>(null)
    const [stopInterval, setStopInterval] = useState(false)
    const [cookies] = useCookies(['uuid']);

    const refreshData = useCallback(() => {
            fetchHistory(cookies.uuid).then(response => {
                setData(response.data.FILES_IN_PROGRESS_KEY.concat(response.data.FILES_HISTORY))
                setLastUpdate(new Date().toLocaleString())

            }).catch(err => {
                    setLastUpdate(new Date().toLocaleString())
                }
            )
        }, [cookies.uuid]
    )

    useEffect(() => {
            if (!stopInterval) {
                refreshData()
                setIntervalId(setInterval(refreshData, process.env.REACT_APP_REFRESH_FILES_QUEUE_INTERVAL_IN_MS))
            }
            return () => {
                setStopInterval(true)
            }
        }
        , [refreshData, stopInterval])

    useEffect(() => {
            if (stopInterval) {
                clearInterval(intervalId)
            }
        },
        [stopInterval, intervalId]
    )


    const onClickDownload = (filepath: string | undefined, filename: string) => {
        if (filepath) saveAs(filepath, `${filename}.csv`)
    }
    const onPageChange = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };


    const renderStatusDownloadCell = (row: FileHistoryRow) =>
        <TableCell align={'center'}>
            {(row.status === 'STARTED' || row.status === 'PENDING') &&
                <Tooltip title={translateKey('fileGeneratingInProgressTooltip', language)}><HourglassBottomIcon
                    color={'warning'}/></Tooltip>}
            {row.status === 'FAILURE' && <Tooltip title={'fileError'}><ErrorIcon color={'error'}/></Tooltip>}
            {!row.status && row.file_path &&
                <IconButton onClick={() => onClickDownload(row.file_path, row.filename)}
                            title={translateKey('download', language)}
                            color={'success'}><FileDownloadIcon/></IconButton>}
        </TableCell>


    return <Box>
        <Typography fontSize={'small'} style={{
            float: 'right',
            padding: 8
        }}>{translateKey('lastUpdate', language)}: {lastUpdate}</Typography>
        <TableContainer className={'paper-table files-table'} component={Paper}>
            <Table aria-label="files table">
                <TableHead>
                    <TableRow>
                        <TableCell width={'10%'}
                                   className={'first-cell-in-row'}>{translateKey('date', language)}</TableCell>
                        <TableCell width={'15%'}>{translateKey('filename', language)}</TableCell>
                        <TableCell width={'20%'}>{translateKey('query', language)}</TableCell>
                        <TableCell>{translateKey('selectedFields', language)}</TableCell>
                        <TableCell width={'5%'}>{translateKey('recordsNumber', language)}</TableCell>

                        <TableCell width={'5%'} align={'center'}><IconButton
                            title={translateKey('refresh', language)}
                            onClick={refreshData}><RefreshTwoTone
                            color={"primary"}/></IconButton></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {data && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <TableRow
                            key={row.date}
                        >
                            <TableCell component="th" scope="row" className={'first-cell-in-row'}>
                                {row.date}
                            </TableCell>
                            <TableCell>{row.filename}</TableCell>
                            <TableCell>{row.query}</TableCell>

                            <TableCell>{row.fields?.join(', ') ?? ''}</TableCell>
                            <TableCell>{row.records_number}</TableCell>
                            {renderStatusDownloadCell(row)}
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination count={data?.length ?? 0} page={page} onPageChange={onPageChange}
                                         rowsPerPage={5} rowsPerPageOptions={[5]} showFirstButton={true}
                                         showLastButton={true}/>
                    </TableRow>
                </TableFooter>
            </Table>

        </TableContainer>

    </Box>
}
export default FilesQueueBox