export const highlightName = (name: string, searchedText: string | null) => {
    if (!searchedText) return name
    const tokenizedSearchText = searchedText.toLowerCase().split(' ')
    const tokenizedName = name.split(' ')

    return tokenizedName.map(token => {
        const cleanToken = token.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[,.?!()-]/gi, '');
        if (tokenizedSearchText.includes(token) || tokenizedSearchText.includes(cleanToken)) return `<span style="font-weight: bold; background: #f2c75c">${token}</span>`
        return token
    }).join(' ')
}

