import {Box} from "@mui/material";
import React, {useContext} from "react";
import {SectionTypography} from "./common/SectionTypography";
import {SectionHeaderTypography} from "./common/SectionHeaderTypography";
import {translateKey} from "utils/fieldsFormatters";
import {LanguageContext} from "context/LanguageContext";

export interface SectionProps {
    title: string
}

export const Section1 = ({title}: SectionProps) => {
    const {language} = useContext(LanguageContext);

    const sectionParagraphs = ['documentationSection1_1', 'documentationSection1_2', 'documentationSection1_3']

    return <Box className={'docs-section'}>
        <SectionHeaderTypography>{title}</SectionHeaderTypography>

        {sectionParagraphs.map((paragraph, idx) => <SectionTypography key={`section-1-${idx}`}>{translateKey(paragraph, language)}</SectionTypography>)}

    </Box>

}
