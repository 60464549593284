import {Box} from "@mui/material";

interface EmptyDividerProps {
    size?: 'extra-small' | 'small' | 'medium'
}

const EmptyDivider = ({size = 'medium'}: EmptyDividerProps) => {
    const className = `empty-divider-${size}`
    return <Box className={className}/>
}

export default EmptyDivider