import {SuggestionDataItem} from "react-mentions";


export type FetchFieldsResponse = {
    standard_fields: SearchEngineAutocompleteOption[]
    _006_chars: SearchEngineAutocompleteOption[]
    _007_chars: SearchEngineAutocompleteOption[]
    _008_chars: SearchEngineAutocompleteOption[]
    leader: SearchEngineAutocompleteOption[]
}
export type _008CharsType = {
    books: { [id: string]: string },
    computer_files: {}
}
export type SearchEngineAutocompleteOption = {
    id: string
    display: string
}

export type ExportMetadataGroups = {
    [id: string]: ExportMetadataObject[]
}

export type ExportMetadataObject = {
    id: string
    display: string
    subfields?: SearchEngineAutocompleteOption[]
}

export type ESRecord = {
    [id: string]: string
}

export type SearchResults = {
    total: number
    hits: ESRecord[]
    es_query: string
    message?: string
    has_error: boolean
}
export const CONDITIONS_DATA = [
    {
        id: '=',
        display: 'równa się',
    },
    {
        id: '!=',
        display: 'różne od'
    },
    {
        id: '>',
        display: 'większe'
    },
    {
        id: '>=',
        display: 'większe lub równe'
    },
    {
        id: '<',
        display: 'mniejsze'
    },
    {
        id: '<=',
        display: 'mniejsze lub równe'
    },
    {
        id: '*',
        display: 'zawiera [wartość]'
    },
    {
        id: '!*',
        display: 'nie zawiera [wartość]'
    },
    {
        id: 'exists',
        display: 'zawiera [pole]'
    },
    {
        id: 'not_exists',
        display: 'nie zawiera [pole]'
    }
]

export const OPERATORS: SuggestionDataItem[] = [
    {
        id: '|',
        display: 'lub'

    },
    {
        id: '&',
        display: 'oraz'

    }]

export enum METADATA_SET {
    default = 'defaultMetadata',
    custom = 'customMetadata',
    saved = 'savedMetadata'
}

export enum RECORDS_NUMBER {
    _50 = 'first50Records',
    ALL = 'allRecords'
}

export const SPECIAL_CHARACTERS_MENTIONS = [...CONDITIONS_DATA.map(c => c.id), ...OPERATORS.map(o => o.id)]

export const RESULTS_OFFSET = 50

export type QueryHistoryRow = {
    date: string
    query: string
    total_results: number
}

export type FileHistoryRow = {
    filename: string,
    file_path?: string,
    records_number?: number,
    fields?: string[]
    query?: string
    date: string
    status?: 'SUCCESS' | 'STARTED' | 'PENDING' | 'FAILURE'

}


export type CustomDatasetRow = {
    name: string
    fields: string[]
    date: string
}
export type HistoryResponse = {
    FILES_HISTORY: FileHistoryRow[]
    QUERIES_HISTORY: QueryHistoryRow[]
    CUSTOM_DATASETS: CustomDatasetRow[]
    FILES_IN_PROGRESS_KEY: FileHistoryRow[]
}

export type FileToDownloadType = {
    status: 'SUCCESS' | 'STARTED' | 'PENDING' | 'FAILURE'
    link: string

}

export enum SearchEngineContext {
    DBN = 'BNCatalogue',
    INTEGRATED_CATALOGUE = 'integratedCatalogue',
    AUTHORITIES = 'authorities'
}