import {Box, LinearProgress, Tab, Tabs, useMediaQuery, useTheme} from "@mui/material";
import React, {lazy, Suspense, useContext, useEffect, useState} from "react";
import {useWindowSize} from "@react-hook/window-size/throttled";
import {translateKey} from "utils/fieldsFormatters";
import {LanguageContext} from "context/LanguageContext";
import Highcharts from "highcharts";
import {DescriptorType, RelationsData} from "consts/khw-consts";
import BoxAccordion from "components/common/BoxAccordion";

const AssociationsGraph = lazy(() => import("./AssociationsGraph"));
const DescriptorMap = lazy(() => import("./descriptorTabs/map/DescriptorMap"));
const WorksCharts = lazy(() => import("./descriptorTabs/works/WorksCharts"));
const ManifestationsCharts = lazy(() => import("./descriptorTabs/manifestations/ManifestationsCharts"));
const WorksTimeline = lazy(() => import("./descriptorTabs/timeline/WorksTimeline"));
const WorksAboutList = lazy(() => import("./WorksAboutList"));

interface DescriptorStatisticsCardProps {
    descriptorName: string
    associations: RelationsData | null
    descriptorType: DescriptorType
    coords?: string[]
}

const DescriptorStatisticsCard = ({
                                      descriptorName,
                                      descriptorType,
                                      associations,
                                      coords
                                  }: DescriptorStatisticsCardProps) => {
    const {language} = useContext(LanguageContext);
    const showStatisticsManifestations = process.env.REACT_APP_SHOW_DESCRIPTOR_STATISTICS_MANIFESTATIONS === 'true'
    const showStatisticsSubject = process.env.REACT_APP_SHOW_DESCRIPTOR_STATISTICS_SUBJECT_OF_PUBLICATION === 'true'
    const showStatisticsRecent = process.env.REACT_APP_SHOW_DESCRIPTOR_STATISTICS_RECENT_PUBLICATIONS_ON === 'true'
    const showStatisticsAssociations = process.env.REACT_APP_SHOW_DESCRIPTOR_STATISTICS_ASSOCIATIONS === 'true'
    const showStatisticsTimeline = process.env.REACT_APP_SHOW_DESCRIPTOR_STATISTICS_TIMELINE === 'true'

    useEffect(() => {
            Highcharts.setOptions({
                lang: {
                    downloadPNG: translateKey('downloadPNG', language),
                    downloadJPEG: translateKey('downloadJPEG', language),
                    downloadPDF: translateKey('downloadPDF', language),
                    downloadSVG: translateKey('downloadSVG', language),
                    exitFullscreen: translateKey('exitFullscreen', language),
                    viewFullscreen: translateKey('viewFullscreen', language),
                    printChart: translateKey('printChart', language),
                    contextButtonTitle: translateKey('chartContextMenu', language)
                }
            })
        }
        ,
        [language]
    )

    const theme = useTheme();
    const [width] = useWindowSize()
    const mobileView = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const [tab, setTab] = useState<number | null>(descriptorType === DescriptorType.PERSONAL ? 0 : associations ? 4 : 5)

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };


    return <BoxAccordion header={'statisticsData'} showBorderedCard
                         tooltipText={translateKey('statisticsTooltip', language)}>
        <Tabs value={tab} onChange={handleTabChange} aria-label="tabs" variant={'scrollable'} scrollButtons
              allowScrollButtonsMobile>
            {descriptorType === DescriptorType.PERSONAL && showStatisticsManifestations &&
                <Tab label={translateKey('manifestationData', language)}
                     value={0} tabIndex={0}/>}
            {descriptorType === DescriptorType.PERSONAL && showStatisticsSubject &&
                <Tab label={translateKey('worksData', language)} value={1} tabIndex={0}/>}
            {descriptorType === DescriptorType.PERSONAL && showStatisticsRecent &&
                <Tab label={translateKey('worksAboutData', language)} value={2} tabIndex={0}/>}
            {descriptorType === DescriptorType.PERSONAL && showStatisticsTimeline &&
                <Tab label={translateKey('timeline', language)} value={3} tabIndex={0}/>}
            {associations && showStatisticsAssociations &&
                <Tab label={translateKey('associations', language)} value={4} tabIndex={0}/>}
            {descriptorType === DescriptorType.GEOGRAPHIC &&
                <Tab label={translateKey('map', language)}
                     value={5} tabIndex={0}/>}
        </Tabs>

        {tab === 0 &&
            <Suspense fallback={<Box sx={{width: '100%'}}>
                <LinearProgress/>
            </Box>}><ManifestationsCharts descriptorName={descriptorName}
                                          windowWidth={width}
                                          mobileView={mobileView}/></Suspense>}
        {tab === 1 && <Suspense fallback={<Box sx={{width: '100%'}}>
            <LinearProgress/>
        </Box>}><WorksCharts descriptorName={descriptorName} windowWidth={width}
                             mobileView={mobileView}/></Suspense>}
        {tab === 2 && <Suspense fallback={<Box sx={{width: '100%'}}>
            <LinearProgress/>
        </Box>}><WorksAboutList descriptorName={descriptorName}/></Suspense>}
        {tab === 3 && <Suspense fallback={<Box sx={{width: '100%'}}>
            <LinearProgress/>
        </Box>}><WorksTimeline descriptorName={descriptorName}/></Suspense>}
        {(tab === 4 && associations) && <Suspense fallback={<Box sx={{width: '100%'}}>
            <LinearProgress/>
        </Box>}><AssociationsGraph associations={associations}/></Suspense>}
        {(tab === 5 && coords) && <Suspense fallback={<Box sx={{width: '100%'}}>
            <LinearProgress/>
        </Box>}><DescriptorMap coords={coords}/></Suspense>}

    </BoxAccordion>


}

export default DescriptorStatisticsCard