import {DBN_URL, DESCRIPTOR_TYPE_IN_GENITIVE_CASE} from "../consts/khw-consts";


export const renderSEODescriptionForDescriptorDetailView = (source: string, type: string, preferred_name: string, alternateNames?: string[]) => {
    const genitiveName = DESCRIPTOR_TYPE_IN_GENITIVE_CASE[type]
    const sourceFormatted = source === 'DBN' ? 'BN' : 'MeSH-POL'
    let tag = `Wyświetl informacje na temat ${genitiveName} ${sourceFormatted}: ${preferred_name}.`
    if (alternateNames?.length) {
        const formatted = alternateNames.slice(0, 3).join(' | ')
        tag = tag.concat(` Warianty nazwy: ${formatted}.`)
    }
    return tag
}

export const renderCanonicalUrl = (bnId: string) => `${DBN_URL}/descriptor-details/${bnId}`