import {DescriptorWikidataInfo} from "consts/khw-consts";
import {formatDate, translateKey} from "utils/fieldsFormatters";
import React, {useContext} from "react";
import {LanguageContext} from "context/LanguageContext";
import {Grid} from "@mui/material";
import {renderWikidataUrl} from "utils/renderUrls";

const BoxAccordion = React.lazy(() => import("components/common/BoxAccordion"));
const InfoGridRow = React.lazy(() => import("components/descriptorInfo/InfoGridRow"));

interface DescriptorWikidataInfoCardProps {
    wikidataId: string
    data: DescriptorWikidataInfo
}

const DescriptorWikidataInfoCard = ({wikidataId, data}: DescriptorWikidataInfoCardProps) => {
    const {language} = useContext(LanguageContext);

    const renderRows = () => {
        return Object.entries(data).map(([key, value]) => {
            const label = translateKey(key, language, 'wikidata')
            const uniqueKey = `wikidata-${key}`
            if (key.toLowerCase().includes('date')) {
                const date = formatDate(value, language)
                if (date)
                    return <InfoGridRow key={uniqueKey} label={label} simpleValue={date}/>
                else return <></>

            } else if (key === 'signature') {
                return <InfoGridRow key={key} label={label} simpleValue={value} valueType={"image"}/>
            } else if (key === 'pronunciationAudio') {
                return <InfoGridRow key={key} label={label} simpleValue={value} valueType={"sound"}/>
            }
            return <InfoGridRow key={uniqueKey} label={label} simpleValue={value}/>
        })
    }


    return <BoxAccordion header={'wikidataInfo'} showBorderedCard
                         tooltipText={translateKey('wikidataTooltip', language)}>
        <Grid container rowSpacing={0} columnSpacing={2} className={'detail-grid'}>

            <InfoGridRow key={'wikidataId'} label={translateKey('wikidataId', language)} simpleValue={wikidataId}
                         link={renderWikidataUrl(wikidataId)}/>
            {renderRows()}
        </Grid>
    </BoxAccordion>


}

export default DescriptorWikidataInfoCard