import React, {lazy, PropsWithChildren, useContext, useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, BoxProps, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {translateKey} from "../../utils/fieldsFormatters";
import {LanguageContext} from "../../context/LanguageContext";

const InfoTooltip = lazy(() => import("components/common/InfoTooltip"));

interface BoxAccordionProps extends BoxProps {
    header: string
    tooltipText?: string
    showBorderedCard: boolean
    closedOnInit?: boolean
}

const BoxAccordion = (props: PropsWithChildren<BoxAccordionProps>) => {
    const {header, tooltipText, showBorderedCard, closedOnInit, children} = props
    const [expanded, setExpanded] = useState(closedOnInit ? false : true)
    const className = showBorderedCard ? 'bordered-box-accordion' : 'simple-box-accordion'
    const titleClassName = showBorderedCard ? 'bordered-accordion-header upper-case' : 'filter-type-header'

    const contentId = `${header.replace(' ', '_').toLowerCase()}-content`
    const headerId = `${header.replace(' ', '_').toLowerCase()}-header`
    const {language} = useContext(LanguageContext);

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === 'Space') {
            setExpanded(!expanded)
        }

    }

    return <Accordion className={className} disableGutters expanded={expanded}>

        <AccordionSummary onKeyDown={onKeyDown}
                          expandIcon={<ExpandMoreIcon onClick={() => setExpanded(!expanded)} tabIndex={0}/>}
                          id={headerId}
        >
            {tooltipText ? <Typography className={titleClassName} component={'h2'}>{translateKey(header, language)} <InfoTooltip
                    text={tooltipText}/></Typography> :
                <Typography className={titleClassName}  component={'h2'}>{translateKey(header, language)} </Typography>}
        </AccordionSummary>
        <AccordionDetails id={contentId}>
            {children}

        </AccordionDetails>
    </Accordion>
}

export default BoxAccordion