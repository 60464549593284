import {DialogTitle, IconButton, Typography} from "@mui/material";
import {translateKey} from "utils/fieldsFormatters";
import CloseIcon from "@mui/icons-material/Close";
import React, {useContext} from "react";
import {LanguageContext} from "context/LanguageContext";

export interface DialogTitleWithCloseIconProps {
    title: string
    subtitle?: string
    onClose: () => void
    extraClass?: string
}


const DialogTitleWithCloseIcon = ({title, subtitle, onClose, extraClass}: DialogTitleWithCloseIconProps) => {
    const {language} = useContext(LanguageContext);

    return <DialogTitle className={extraClass ? extraClass : ''}>
        <Typography className='box-header upper-case'>{translateKey(title, language)}</Typography>
        <Typography fontWeight={500}>{subtitle}</Typography>
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 4,
                top: 4,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon/></IconButton>
    </DialogTitle>
}


export default DialogTitleWithCloseIcon
