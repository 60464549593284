import React, {useState, createContext} from 'react';
import {LanguageType} from "consts/khw-consts";

interface ILanguageContext {
    language: LanguageType;
    setLanguage?: (lang: LanguageType) => void
}

const defaultState = {
    language: 'pl' as LanguageType,
}

export const LanguageContext = createContext<ILanguageContext>(defaultState);

export const LanguageProvider = ({children}: any) => {
    const [language, setLanguage] = useState(defaultState.language);

    const changeLanguage = (lang: LanguageType) => {
        setLanguage(lang);
    };

    return (
        <LanguageContext.Provider
            value={{
                language,
                setLanguage: changeLanguage,
            }}
        >
            {children}
        </LanguageContext.Provider>
    );
};