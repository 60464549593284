import {TimelineInputData} from "../consts/khw-consts";
import _ from "lodash";

export const groupWorksByYear = (data: TimelineInputData[]) => {

    data.sort((a, b) => {
            if (a.work_first_pub_or_creation_date > b.work_first_pub_or_creation_date) return 1
            return -1
        }
    )

    const grouped = _.groupBy(data, item => item.work_first_pub_or_creation_date)
    return grouped
}