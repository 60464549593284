import {
    IconButton,
    LinearProgress, Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import {useContext, useEffect, useState} from "react";
import {fetchHistory} from "services/searchEngineApi";
import {QueryHistoryRow} from "consts/search-engine-consts";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import {translateKey} from "utils/fieldsFormatters";
import {LanguageContext} from "context/LanguageContext";
import {useCookies} from "react-cookie";

interface QueriesHistoryBoxProps {
    onRerunQuery: (query: string) => void
}

const QueriesHistoryBox = ({onRerunQuery}: QueriesHistoryBoxProps) => {
    const [data, setData] = useState<QueryHistoryRow[] | null>(null)
    const [loading, setLoading] = useState(false)
    const {language} = useContext(LanguageContext);
    const [cookies] = useCookies(['uuid']);

    useEffect(() => {
        setLoading(true)
        fetchHistory(cookies.uuid).then(response => {
            setData(response.data.QUERIES_HISTORY)
            setLoading(false)

        }).catch(err => setLoading(false)
        )

    }, [cookies.uuid])

    const onClickRerun = (query: string) => {
        onRerunQuery(query)
    }

    return loading ? <LinearProgress/> :
            <TableContainer className={'paper-table files-table'} component={Paper}>
                <Table aria-label="queries table">
                    <TableHead>
                        <TableRow >

                            <TableCell className={'first-cell-in-row'}>{translateKey('date', language)}</TableCell>
                            <TableCell>{translateKey('query', language)}</TableCell>
                            <TableCell/>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.map((row) => (
                            <TableRow
                                key={row.date}
                            >

                                <TableCell component="th" scope="row" width={150} className={'first-cell-in-row'}>
                                    {row.date}
                                </TableCell>
                                <TableCell>{row.query}</TableCell>
                                <TableCell width={50}><IconButton title={translateKey('reload', language)}
                                                                  color={'primary'}
                                                                  onClick={() => onClickRerun(row.query)}><ReplayOutlinedIcon/></IconButton></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

}
export default QueriesHistoryBox