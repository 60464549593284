import {
    Box,
    Typography,
    ToggleButton,
    ToggleButtonGroup,
    Snackbar,
    Alert,
    Select,
    MenuItem,
    SelectChangeEvent, Button
} from "@mui/material";
import {translateKey} from "utils/fieldsFormatters";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {LanguageContext} from "context/LanguageContext";
import {
    FetchFieldsResponse,
    SearchEngineAutocompleteOption, SearchEngineContext,
    SearchResults,
    SPECIAL_CHARACTERS_MENTIONS
} from "consts/search-engine-consts";
import {fetchFields, searchRecords} from "services/searchEngineApi";
import {MentionItem} from "react-mentions";
import _ from "lodash";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import HistoryIcon from '@mui/icons-material/History';
import SearchIcon from '@mui/icons-material/Search';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import {downloadTextFile} from "utils/downloadTextFile";
import QueriesHistoryBox from "./search/QueriesHistoryBox";
import SearchResultsList from "./search/SearchResultsList";
import SearchQueryEditor from "./search/SearchQueryEditor";
import HelpBox from "./search/HelpBox";
import {LoadingButton} from "@mui/lab";
import FilesQueueBox from "./search/FilesQueueBox";
import {useCookies} from "react-cookie";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const MARCRecordsSearch = () => {

    const [standardFields, setStandardFields] = useState<SearchEngineAutocompleteOption[]>([])
    const [_006Chars, set_006Chars] = useState<SearchEngineAutocompleteOption[]>([])
    const [_007Chars, set_007Chars] = useState<SearchEngineAutocompleteOption[]>([])
    const [_008Chars, set_008Chars] = useState<SearchEngineAutocompleteOption[]>([])
    const [leaderChars, setLeaderChars] = useState<SearchEngineAutocompleteOption[]>([])
    const {language} = useContext(LanguageContext);
    const [results, setResults] = useState<SearchResults | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const [query, setQuery] = useState('')
    const [cleanedQuery, setCleanedQuery] = useState('')
    const [mentions, setMentions] = useState<MentionItem[]>([])
    const [mentionFields, setMentionFields] = useState<MentionItem[]>([])
    const [page, setPage] = useState(1)
    const [runSearch, setRunSearch] = useState(false)
    const [visibleBox, setVisibleBox] = useState<string | null>(null)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [cookies, setCookie] = useCookies(['searchContext', 'uuid']);
    const [selectedContext, setSelectedContext] = useState(cookies.searchContext ?? SearchEngineContext.DBN)

    useEffect(() => {
        fetchFields(language, selectedContext).then(response => {
            const data = response.data as FetchFieldsResponse
            setStandardFields(data.standard_fields)
            set_006Chars(data._006_chars)
            set_007Chars(data._007_chars)
            set_008Chars(data._008_chars)
            setLeaderChars(data.leader)
        })
            .catch(error => console.log(error))
    }, [language, selectedContext])

    const prepareMentionFields = useCallback(() => {
        const allMentionedField = mentions.filter(mention => !SPECIAL_CHARACTERS_MENTIONS.includes(mention.id))
        return _.uniqBy(allMentionedField, 'id')
    }, [mentions])

    useEffect(() => {
        if (!runSearch) return;
        if (!cleanedQuery) return

        setLoading(true)
        searchRecords(cleanedQuery, page, selectedContext, cookies.uuid).then(response => {
            setResults(response.data)
            setLoading(false)
            setMentionFields(prepareMentionFields())
            setRunSearch(false)
        }).catch(err => {
            const {message} = err.response.data
            setError(message)
            setLoading(false)
            setRunSearch(false)
        })
    }, [runSearch, cleanedQuery, page, prepareMentionFields, selectedContext, cookies.uuid])


    const getCleanQuery = (q: string) => q.replaceAll('[', '').replaceAll(']', '')

    const onSearchBtnClick = () => {
        setVisibleBox(null)
        setResults(null)
        setError('')
        const cleanedQuery = getCleanQuery(query)
        setCleanedQuery(cleanedQuery)
        setPage(1)
        setRunSearch(true)
        localStorage.setItem('query', JSON.stringify(cleanedQuery))


    }

    const onCopyQueryBtnClick = () => {
        const cleanedQuery = getCleanQuery(query)
        downloadTextFile(cleanedQuery, 'zapytanie', 'txt', 'text/plain')
    }

    const onUploadQueryBtnClick = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.item(0)
        const text = await file?.text()
        if (text) {
            setQuery(text)
            setOpenSnackbar(true)
        }
    }

    const onUpdateQuery = (newQuery: string, mentions: MentionItem[]) => {
        setQuery(newQuery)
        setMentions(mentions)
    }

    const loadMoreData = () => {
        setPage(page + 1)
        setRunSearch(true)
    }
    const handleExtraBoxes = (
        event: React.MouseEvent<HTMLElement>,
        box: string | null,
    ) => {
        setVisibleBox(box);
    };

    const onRerunQuery = (query: string) => {
        setQuery(query)
        setOpenSnackbar(true)
    }


    const selectContext = (event: SelectChangeEvent) => {
        setSelectedContext(event.target.value)
        setCookie('searchContext', event.target.value)
    }

    const renderContextSelect = () => <Select value={selectedContext} className={'select'}
                                              variant={'outlined'} size={'small'}
                                              onChange={selectContext}>
        <MenuItem value={SearchEngineContext.DBN}>{translateKey(SearchEngineContext.DBN, language)}</MenuItem>
        <MenuItem
            value={SearchEngineContext.INTEGRATED_CATALOGUE}>{translateKey(SearchEngineContext.INTEGRATED_CATALOGUE, language)}</MenuItem>
        <MenuItem
            value={SearchEngineContext.AUTHORITIES}>{translateKey(SearchEngineContext.AUTHORITIES, language)}</MenuItem>
    </Select>

    return <Box>
        <Typography className={'search-engine-header'}
                    variant={'h1'}>{translateKey('MARCRecordSearchEngine', language)}</Typography>

        <Box className={'row-space-between'}>
            <ToggleButtonGroup
                value={visibleBox}
                exclusive
                onChange={handleExtraBoxes}
                aria-label="text alignment"
            >
                <ToggleButton value="help" aria-label="help">
                    <Box className={'custom-toggle-button'}>
                        <QuestionMarkIcon color={'primary'}/>
                        <Typography>{translateKey('help', language)}</Typography>
                    </Box>
                </ToggleButton>
                <ToggleButton value="queries-history" aria-label="queries-history">
                    <Box className={'custom-toggle-button'}>

                        <HistoryIcon color={'primary'}/>
                        <Typography>{translateKey('recentQueries', language)}</Typography>
                    </Box>
                </ToggleButton>
                <ToggleButton value="files-queue" aria-label="files-queue">
                    <Box className={'custom-toggle-button'}>

                        <HourglassBottomIcon color={'primary'}/>
                        <Typography>{translateKey('filesQueue', language)}</Typography>
                    </Box>
                </ToggleButton>
            </ToggleButtonGroup>

            {renderContextSelect()}
        </Box>
        <Box className={'search-engine-query-btns'}>
            <Button variant={'text'} startIcon={<UploadFileIcon/>}
                    title={'Załaduj zapytanie z pliku'} component={'label'} className={'upload-button'}
            >{translateKey('uploadQuery', language)}<input hidden accept=".txt" type="file"
                                                           onChange={onUploadQueryBtnClick}/></Button>
            <Button variant={'text'} startIcon={<ContentCopyIcon/>}
                    disabled={!query} onClick={onCopyQueryBtnClick}
                    title={'Zapisz zaptanie do pliku'} className={'save-button'}>{translateKey('saveQuery', language)}
            </Button>
        </Box>
        {visibleBox === 'help' && <HelpBox/>}
        {visibleBox === 'queries-history' && <QueriesHistoryBox onRerunQuery={onRerunQuery}/>}
        {visibleBox === 'files-queue' && <FilesQueueBox/>}
        {!loading && error && <Box>
            <Alert severity="error" variant={'filled'}><b>{translateKey('SYNTAX_ERROR', language)}!</b><br></br>{translateKey(error, language)}</Alert>
        </Box>}


        <SearchQueryEditor fields={standardFields} _006Chars={_006Chars} _007Chars={_007Chars} _008Chars={_008Chars}
                           leaderChars={leaderChars} query={query} onUpdateQuery={onUpdateQuery}/>
        <LoadingButton loading={loading} onClick={onSearchBtnClick} variant={'contained'} startIcon={<SearchIcon/>}
                       title={'Szukaj'} loadingPosition="start"
                       className={'search-engine-search-btn'}>{translateKey('search', language)}</LoadingButton>

        <Box>
            {!loading && !!results && results.hits &&
                <SearchResultsList hits={results?.hits} mentionFields={mentionFields} total={results.total}
                                   loadMoreData={loadMoreData} page={page}/>}</Box>
        <Snackbar
            anchorOrigin={{vertical: "top", horizontal: 'right'}}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
        ><Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{width: '100%'}} variant={'filled'}>
            {translateKey('successfullyUploadedQuery', language)}
        </Alert></Snackbar>

    </Box>
}
export default MARCRecordsSearch