import axios from "axios";
import {BNDataSourceName, DataSourceType, DescriptorType, FileType} from "consts/khw-consts";

const qs = require('qs');

export type DescriptorsListQueryParams = {
    page: number | null
    size: number | null
    order: string | null
    sortBy: string | null
    sourceName?: DataSourceType[]
    descriptorType?: DescriptorType[]
    createdTimeFrom?: string
    createdTimeTo?: string
    updatedTimeFrom?: string
    updatedTimeTo?: string
    searchedText: string | null
}
export const fetchDescriptorsList = (params: {}) => {

    const url = `${process.env.REACT_APP_API_URL}/khw/search`
    return axios.get(url, {
        params,
        paramsSerializer: function (params) {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        }
    })
}

export const fetchDescriptorsListByAlternateNames = (params: {}) => {
    const url = `${process.env.REACT_APP_API_URL}/khw/searchByAlternateNames`
    return axios.get(url, {
        params,
        paramsSerializer: function (params) {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        }
    })
}

export const fetchDescriptorData = (nlpId: string) => {

    const url = `${process.env.REACT_APP_API_URL}/khw/${nlpId}`
    return axios.get(url)
}

export const fetchWikidataData = (
    bnId: string, descriptor: string, language: string, viafId: string | null, wikidataId: string | null
) => {
    const url = `${process.env.REACT_APP_API_URL}/lod/getAdditionalInformation`
    return axios.get(url, {
        params: {
            bnId,
            descriptor: descriptor.toLowerCase(),
            language: language as string,
            viafId,
            wikidataId
        }
    })
}

export const fetchAutosuggestions = (searchedText: string, selectedSource: string) => {
    const url = `${process.env.REACT_APP_API_URL}/khw/suggest`
    const sourceName = selectedSource === '-1' ? null : selectedSource

    return axios.get(url, {
        params: {searchedText: searchedText, sourceName},
    })
}

export const searchBySimilarPhrases = (query: string) => {
    const url = `${process.env.REACT_APP_API_URL}/khw/searchBySimilarPhrases`
    return axios.get(url, {
        params: {query},
    })
}

export const fetchChartData = async (descriptorName: string, type: 'manifestations' | 'works' | 'works_for_timeline') => {
    let url: string;
    if (type === 'manifestations') url = `${process.env.REACT_APP_API_URL}/search-service/manifestations`
    else if (type === 'works') url = `${process.env.REACT_APP_API_URL}/search-service/works`
    else url = `${process.env.REACT_APP_API_URL}/search-service/works-for-timeline`

    let results: any[] = []
    let page = 0
    let offset = 500

    let key = type === 'manifestations' ? 'manifestations' : 'works'
//    first hit
    await axios.get(`${url}/${page}/${offset}`, {params: {name: descriptorName}}).then(async response => {
            results.push(...response.data[key])
            const totalPages = response.data.totalPages
            let requests = []
            if (totalPages === 1) {
                return results
            }

            // fetching and aggregating all the pages
            for (let i = 1; i < totalPages; i++) {
                requests.push(axios.get(`${url}/${i}/${offset}`, {params: {name: descriptorName}}))
            }
            return await Promise.all(requests).then((responses) => {
                return responses.forEach(response => {
                    results.push(...response.data[type])

                })

            }).catch(error => Promise.reject(error))
        }
    ).catch(error => Promise.reject(error))

    return results

}

export const fetchWorksAboutData = (descriptorName: string, page: number, offset: number) => {
    const url = `${process.env.REACT_APP_API_URL}/search-service/works-about`
    return axios.get(`${url}/${page}/${offset}`, {params: {name: descriptorName}})
}

export const downloadRecord = (descriptorNlpId: string, fileType: FileType, descriptorSource?: BNDataSourceName) => {
    const url = descriptorSource === 'MESH' ? `${process.env.REACT_APP_API_URL}/data-bn/mesh/${descriptorNlpId}` : `${process.env.REACT_APP_API_URL}/data-bn/authorities/${descriptorNlpId}`
    return axios.get(url, {
        params: {
            fileType
        }
    })

}

export const downloadChartBase64 = (descriptorName: string, language: string) => {
    const url = `${process.env.REACT_APP_SEARCH_ENGINE_API_URL}/render-chart?descriptor_name=${descriptorName}&lang=${language}`
    return axios.get(url, {responseType: 'json'})
}

export const getDescriptorDetailsByName = (descriptorName: string) => {
    const url = `${process.env.REACT_APP_API_URL}/khw/detailsByNames`
    return axios.post(url, {descriptorNames: [descriptorName]})
}

export const sendContactForm = (senderEmail: string, subject: string, content: string, sourceUrl: string) => {
    const url = `${process.env.REACT_APP_API_URL}/contact`
    return axios.post(url, {senderEmail, subject, content, sourceUrl})
}