import {Breadcrumbs, Link, Typography} from "@mui/material";
import {translateKey} from "utils/fieldsFormatters";
import {useContext} from "react";
import {LanguageContext} from "context/LanguageContext";

interface BreadcrumbsRowProps {
    content: string
    descriptorType?: string
}

const BreadcrumbsRow = ({content, descriptorType}: BreadcrumbsRowProps) => {
    const {language} = useContext(LanguageContext);

    const lastBreadcrumb = descriptorType ? `${descriptorType} - <b>${content}</b>` : `<b>${content}</b>`

    return <Breadcrumbs aria-label="breadcrumb" separator={'>'}>
        <Link underline="hover" color="black" href="/">
            {translateKey('homepage', language)}
        </Link>
        <Link
            display='flex'
            flexDirection='row'
            underline="none"
            color="black"
        ><Typography color={'black'}
                     dangerouslySetInnerHTML={{__html: lastBreadcrumb}}/>
        </Link>
    </Breadcrumbs>
}

export default BreadcrumbsRow