// Functions for formatting different keys in display

import translations_PL from "translations/translations_PL.json";
import translations_EN from "translations/translations_EN.json";
import wikidataTranslationsPL from "translations/wikidata_translation_PL.json";
import wikidataTranslationsEN from "translations/wikidata_translation_EN.json";
import {
    DataAssociatedObject,
    DataSourceType,
    DescriptorLinkObject,
    FormattedValueType,
    LanguageType
} from "consts/khw-consts";


const MAPPED_TRANSLATIONS_FILES: { [key: DataSourceType]: { pl: any, en: any } } = {
    wikidata: {
        pl: wikidataTranslationsPL,
        en: wikidataTranslationsEN
    },
    exemplaryRecord: {
        pl: translations_PL,
        en: translations_EN
    }
}


export const translateKey = (key: string, lang: LanguageType, dataSource: DataSourceType = 'exemplaryRecord') => {
    let languageFile = MAPPED_TRANSLATIONS_FILES[dataSource][lang];
    if (dataSource === 'wikidata') {
        key = 'WikiData.' + key

    }
    return key in languageFile ? languageFile[key] : key
}


export const formatValue = (key: string, value: string | string[] | DataAssociatedObject[] | DescriptorLinkObject[]): FormattedValueType => {
//    identifying field type
    if (Array.isArray(value)) {
        const elementsType = typeof value[0]
        switch (elementsType) {
            case 'string': // array of strings
                return {arrayValue: value} as FormattedValueType
            case 'object':
                const firstObject = value[0] as DescriptorLinkObject | DataAssociatedObject
                if ('nlpId' in firstObject) { // DescriptorLinkObject
                    return {complexValues: value as DescriptorLinkObject[]}
                } else { //DataAssociatedObject
                    const typedValue = value as DataAssociatedObject[]
                    const valueToDisplay = typedValue.map(item =>
                        item.dataAssociated ? `${item.stringValue} ${item.dataAssociated}` : `${item.stringValue}`)
                    return {arrayValue: valueToDisplay}


                }
            default:
                return {simpleValue: '-'}
        }

    }
    return {simpleValue: value}
}


const getDateFromDateTimeString = (datetime: string) => new Date(datetime.trim()).toISOString().split('T')[0].trim()

const isDate = (date: string) => {
    return !isNaN(new Date(date.trim()).getDate());
}

export const formatDate = (dateToFormat: string, language: LanguageType = 'pl')=> {
    if (dateToFormat.includes(',')) {
        return dateToFormat.split(',').filter(isDate).map(d => getDateFromDateTimeString(d)).join(', ')
    } else if (dateToFormat.startsWith('-')) { // BC dates
       return `${new Date(dateToFormat.trim().slice(1)).toISOString().split('T')[0].trim()} ${translateKey('BC', language)}`
    } else if (!isDate(dateToFormat)) {
        return null
    }
    return getDateFromDateTimeString(dateToFormat)

}



