import {useContext, useEffect, useState} from "react";
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";
import {translateKey} from "utils/fieldsFormatters";
import {LanguageContext} from "context/LanguageContext";

let serviceWorker: ServiceWorker | null

export default function ScreenLoading() {

    const [showUpdate, setShowUpdate] = useState(false)
    const [showLoading, setShowLoading] = useState(false)
    const {language} = useContext(LanguageContext);

    useEffect(() => {
        serviceWorkerRegistration.register({onUpdate: onSWUpdate, onSuccess: onSWSuccess})
    }, [])

    function onSWUpdate(registration: ServiceWorkerRegistration) {
        setShowUpdate(true)
        serviceWorker = registration.waiting
    }

    function onSWSuccess() {
        console.log("App installed as a PWA.")
    }

    function updateSW() {
        setShowLoading(true)
        if (!serviceWorker) return
        // Add listener for state change of service worker
        serviceWorker.onstatechange = () => {
            if (serviceWorker?.state === "activated" &&
                navigator.serviceWorker.controller) {
                // Reload page if waiting was successfully skipped
                window.location.reload()
            }
        }
        serviceWorker.postMessage({type: "SKIP_WAITING"})
        setShowUpdate(false)
    }

    return (
        <div>
            {showUpdate &&
                <Dialog title={translateKey('updateBox.updateAvailable', language)} open>
                    <DialogTitle>Update available</DialogTitle>
                    <DialogActions>
                        <Button onClick={updateSW}>{translateKey('updateBox.update', language)} </Button>

                    </DialogActions></Dialog>
            }
            {showLoading &&
                <div>
                    <h2>Updating...</h2>
                </div>
            }
        </div>
    )
}