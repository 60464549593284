import React, {Suspense, useContext, useEffect, useState} from "react";
import {
    Alert,
    Box, Button, Checkbox,
    Dialog, DialogActions,
    DialogContent, FormControlLabel,
    FormGroup,
    LinearProgress, Snackbar,
    TextField,
    Typography
} from "@mui/material";
import {translateKey} from "utils/fieldsFormatters";
import DialogTitleWithCloseIcon from "../../common/DialogTitleWithCloseIcon";
import {LanguageContext} from "context/LanguageContext";
import {sendContactForm} from "services/khwApi";
import {isValidEmailString} from "../../../utils/validators";

interface ReportSuggestionDialogProps {
    open: boolean
    onClose: () => void
}

// const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;


const ReportSuggestionDialog = ({open, onClose}: ReportSuggestionDialogProps) => {
    const {language} = useContext(LanguageContext);
    const [senderEmail, setSenderEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [content, setContentSubject] = useState('')
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
    const [canValidate, setCanValidate] = useState(false)
    const [message, setMessage] = useState('')
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const onCloseDialog = () => {
        setIsSuccess(false)
        setIsError(false)
        setSubject('')
        setSenderEmail('')
        setContentSubject('')
        setIsCheckboxChecked(false)
        setCanValidate(false)
        setMessage('')
        onClose()
    }

    useEffect(() => {
        setIsSuccess(false)
    }, []);
    const onClickSendButton = () => {
        setCanValidate(true)
        if (subject && content && senderEmail && isValidEmailString(senderEmail) && isCheckboxChecked) {
            const sourceUrl = window.location.href
            sendContactForm(senderEmail, subject, content, sourceUrl).then(res => {
                setMessage(translateKey('suggestionSendSuccessMessage', language))
                setIsSuccess(true)
            }).catch(err => {
                console.log(err)
                setIsError(true)
                setMessage(translateKey('suggestionSendErrorMessage', language))
            })
        }

    }
    return <Dialog open={open} onClose={onCloseDialog} fullWidth maxWidth={'sm'}>
        <Suspense fallback={<Box sx={{width: '100%'}}>
            <LinearProgress/>
        </Box>}>
            <DialogTitleWithCloseIcon title={translateKey('reportSuggestion', language)}
                                      onClose={onCloseDialog}/></Suspense>

        <DialogContent>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': {m: 1, width: '25ch'},
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    {message && <Alert severity={isSuccess ? 'success' : 'error'}  variant="filled" aria-live={'assertive'} style={{margin: 8}}>
                        {message}
                    </Alert>}
                    <TextField
                        className={'suggestion-form-input'}
                        error={canValidate && (senderEmail === '' || !isValidEmailString(senderEmail))}
                        helperText={(canValidate && (senderEmail === '' || !isValidEmailString(senderEmail))) ? translateKey('invalidEmail', language) : ''}
                        onChange={(e) => setSenderEmail(e.target.value)}
                        value={senderEmail}
                        label={translateKey('suggestionSenderEmail', language)}
                        variant={"filled"}
                        type={'email'}
                        required
                    />
                </div>
                <div>
                    <TextField
                        className={'suggestion-form-input'}
                        error={canValidate && subject === ''}
                        helperText={canValidate && subject === '' ? translateKey('emptyFieldError', language) : ''}
                        onChange={(e) => setSubject(e.target.value)}
                        value={subject}
                        label={translateKey('suggestionSubject', language)}
                        variant={"filled"}
                        required
                    />
                </div>
                <div>
                    <TextField
                        required
                        className={'suggestion-form-input'}
                        error={canValidate && content === ''}
                        helperText={canValidate && content === '' ? translateKey('emptyFieldError', language) : ''}
                        multiline
                        rows={6}
                        onChange={(e) => setContentSubject(e.target.value)}
                        value={content}
                        label={translateKey('suggestionContent', language)}
                        variant={"filled"}
                    />
                </div>
                <div>

                    <FormGroup>
                        <FormControlLabel
                            className={(canValidate && !isCheckboxChecked) ? 'suggestion-rodo-checkbox-label error-text' : 'suggestion-rodo-checkbox-label'}
                            control={<Checkbox className={'suggestion-rodo-checkbox'}
                                               checked={isCheckboxChecked}
                                               onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}/>}
                            label={<Typography
                                dangerouslySetInnerHTML={{__html: translateKey('suggestionRODOText', language)}}></Typography>}

                        />
                    </FormGroup>

                </div>
            </Box>

        </DialogContent>
        <DialogActions>
            <Box className={'send-suggestion-button-box'}>
                <Button variant={'outlined'}
                        onClick={isSuccess ? onCloseDialog : onClickSendButton}>{translateKey(isSuccess ? 'close' : 'sendSuggestion', language)}</Button>
            </Box>


        </DialogActions>

    </Dialog>
}

export default ReportSuggestionDialog