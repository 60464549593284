export const POLONA_BASE_URL = 'https://polona.pl/search/?query='
export const KATALOG_BN_HOME_URL = 'https://katalogi.bn.org.pl'
export const KATALOG_BN_BASE_URL = 'https://katalogi.bn.org.pl/discovery/search?query=any,contains,'
export const KATALOG_BN_BASE_URL_EXACT = 'https://katalogi.bn.org.pl/discovery/search?query=sub,exact,'
export const KATALOG_BN_PUBLICATION_SUFFIX_URL = '&tab=BN&search_scope=MyInstitution1&vid=48OMNIS_NLOP:48OMNIS_NLOP&facet=topic,include,'
export const KATALOG_BN_SUFFIX_URL = '&tab=BN&search_scope=MyInstitution1&vid=48OMNIS_NLOP:48OMNIS_NLOP&offset=0'
export const VIAF_URL = 'https://viaf.org/viaf'
export const WIKIDATA_URL = 'https://www.wikidata.org/wiki'
export const BN_URL = 'https://bn.org.pl'
export const PRZEPISY_BN_URL = 'https://przepisy.bn.org.pl'

export const OMNIS_URL = 'https://omnis.bn.org.pl'

export const MLUMEN_URL = 'https://mlumen.bn.org.pl'

export const DBN_URL = 'https://dbn.bn.org.pl'
export const API_URL = 'https://dbn.bn.org.pl/api/swagger-ui/index.html'

const LANGUAGES = ['pl', 'en'] as const
export type LanguageType = typeof LANGUAGES[number]

const DATA_SOURCES = ['exemplaryRecord', 'wikidata']
export type DataSourceType = typeof DATA_SOURCES[number]

const BN_DATA_SOURCES = ['DBN', 'MeSH']
export type BNDataSourceName = typeof BN_DATA_SOURCES[number]

export enum DescriptorType {
    PERSONAL = 'personal_descriptor',
    CORPORATE = 'corporate_descriptor',
    EVENT = 'event_descriptor',
    GEOGRAPHIC = 'geographic_descriptor',
    CHRONOLOGICAL = 'chronological_descriptor',
    SUBJECT = 'subject_descriptor',
    GENRE = 'genre_descriptor',
    AUTHOR_TITLE = 'author_title_descriptor',
    UNIFORM_TITLE = 'uniform_title_descriptor',
    FAKE = 'fake_descriptor'
}

export const DESCRIPTOR_TYPE_IN_GENITIVE_CASE: { [key: string]: string } = {
    [DescriptorType.PERSONAL]: 'deskryptora osobowego',
    [DescriptorType.CORPORATE]: 'deskryptora korporstywnego',
    [DescriptorType.EVENT]: 'deskryptora imprezy',
    [DescriptorType.GEOGRAPHIC]: 'deskryptora geograficznego',
    [DescriptorType.CHRONOLOGICAL]: 'deskryptora chronologicznego',
    [DescriptorType.SUBJECT]: 'deskryptora tematycznego',
    [DescriptorType.GENRE]: 'deskryptora formy/rodzaju/gatunku',
    [DescriptorType.AUTHOR_TITLE]: 'deskryptora autora/tytułu',
    [DescriptorType.UNIFORM_TITLE]: 'deskryptora tytułowego',
    mesh_main_heading: 'hasła przedmiotowego',
    mesh_geographics: 'hasła geograficznego'

}

export const RELATION_TYPES = ['up', 'down', 'horizontal']
export type RelationType = typeof RELATION_TYPES[number]

export const POSITION_TYPES = ['left', 'right']
export type PositionType = typeof POSITION_TYPES[number]


export const FILES_TYPES = ['JSON', 'XML', 'MARCXML', 'MRC', 'MARC_PRETTY_PRINT']
export type FileType = typeof FILES_TYPES[number]


export type RelationsData = {
    mainDescriptor: DescriptorLinkObject
    relatedHorizontally: DescriptorLinkObject[] | null
    relatedUp: DescriptorLinkObject[] | null
    relatedDown: DescriptorLinkObject[] | null
}

export type DescriptorLinkObject = {
    nlpId: string
    type: DescriptorType
    preferredName: string
    dataAssociated: string
}

export type GenderType = {
    current: string
    history?: string | null
}


export type SuggestionObject = {
    nlpId: string
    preferredName: string
    secondaryName: string | null
    type: DescriptorType
}

export type SimilarObject = {
    id: string
    preferredName: string
    source: string
    type: DescriptorType
}

export type SimilarObjectsDict = {
    DBN: SimilarObject[],
    MESH: SimilarObject[]

}
export type BasicDescriptorInfo = {
    nlpId: string
    preferredName: string
    sourceName: string
    alternateNames: string[]
    type: DescriptorType
    createdTime: string
    updatedTime: string
    foundIn: string[]
    jsonFieldValues: {}
}

export type DataAssociatedObject = {
    dataAssociated: string
    stringValue: string
}

type JSONFieldObject = {
    [key: string]: string | string[] | DataAssociatedObject[] | DescriptorLinkObject[]
}

export type DescriptorInfo = {
    nlpId: string
    preferredName: string
    type: DescriptorType
    createdTime: string
    updatedTime: string
    sourceName: BNDataSourceName
    alternateNames: string[]
    externalIds: DataAssociatedObject[]
    mmsId: string
    controlNumber: string
    jsonFieldValues: JSONFieldObject

}

export type WikidataImage = {
    author: string
    encodedImage: string
    imageType: string
    imageUrl: string
}
export type DescriptorWikidataInfo = {
    [key: string]: string
}


export type FormattedValueType = {
    simpleValue?: string
    arrayValue?: string[]
    complexValues?: DescriptorLinkObject[]
    // redirectLink?: string
    dialog?: {
        title: string
        content: string
    }
}

export type DateTimeRange = {
    from: Date | null
    to: Date | null
}

export type FilterItem = {
    label?: string
    value?: any
    fullName: string
    isSelected?: boolean
    type: 'source' | 'descriptor_type' | 'date'
}
export const INITIAL_FILTERS: FilterItem[] = [
    {fullName: DescriptorType.PERSONAL, isSelected: false, type: 'descriptor_type'},
    {fullName: DescriptorType.CORPORATE, isSelected: false, type: 'descriptor_type'},
    {fullName: DescriptorType.EVENT, isSelected: false, type: 'descriptor_type'},
    {fullName: DescriptorType.CHRONOLOGICAL, isSelected: false, type: 'descriptor_type'},
    {fullName: DescriptorType.GEOGRAPHIC, isSelected: false, type: 'descriptor_type'},
    {fullName: DescriptorType.SUBJECT, isSelected: false, type: 'descriptor_type'},
    {fullName: DescriptorType.GENRE, isSelected: false, type: 'descriptor_type'},
    {fullName: DescriptorType.AUTHOR_TITLE, isSelected: false, type: 'descriptor_type'},
    {fullName: DescriptorType.UNIFORM_TITLE, isSelected: false, type: 'descriptor_type'},
    {fullName: 'mesh_main_heading', isSelected: false, type: 'descriptor_type'},
    {fullName: 'mesh_geographics', isSelected: false, type: 'descriptor_type'},
    {fullName: 'DBN', label: 'DBN files', isSelected: true, type: 'source'},
    {fullName: 'MESH', label: 'MeSH files', isSelected: false, type: 'source'},
    {fullName: 'creation_date', type: 'date', value: null, isSelected: false},
    {fullName: 'modification_date', type: 'date', value: null, isSelected: false}
]

export const PERSONAL_DESCRIPTOR_EXTRA_FILTERS = [
    'placeOfBirth', 'placeOfDeath', 'gender', 'affiliation', 'occupation', 'fieldOfActivity', 'titleOfPerson',
    'otherDesignation', 'associatedLanguage', 'associatedPlaceCountry', 'associatedPlaceResidenceOrHeadquarters',
    'associatedPlaceOtherPlace', 'dateOfBirthFrom', 'dateOfBirthTo', 'dateOfDeathFrom', 'dateOfDeathTo'
]
export const SUBJECT_DESCRIPTOR_EXTRA_FILTERS = ['subjectCategory']
export const GEOGRAPHIC_DESCRIPTOR_EXTRA_FILTERS = ['typeOfJurisdiction']
export const CORPORATE_DESCRIPTOR_EXTRA_FILTERS = ['typeOfCorporateBody']

export const DESCRIPTOR_TO_EXTRA_FILTERS_MAP = {
    [DescriptorType.PERSONAL]: PERSONAL_DESCRIPTOR_EXTRA_FILTERS,
    [DescriptorType.SUBJECT]: SUBJECT_DESCRIPTOR_EXTRA_FILTERS,
    [DescriptorType.GEOGRAPHIC]: GEOGRAPHIC_DESCRIPTOR_EXTRA_FILTERS,
    [DescriptorType.CORPORATE]: CORPORATE_DESCRIPTOR_EXTRA_FILTERS
}


export const EXTRA_FILTERS = [...PERSONAL_DESCRIPTOR_EXTRA_FILTERS, ...SUBJECT_DESCRIPTOR_EXTRA_FILTERS,
    ...GEOGRAPHIC_DESCRIPTOR_EXTRA_FILTERS, ...CORPORATE_DESCRIPTOR_EXTRA_FILTERS]

export enum SORT_MODES {
    BY_NAME_ASCENDING = 'PREFERRED_NAME__ASC',
    BY_NAME_DESCENDING = 'PREFERRED_NAME__DESC',
    BY_CREATION_DATE_ASCENDING = 'CREATED_TIME__ASC',
    BY_CREATION_DATE_DESCENDING = 'CREATED_TIME__DESC',
    BY_MODIFICATION_DATE_ASCENDING = 'UPDATED_TIME__ASC',
    BY_MODIFICATION_DATE_DESCENDING = 'UPDATED_TIME__DESC',
    BY_SCORE_ASCENDING = 'SCORE__ASC',
    BY_SCORE_DESCENDING = 'SCORE__DESC'
}


type LabelsMapType = {
    [key: string]: { pl: string, eng: string }
}
export const LABELS_MAP: LabelsMapType = {

    externalIds: {
        pl: 'Identyfikatory',
        eng: 'Indentifiers'
    },
    alternativeNames: {
        pl: 'Nazwy alternatywne',
        eng: 'Alternative names'
    }
}


export type ChartData = {
    keys: string[]
    values: number[]
}

export type PieChartData = {
    name: string
    y: number
}[]


export type BubbleChartData = {
    name: string
    value: number
}[]

export type  TreeMapData = {
    name: string
    value: number
    colorValue: number
}[]

export type  MapChartData = {
    code3: string
    z: number
    y: number
    extraInformation: string
}[]


export type WorksChartItem = {
    [index: string]: string[] | number | string
    work_subject: string[]
    work_genre: string[]
    work_domain: string[]
    form: string[]
}

export type TimelineDotData = {
    year: number
    work_title: string | string[]
}

export type TimelineInputData = WorksChartItem & {
    work_first_pub_or_creation_date: number
    work_isbn: string[]
    work_manifestation_count: number
    work_title_of_orig_pref: string
    work_title_pref: string

}

export type TimelineOutputData = {
    [index: string]: TimelineInputData[]

}

export type WorksChartData = {
    work_subject: TreeMapData | null
    work_genre: PieChartData | null
    work_domain: BubbleChartData | null
    form: PieChartData | null
}
export type ManifestationChartItem = {
    [index: string]: string[] | number | string
    manifestation_publication_year: number
    relation: string[]
    publisher: string[]
    cocreator: string[]
    manifestation_language: string[]
    manifestation_country: string[]
    manifestation_title_and_responsibility: string
    manifestation_nlp_id: string
}
export type ManifestationChartData = {
    manifestation_publication_year: ChartData
    relation: PieChartData | null
    publisher: PieChartData | null
    cocreator: PieChartData | null
    manifestation_language: ChartData
    manifestation_country: MapChartData | null
}

export type WorksAboutData = {
    work_title: string
    work_id: string
    work_main_creator: string[]
    work_other_creator: string[]
    manifestation_last_publication_year: number
    expression_form: string[]
    work_host_item_full: string[]
    work_subject: string[]
}
export const INITIAL_CHART_DATA = {
    keys: [],
    values: []
}

export const DESCRIPTOR_RELATED_FIELDS = ['relatedPerson', 'relatedCorporateBody', 'relatedEvent', 'relatedSubject', 'relatedGenre']
export const DESCRIPTOR_RELATED_UP_FIELDS = ['corporateBroader', 'geographicalBbroader', 'subjectBroader', 'genreBroader']
export const DESCRIPTOR_RELATED_DOWN_FIELDS = ['corporateNarrower', 'geographicalNarrower', 'subjectNarrower', 'genreNarrower']

export type AggregationType = {
    aggregation: 'DESCRIPTOR_TYPE' | 'SOURCE_NAME'
    count: number
    value: DescriptorType | BNDataSourceName
}

export const ATTRIBUTES_ORDER = ['dateOfBirth',
    'placeOfBirth',
    'dateOfDeath',
    'placeOfDeath',
    'startOfActivity',
    'endOfActivity',
    'gender',
    'associatedLanguage',
    'affiliation',
    'occupation',
    'fieldOfActivity',
    'titleOfPerson',
    'otherDesignation',
    'associatedPlaceCountry',
    'associatedPlaceResidenceOrHeadquarters',
    'associatedPlaceAddress',
    'associatedPlaceOtherPlace',
    'typeOfCorporateBody',
    'corporateEarlierName',
    'corporateLaterName',
    'eventEarlierName',
    'eventLaterName',
    'typeOfJurisdiction',
    'coords',
    'subjectCategory',
    'issn',
    'corporateBroader',
    'corporateNarrower',
    'relatedCorporateBody',
    'subjectBroader',
    'subjectNarrower',
    'relatedSubject',
    'genreBroader',
    'genreNarrower',
    'relatedGenre',
    'relatedPerson',
    'relatedEvent',
    'geographicalBroader',
    'geographicalNarrower',
    'mainHeadingBroader',
    'mainHeadingNarrower',
    'relatedMainHeading',
    'geographicsBroader',
    'geographicsNarrower',
    'relatedGeographics',
    'publicGeneralNote',
    'sourceDataFound',
    'sourceDataNotFound'
]
export const SPECIFIC_FIELDS = ['location_points']
export const HIDDEN_FIELDS = ['location',
    'preferredNameForFuzzySearch',
    'alternateNamesForFuzzySearch',
    'dateOfDeathYear',
    'dateOfBirthYear'
]
