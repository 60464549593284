import {Typography} from "@mui/material";
import {translateKey} from "utils/fieldsFormatters";
import CookieConsent from "react-cookie-consent";
import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "context/LanguageContext";
import {COLORS} from "styles/colors";
import additionalInfoBarConfig from "config/additionalInfoBarConfig.json"

const AdditionalInfoBar = () => {
    const {language} = useContext(LanguageContext);
    const [data, setData] = useState({showInfo: false, version: 0, content: '', cookieName: ''})

    useEffect(() => {
        const {showInfo, version, content} = additionalInfoBarConfig
        setData({showInfo, version, content, cookieName: `info-${version}`})
    }, [])

    const cookieConsentProps = {
        buttonText: translateKey('understand', language),
        style: {
            background: COLORS.light,
        },
        buttonStyle: {
            background: COLORS.dark,
            color: COLORS.white
        }
    }


    return data.showInfo ? <CookieConsent {...cookieConsentProps} cookieName={data.cookieName} location={'top'} containerClasses={'additional-info-bar'}>


        <Typography>{data.content}</Typography>

    </CookieConsent> : null
}

export default AdditionalInfoBar