import {Box, Button, Chip, Grid, Link, Paper, Typography, useMediaQuery, useTheme} from "@mui/material";
import {DescriptorInfo, DescriptorType, DescriptorWikidataInfo, WikidataImage} from "consts/khw-consts";
import {formatDate, translateKey} from "utils/fieldsFormatters";
import avatar from 'assets/user.png'
import globe from 'assets/globe.png'
import subject from 'assets/search.png'
import React, {lazy, useContext, useEffect, useState} from "react";
import {LanguageContext} from "context/LanguageContext";
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptorPDF from "./DescriptorPDF";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {downloadChartBase64} from "services/khwApi";
import {renderViafUrl, renderWikidataUrl} from "utils/renderUrls";
import {highlightName} from "utils/highlightName";
import ReportSuggestionDialog from "./dialogs/ReportSuggestionDialog";


const EmptyDivider = lazy(() => import("components/common/EmptyDivider"));
const InfoDialog = lazy(() => import("components/common/InfoDialog"));
const DownloadRecordDialog = lazy(() => import("components/descriptorInfo/dialogs/DownloadRecordDialog"));
const MARC21RecordDialog = lazy(() => import("components/descriptorInfo/dialogs/MARC21RecordDialog"));
const LinksDialog = lazy(() => import("components/descriptorInfo/dialogs/LinksDialog"));
const InfoTooltip = lazy(() => import("components/common/InfoTooltip"));

interface DescriptorBasicInfoCardProps {
    data: DescriptorInfo
    image: WikidataImage | null
    wikidata: DescriptorWikidataInfo | null
    authorData: { nlpId: string, href: string, type: DescriptorType } | null
}

const DescriptorBasicInfoCard = ({data, image, wikidata, authorData}: DescriptorBasicInfoCardProps) => {
    const {
        nlpId,
        preferredName,
        type,
        createdTime,
        updatedTime,
        alternateNames,
        externalIds,
        mmsId,
        controlNumber,
        sourceName
    } = data

    const {language} = useContext(LanguageContext);
    const [openNamesDialog, setOpenNamesDialog] = useState(false)
    const [openDownloadRecordDialog, setOpenDownloadRecordDialog] = useState(false)
    const [openSeeMARC21RecordDialog, setOpenSeeMARC21RecordDialog] = useState(false)
    const [openReportSuggestionForm, setopenReportSuggestionForm] = useState(false)
    const [openLinksDialog, setOpenLinksDialog] = useState(false)
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const [chartsImages, setChartImages] = useState<{ [index: string]: string } | null>(null)
    const [downloadPdfUnlocked, setDownloadPdfUnlocked] = useState(false)
    const searchedText = localStorage.getItem('searchedText')
    const [imageValidPath, setImageValidPath] = useState('')
    const [imageAlt, setImageAlt] = useState('')

    useEffect(() => {
        const imageValidPath = generateValidImagePath()
        const imageAlt = getImageAltBasedOnType(type, !!image)

        setImageValidPath(imageValidPath)
        setImageAlt(imageAlt)

    }, [image])

    const generateValidImagePath = () =>{
        if (!image) return getImagePlaceholderBasedOnType(type)
        const {encodedImage, imageUrl} = image
        if (encodedImage){
            return `data:image/${image.imageType};base64,${image?.encodedImage}`
        }else if (imageUrl) {
            return imageUrl
        }
        return getImagePlaceholderBasedOnType(type)
    }


    const unblockPDF = () => {
        setDownloadPdfUnlocked(true)

        if (type === DescriptorType.PERSONAL) {
            downloadChartBase64(preferredName, language).then(response => {
                setChartImages(response.data)

            }).catch(error => {
                setDownloadPdfUnlocked(true)

            })
        }
    }
    const getImagePlaceholderBasedOnType = (type: DescriptorType) => {
        switch (type) {
            case DescriptorType.GEOGRAPHIC:
                return globe
            case DescriptorType.PERSONAL:
                return avatar
            default:
                return subject
        }
    }

    const getImageAltBasedOnType = (type: DescriptorType, hasImage: boolean) => {
        switch (type) {
            case DescriptorType.GEOGRAPHIC:
                return 'grafika miejsca'
            case DescriptorType.PERSONAL:
                return hasImage ? 'zdjęcie osoby' : 'avatar osoby'
            default:
                return 'ogólna grafika deskryptora'
        }
    }


    const onCloseNamesDialog = () => {
        setOpenNamesDialog(false)
    }
    const onCloseDownloadRecordDialog = () => {
        setOpenDownloadRecordDialog(false)
    }

    const onCloseSeeMARC21RecordDialog = () => {
        setOpenSeeMARC21RecordDialog(false)
    }

    const onCloseReportSuggestionForm = () => {
        setopenReportSuggestionForm(false)
    }

    const onCloseLinksDialog = () => {
        setOpenLinksDialog(false)
    }

    const renderAltNamesBox = () => {
        if (alternateNames.length) {
            const key = 'alternativeNames'
            const label = translateKey(key, language)

            const first3Names = alternateNames.slice(0, 3)

            return <Grid container>
                <Grid item lg={4} md={5} sm={12} xs={12}><Typography
                    className={'label-typography'}>{label}</Typography></Grid>
                <Grid item lg={7} md={6} sm={12} xs={12}>
                    <Box className={'column'}>
                        {first3Names.map(item => <Typography className={'value-typography'}
                                                             key={item}
                                                             dangerouslySetInnerHTML={{__html: highlightName(item, searchedText)}}/>)}
                        {alternateNames.length > 3 && <Chip className='read-more-chip'
                                                            label={`${translateKey('showMore', language)} (+${alternateNames.length - 3})`}
                                                            onClick={() => setOpenNamesDialog(true)}
                                                            variant={'filled'}
                                                            size={'small'}
                                                            icon={
                                                                <ReadMoreIcon/>}/>}
                    </Box>
                </Grid>
                <InfoDialog title={'allAlternativeNames'} items={alternateNames} open={openNamesDialog}
                            onClose={onCloseNamesDialog}/>
            </Grid>
        }
    }

    const prepareImageAuthorLink = (link: string) => {
        if (link.includes('<a')) {
            const splitted = link.split('>')
            return `${splitted[0]} style="color: white" target="_blank">${splitted[1]}</a>`
        }
        return link
    }

    const renderViafLink = (viafId: string) => {
        const href = renderViafUrl(viafId)
        return <Link className={'redirect-typography'} target={'_blank'}
                     title={translateKey('linkOpeningPageInNewTab', language)}
                     href={href}>{href}</Link>
    }

    const renderWikidataLink = (wikidataId: string) => {
        const href = renderWikidataUrl(wikidataId)
        return <Link className={'redirect-typography'} target={'_blank'}
                     title={translateKey('linkOpeningPageInNewTab', language)}
                     href={href}>{wikidataId}</Link>
    }


    const renderIdRow = (label: string, value: string) => <Grid container key={label}><Grid item lg={4} md={5}
                                                                                            xs={12}>
        <Typography className={'label-typography-with-icon'}
        >{translateKey(label, language)}</Typography></Grid>
        <Grid item lg={8} md={7} xs={12}>


            {label === 'viaf' ? renderViafLink(value) : label === 'wikidata' ? renderWikidataLink(value) : <Typography
                className={'value-typography'}>{value}</Typography>}
        </Grid>
    </Grid>

    const renderExternalIdsBox = () => {

        return <Box>
            {renderIdRow('nlpId', `(PL)${nlpId}`)}
            {controlNumber && renderIdRow('controlNumber', controlNumber)}
            {mmsId && renderIdRow('mmsId', mmsId)}
            {externalIds && externalIds.map(item => renderIdRow(item.dataAssociated, item.stringValue))}
        </Box>
    }

    const renderDatesBox = () => <Box className={'column'}>
        <Box className={mobileView ? 'column' : 'row-space-between small-gap'}>
            <Typography className={'small-text'}>{translateKey('creationDate', language)}:</Typography>
            <Typography className={'small-text value-typography'}> {formatDate(createdTime)}</Typography>
        </Box>
        <Box className={mobileView ? 'column' : 'row-space-between small-gap'}>
            <Typography
                className={'small-text'}>{translateKey('modificationDate', language)}:</Typography>
            <Typography className={'small-text value-typography'}> {formatDate(updatedTime)}</Typography>
        </Box>
    </Box>

    const renderSuggestionBtn = () =>  <Button variant={'text'} size={'small'} className={'suggestion-button'}
                                               onClick={() => setopenReportSuggestionForm(true)}>
        {translateKey('reportSuggestion', language)}</Button>
    const renderDescriptorTypeChip = () => <Typography className={'bordered-text upper-case'} boxShadow={2}
                                                       textAlign={'center'}>{translateKey(type, language)}</Typography>

    const renderImage = () => <Box className={'narrow-box'}>
        <img src={imageValidPath} className={'avatar'} alt={imageAlt} aria-label={imageAlt}/>
        {image &&
            <Box className={'avatar-text-box'}> <Typography
                className={'avatar-text'}>{translateKey('Source', language)}:
                Wikipedia</Typography>
                {image.author && <InfoTooltip text={prepareImageAuthorLink(image.author)}/>}
            </Box>}
    </Box>

    const renderPreferredNameBox = () => <Box>
        <Typography className={'big-text bold-text descriptor-name'} variant={'h2'}
                    paddingTop={1}
                    dangerouslySetInnerHTML={{__html: highlightName(preferredName, searchedText)}}/>
        <Typography className={'label-typography'}>{translateKey('preferredName', language)}</Typography>

    </Box>

    const renderAuthorLink = () => authorData ?
        <Button className={'author-link'} href={authorData.href} startIcon={<OpenInNewOutlinedIcon/>} variant={'text'}
                style={{marginBottom: -20}}>{translateKey(`${authorData.type}_link`, language)}</Button> : null

    const renderCTAButtons = () => <Grid item className={'cta-buttons margin-top'}>
        <Button variant={'contained'} size={'small'} className={'cta-button'}
                onClick={() => setOpenLinksDialog(true)}
                startIcon={<VisibilityIcon/>}>{translateKey('descriptorInfo.seeBNServices', language)}</Button>
        <Button variant={'outlined'} size={'small'} className={'cta-button'}
                onClick={() => setOpenSeeMARC21RecordDialog(true)}
                startIcon={<InsertDriveFileIcon/>}>{translateKey('descriptorInfo.seeMARC21', language)}</Button>
        <Button variant={'contained'} size={'small'} className={'cta-button'}
                onClick={(event) => {
                    event.preventDefault()
                    setOpenDownloadRecordDialog(true)
                }}
                startIcon={<DownloadIcon/>}>{translateKey('descriptorInfo.downloadRecord', language)}</Button>

        {!downloadPdfUnlocked &&
            <Button variant={'outlined'} size={'small'} startIcon={<PictureAsPdfIcon/>} onClick={unblockPDF}
                    title={translateKey('linkDownloadingPdfTitle', language)} tabIndex={-1}
                    className={'cta-button'}>{translateKey('descriptorInfo.generatePdf', language)}</Button>}

        {downloadPdfUnlocked && <PDFDownloadLink
            document={<DescriptorPDF data={data} image={image?.imageUrl ?? imageValidPath} wikidata={wikidata}
                                     language={language} chartsImages={chartsImages}/>}
            fileName={`${preferredName}.pdf`}
            className={'pdf-download-link'}>
            {({blob, url, loading, error}) =>
                loading ? <Button disabled>{translateKey('descriptorInfo.loadingPdf', language)}</Button> :
                    <Button variant={'outlined'} size={'small'} startIcon={<PictureAsPdfIcon/>}
                            title={translateKey('linkDownloadingPdfTitle', language)} tabIndex={-1}
                            className={'pdf-button'}>{translateKey('descriptorInfo.downloadPdf', language)}</Button>}
        </PDFDownloadLink>}


        {data && <DownloadRecordDialog recordJsonData={data} open={openDownloadRecordDialog}
                                       onClose={onCloseDownloadRecordDialog}/>}
        <MARC21RecordDialog nlpId={nlpId} sourceName={sourceName} open={openSeeMARC21RecordDialog}
                            onClose={onCloseSeeMARC21RecordDialog}/>

        <LinksDialog nlpId={nlpId} open={openLinksDialog} descriptorName={preferredName} descriptorType={type}
                     onClose={onCloseLinksDialog}/>

        <ReportSuggestionDialog  open={openReportSuggestionForm}
                                 onClose={onCloseReportSuggestionForm}/>
    </Grid>


    const renderMobileView = () => {
        return <Paper className={'paper-container'}>
            {renderDescriptorTypeChip()}
            <EmptyDivider size={'small'}/>
            {renderPreferredNameBox()}
            <EmptyDivider size={'extra-small'}/>
            {renderSuggestionBtn()}
            <EmptyDivider size={'extra-small'}/>
            {renderImage()}
            <EmptyDivider size={'small'}/>
            {renderDatesBox()}
            <EmptyDivider size={'small'}/>
            {renderAltNamesBox()}
            <EmptyDivider size={'small'}/>
            {renderExternalIdsBox()}
            <EmptyDivider size={'small'}/>
            {renderCTAButtons()}


        </Paper>
    }

    const renderDesktopView = () => <Paper className={'paper-container'}>
        <Box className={'basic-info-header'}>

            <Box className={'row'}>{renderDescriptorTypeChip()}
                {type === DescriptorType.AUTHOR_TITLE &&
                    <Box>
                        {renderAuthorLink()}
                    </Box>}
            </Box>
            {renderDatesBox()}
        </Box>
        <Box className={'right-float-box'}>
        {renderSuggestionBtn()}
        </Box>
        <Grid container rowSpacing={3} columnSpacing={1} className={'basic-info'}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                    {renderPreferredNameBox()}
                </Box>
            </Grid>

            <Grid item lg={2} md={4} sm={7} xs={12} className={'margin-top'}>
                {renderImage()}
            </Grid>

            <Grid item lg={6} md={8} sm={5} xs={12} className={'margin-top'}>
                <Box>
                    {renderAltNamesBox()}
                    <EmptyDivider size={'small'}/>
                    {renderExternalIdsBox()}

                </Box>
            </Grid>
            {renderCTAButtons()}
        </Grid>
    </Paper>
    return mobileView ? renderMobileView() : renderDesktopView()
}

export default DescriptorBasicInfoCard