import {Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import React, {useContext} from "react";
import {SectionTypography} from "./common/SectionTypography";
import {SectionHeaderTypography} from "./common/SectionHeaderTypography";
import {translateKey} from "utils/fieldsFormatters";
import {LanguageContext} from "context/LanguageContext";

export interface SectionProps {
    title: string
}

type TableData = {
    fieldName: string,
    plLabel: string,
    enLabel: string,
    exampleValue: string,
}
export const Section6 = ({title}: SectionProps) => {
    const {language} = useContext(LanguageContext);

    const sectionParagraphs = ['documentationSection6_1', 'documentationSection6_2']

    const createData = (
        fieldName: string,
        plLabel: string,
        enLabel: string,
        exampleValue: string,
    ) => {
        return {fieldName, plLabel, enLabel, exampleValue} as TableData;
    }

    const rowsForTable1 = [
        createData('nlpId', 'Identyfikator BN', 'NLP ID', '"a0000001178294"'),
        createData('type', 'Typ deskryptora', 'Descriptor type', '"personal_descriptor"'),
        createData('preferredName', 'Nazwa preferowana', 'Preferred name', 'Bocheński, Józef Maria (1902-1995)'),
        createData('alternateNames', 'Warianty nazwy', 'Alternative names', '["Bocheński, Józef M.", "Bocheński, Innocenty Józef"]'),
        createData('externalIds', '[w zależności od wartości w polu dataAssociated, np.VIAF]', '[depends on the dataAssociated field value, eg.VIAF]', '[{"stringValue": "88344067", "dataAssociated": "viaf"}]'),
        createData('mmsId', 'MMS ID', 'MMS ID', '"9810648280405606"'),
        createData('controlNumber', 'Numer kontrolny', 'Control number', '"p 2004407878"'),
        createData('createdTime', 'Data utworzenia rekordu', 'Record creation date', '"2005-01-20"'),
        createData('updatedTime', 'Data aktualizacji rekordu', 'Record modification date', '"2021-08-11"'),
        createData('sourceName', 'Baza danych', 'Database', '"DBN"'),
        createData('jsonFieldValues', '[zmienny obiekt z pozostałymi polami/atrybutami w zależności od typu deskryptora i tego, czy dane pole jest wypełnione w rekordzie źródłowym]', '[variable object containing the remaining fields / attributes, depends on the descriptor type and completeness of the record]', '{"associatedPlaceOtherPlace":[{"dataAssociated": "1945-1995", "preferredName": "Fryburg(Szwajcaria)", "type":"geographic_descriptor", "nlpId":"a0000001016995"}]}')

    ]
    const rowsForTable2 = [
        createData('dateOfBirth', 'data urodzenia', 'date of birth', '1952'),
        createData('placeOfBirth', 'miejsce urodzenia', 'place of birth', 'Saragossa (Hiszpania)'),
        createData('dateOfDeath', 'data śmierci', 'date of death', '1995'),
        createData('placeOfDeath', 'miejsce śmierci', 'place of death', 'Nowy Jork (Stany Zjednoczone)'),
        createData('startOfActivity', 'data początkowa aktywności', 'start of activity', '1736'),
        createData('endOfActivity', 'data końcowa aktywności', 'end of activity', '1752'),
        createData('gender', 'płeć', 'gender', 'kobieta'),
        createData('associatedLanguage', 'język powiązany', 'associated language', 'włoski'),
        createData('affiliation', 'afiliacja', 'affiliation', 'Warszawski Uniwersytet Medyczny'),
        createData('occupation', 'zajęcie / zawód', 'occupation', 'Pracownicy naukowi'),
        createData('otherDesignation', 'inne oznaczenie', 'other designation', 'Postacie fikcyjne'),
        createData('titleOfPerson', 'tytuł lub odznaczenie', 'title of person', 'Order Wojenny Virtuti Militari'),
        createData('associatedPlaceCountry', 'miejsce powiązane (kraj)', 'associated place (country)', 'Niemcy'),
        createData('associatedPlaceResidenceOrHeadquarters', 'miejsce powiązane (siedziba)', 'associated place (residence or headquarters)', 'Mszana (woj. śląskie, pow. wodzisławski, gm. Mszana)'),
        createData('associatedPlaceAddress', 'miejsce powiązane (adres)', 'associated place (address)', 'ul. Bracka 16/10 Żagań (woj. lubuskie) Polska 68-100'),
        createData('associatedPlaceOtherPlace', 'miejsce powiązane (inne miejsce)', 'associated place (other place)', 'Gdańsk (woj. pomorskie)'),
        createData('typeOfCorporateBody', 'typ instytucji', 'type of corporate body', 'Drukarnie'),
        createData('corporateEarlierName', 'wcześniejsza nazwa instytucji', 'corporate earlier name', 'Szkoła Główna Planowania i Statystyki (Warszawa)'),
        createData('corporateLaterName', 'późniejsza nazwa instytucji', 'corporate later name', 'Szkoła Główna Handlowa (Warszawa)'),
        createData('eventEarlierName', 'wcześniejsza nazwa wydarzenia', 'event earlier name', 'Targ Poznański'),
        createData('eventLaterName', 'późniejsza nazwa wydarzenia', 'event later name', 'Międzynarodowe Targi Poznańskie'),
        createData('typeOfJurisdiction', 'typ jednostki geograficznej', 'type of jurisdiction', 'Rezerwaty przyrody'),
        createData('location', 'współrzędne geograficzne', 'coordinates', 'POINT (18.521944444444443 49.969722222222224)'),
        createData('subjectCategory', 'kategoria tematyczna', 'subject category', 'Biologia'),
        createData('issn', 'ISSN', 'ISSN', '0939-6691'),
        createData('corporateBroader', 'instytucja nadrzędna', 'corporate broader', 'Samodzielny Publiczny Szpital Wojewódzki im. Jana Bożego (Lublin)'),
        createData('corporateNarrower', 'instytucja podrzędna', 'corporate narrower', 'Oddział Chorób Zakaźnych Dziecięcych (Samodzielny Publiczny Szpital Wojewódzki im. Jana Bożego ; Lublin)'),
        createData('subjectBroader', 'deskryptor szerszy', 'subject broader', 'Muzyka instrumentalna'),
        createData('subjectNarrower', 'deskryptor węższy', 'subject narrower', 'Muzyka na gitarę basową'),
        createData('genreBroader', 'gatunek szerszy', 'genre broader', 'Wizerunki maryjne'),
        createData('genreNarrower', 'gatunek węższy', 'genre narrower', 'Film bhutański'),
        createData('eventBroader', 'wydarzenie szersze', 'event broader', 'Festiwal Szekspirowski (Gdańsk)'),
        createData('eventNarrower', 'wydarzenie węższe', 'event narrower', 'Festiwal Szekspirowski (22 ; 2018 ; Gdańsk)'),
        createData('geographicalBroader', 'jednostka geograficzna nadrzędna', 'geographical broader', 'Warszawa (woj. mazowieckie)'),
        createData('geographicalNarrower', 'jednostka geograficzna podrzędna', 'geographical narrower', 'Cisowa (Gdynia ; część miasta)'),
        createData('relatedPerson', 'osoba powiązana', 'related person', 'Zahler, S. Craig (1973- )'),
        createData('relatedCorporateBody', 'instytucja powiązana', 'related corporate body', 'Bezpartyjny Blok Współpracy z Rządem'),
        createData('relatedEvent', 'wydarzenie powiązane', 'related event', 'Praskie Warsztaty Twórcze'),
        createData('relatedSubject', 'deskryptor powiązany', 'related subject', 'Energia'),
        createData('relatedGenre', 'gatunek powiązany', 'related genre', 'Muzyka na wiolonczelę'),
        createData('publicGeneralNote', 'ogólna uwaga publiczna', 'public general note', 'deskryptor - Poezja śpiewana stosuje się dla utworów słowno-muzycznych, w których tekst jest samodzielnym (...)'),
        createData('sourceDataFound', 'źródło / cytata biliograficzna', 'source data / bibliographic note', 'Dzieci smoka / Andrzej Kołodyński. - Łódź, 1994.'),
        createData('sourceDataNotFound', 'źródło, w którym nie znaleziono informacji', 'source data not found', 'KRS')
    ]
    const renderTable = (rows: TableData[]) => <Grid item xs={12}>
        <TableContainer>
            <Table aria-label="table-2">
                <TableHead>
                    <TableRow>
                        <TableCell className={'table-header'}>{translateKey('fieldName', language)}</TableCell>
                        <TableCell className={'table-header'}>{translateKey('plLabel', language)}</TableCell>
                        <TableCell className={'table-header'}>{translateKey('enLabel', language)}</TableCell>
                        <TableCell className={'table-header'}>{translateKey('exampleValue', language)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.fieldName}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell>{row.fieldName}</TableCell>
                            <TableCell>{row.plLabel}</TableCell>
                            <TableCell>{row.enLabel}</TableCell>
                            <TableCell>{row.exampleValue}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>
    return <Box className={'docs-section'}>
        <SectionHeaderTypography>{title}</SectionHeaderTypography>

        {sectionParagraphs.map((paragraph, idx) =>
            <SectionTypography key={`section-6-${idx}`}>{translateKey(paragraph, language)}</SectionTypography>)}
        {renderTable(rowsForTable1)}
        <SectionTypography>{translateKey('documentationSection6_3', language)}</SectionTypography>
        {renderTable(rowsForTable2)}

    </Box>

}
