import {
    Box, Button,
    Chip, Drawer, FormControl, FormControlLabel, FormLabel,
    Grid, LinearProgress,
    List,
    ListItem, ListItemButton,
    MenuItem, Radio, RadioGroup,
    Select,
    SelectChangeEvent, ToggleButton,
    Typography, useMediaQuery, useTheme, ToggleButtonGroup
} from "@mui/material";
import {
    BasicDescriptorInfo,
    DateTimeRange,
    FilterItem,
    INITIAL_FILTERS,
    SORT_MODES,
    SimilarObjectsDict,
    EXTRA_FILTERS
} from "consts/khw-consts";
import React, {Suspense, useContext, useEffect, useState, lazy} from "react";
import {translateKey} from "utils/fieldsFormatters";
import {LanguageContext} from "context/LanguageContext";
import {
    DescriptorsListQueryParams,
    fetchDescriptorsList,
    fetchDescriptorsListByAlternateNames,
    searchBySimilarPhrases
} from "services/khwApi";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useQueries} from "@tanstack/react-query";
import _, {pickBy, omit} from 'lodash'
import CloseIcon from "@mui/icons-material/Close";
import DescriptorSimilarObjects from "./DescriptorSimilarObjects";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewIcon from '@mui/icons-material/GridView';
import {useCookies} from "react-cookie";
import {highlightName} from "utils/highlightName";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SEO from "../common/SEO";


const DescriptorCard = lazy(() => import("components/descriptorsList/DescriptorCard"));
const FiltersBox = lazy(() => import("components/descriptorsList/FiltersBox"));
const BreadcrumbsRow = lazy(() => import("components/common/BreadcrumbsRow"));
const EmptyDivider = lazy(() => import("components/common/EmptyDivider"));
const PaginationBox = lazy(() => import("components/common/PaginationBox"));
const MARC21RecordDialog = lazy(() => import("components/descriptorInfo/dialogs/MARC21RecordDialog"));


const DescriptorsList = () => {
    const {language} = useContext(LanguageContext);

    const INITIAL_PAGE_SIZE = 30
    const [queryParams, setQueryParams] = useSearchParams()
    const [result, setResult] = useState<BasicDescriptorInfo[]>([])
    const [alternateNamesResult, setAlternateNamesResult] = useState<BasicDescriptorInfo[]>([])
    const [filters, setFilters] = useState<FilterItem[]>([...INITIAL_FILTERS])
    const [selectedSort, setSelectedSort] = useState<string>(SORT_MODES.BY_CREATION_DATE_DESCENDING)
    const [showSortDrawer, setShowSortDrawer] = useState(false)
    const [showFilterDrawer, setShowFilterDrawer] = useState(false)
    const [totalPages, setTotalPages] = useState(0)
    const [totalRecords, setTotalRecords] = useState(0)
    const [alternateNamesTotalRecords, setAlternateNamesTotalRecords] = useState(0)
    const [aggregations, setAggregations] = useState([])
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const [selectedFilters, setSelectedFilters] = useState({})
    const searchedText = queryParams.get('searchedText')
    const navigate = useNavigate()
    const [cookies, setCookie] = useCookies(['displayMode']);
    const [loading, setLoading] = useState(false)
    const [resultsQuery, alternateNamesQuery] = useQueries({
        queries: [
            {
                queryKey: ['descriptors', selectedFilters],
                queryFn: () => fetchDescriptorsList(selectedFilters)
            },
            {
                queryKey: ['alternateNamesDescriptors', selectedFilters],
                queryFn: () => fetchDescriptorsListByAlternateNames(selectedFilters)
            },
        ]


    })

    const [similarRecords, setSimilarRecords] = useState<SimilarObjectsDict | null>(null)
    const [displayMode, setDisplayMode] = useState(cookies.displayMode ?? 'cards')
    const [openSeeMARC21RecordDialog, setOpenSeeMARC21RecordDialog] = useState(false)
    const [selectedNlpId, setSelectedNlpId] = useState('')
    const [selectedSourceName, setSelectedSourceName] = useState('')
    const [selectedExtraFilters, setSelectedExtraFilters] = useState<{ [key: string]: (string)[] }>({})

    const onCloseSeeMARC21RecordDialog = () => {
        setOpenSeeMARC21RecordDialog(false)
        setSelectedSourceName('')
        setSelectedNlpId('')
    }
    useEffect(() => {
        //fetching classic search results
        if (resultsQuery?.data) {
            const {result, totalPages, totalHits, aggregations} = resultsQuery?.data.data
            setResult(result)
            setTotalPages(totalPages)
            setTotalRecords(totalHits)
            setAggregations(aggregations)
        }
        //fetching alternate names search results
        const searchedText = queryParams.get('searchedText')
        if (alternateNamesQuery?.data && searchedText) {
            const {result, totalHits} = alternateNamesQuery?.data.data
            setAlternateNamesResult(result)
            setAlternateNamesTotalRecords(totalHits)
        }
    }, [resultsQuery, alternateNamesQuery, queryParams])

    const navigateToDescriptorDetails = (nlpId: string) => {
        if (searchedText) localStorage.setItem('searchedText', searchedText)
        navigate(`/descriptor-details/${nlpId}`)
    }

    useEffect(() => {
        const searchedText = queryParams.get('searchedText')

        if (searchedText && !result.length) {
            searchBySimilarPhrases(searchedText).then(res => {
                setSimilarRecords(res.data.similarPhrasesBySource)
            }).catch(err => {
                console.log(err)
            })
        } else if (!searchedText) {
            setAlternateNamesResult([])
            setAlternateNamesTotalRecords(0)
        }
    }, [result, queryParams])

    const getDDMMYYYDateFormat = (date: Date) => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`

    useEffect(() => {
            // fetching basic queryParams
            const descriptorType = queryParams.getAll('descriptorType')
            const sourceName = queryParams.getAll('sourceName') ?? 'DBN'
            const searchedText = queryParams.get('searchedText')
            const page = !!queryParams.get('page') ? Number(queryParams.get('page')) : 0
            const size = !!queryParams.get('size') ? Number(queryParams.get('size')) : INITIAL_PAGE_SIZE
            const order = !!queryParams.get('order') ? queryParams.get('order') : 'DESC'
            let sortBy = queryParams.get('sortBy')
            if (searchedText && sortBy === null) {
                sortBy = 'SCORE'
                setSelectedSort(SORT_MODES.BY_SCORE_DESCENDING)

            } else if (sortBy === null) {
                sortBy = 'CREATED_TIME'
                setSelectedSort(SORT_MODES.BY_CREATION_DATE_DESCENDING)
            } else {
                const currentSelectedSort = sortBy + '__' + order
                setSelectedSort(currentSelectedSort)
            }

            // fetching date fields from queryParams
            const createdTimeFrom = queryParams.get('createdTimeFrom')
            const createdTimeTo = queryParams.get('createdTimeTo')
            const updatedTimeFrom = queryParams.get('updatedTimeFrom')
            const updatedTimeTo = queryParams.get('updatedTimeTo')


            const extraFiltersMap: { [key: string]: (string)[] } = {}

            EXTRA_FILTERS.forEach(extraFilter => {
                const values = queryParams.getAll(extraFilter)
                if (values.length > 0) {
                    extraFiltersMap[extraFilter] = values
                }
            })
            setSelectedExtraFilters(extraFiltersMap)

            let queryFilters = filters
                .map(item => {
                    item.isSelected = (item.type === 'descriptor_type' && descriptorType.includes(item.fullName))
                        || (item.type === 'source' && sourceName.includes(item.fullName))
                    if (item.fullName === 'creation_date' && (!!createdTimeFrom || !!createdTimeTo)) {
                        const dateFrom = createdTimeFrom ? new Date(Date.parse(createdTimeFrom)) : null
                        const dateTo = createdTimeTo ? new Date(Date.parse(createdTimeTo)) : null
                        item = {...item, value: {from: dateFrom, to: dateTo}, isSelected: true}
                    } else if (item.fullName === 'modification_date' && (!!updatedTimeFrom || !!updatedTimeTo)) {
                        const dateFrom = updatedTimeFrom ? new Date(Date.parse(updatedTimeFrom)) : null
                        const dateTo = updatedTimeTo ? new Date(Date.parse(updatedTimeTo)) : null
                        item = {...item, value: {from: dateFrom, to: dateTo}, isSelected: true}
                    }
                    return item
                })

            setFilters(queryFilters)

            setSelectedFilters(pickBy({
                ...selectedFilters,
                ...extraFiltersMap,
                descriptorType,
                sourceName,
                page,
                size,
                order,
                sortBy,
                searchedText,
                createdTimeFrom,
                createdTimeTo,
                updatedTimeFrom,
                updatedTimeTo
            }, v => !!v))
        },
        [queryParams]
    )

    const updateFiltersAndQueryParams = (filterType: string, newFilters: FilterItem[]) => {
        let page = '0'

        if (filterType === 'descriptor_type') {
            const selectedDescriptors = newFilters.filter(item => item.isSelected && item.type === 'descriptor_type').map(item => `${item.fullName}`)

            setQueryParams(pickBy({
                ...selectedFilters,
                page,
                descriptorType: selectedDescriptors
            }, v => !!v))

        } else if (filterType === 'source') {
            const selectedSources = newFilters.filter(item => item.isSelected && item.type === 'source').map(item => item.fullName)

            setQueryParams(pickBy({
                ...selectedFilters,
                page,
                sourceName: selectedSources
            }, v => !!v))

        } else if (filterType === 'date') {
            const createdTime = newFilters.find(item => item.fullName === 'creation_date')?.value
            const updatedTime = newFilters.find(item => item.fullName === 'modification_date')?.value
            let createdTimeFrom, createdTimeTo, updatedTimeFrom, updatedTimeTo
            if (createdTime) {
                const {from, to} = createdTime
                createdTimeFrom = !!from ? getDDMMYYYDateFormat(from) : undefined
                createdTimeTo = !!to ? getDDMMYYYDateFormat(to) : undefined
            }

            if (updatedTime) {
                const {from, to} = updatedTime
                updatedTimeFrom = !!from ? getDDMMYYYDateFormat(from) : undefined
                updatedTimeTo = !!to ? getDDMMYYYDateFormat(to) : undefined
            }

            // @ts-ignore
            setQueryParams(pickBy({
                ...selectedFilters,
                page,
                createdTimeFrom: createdTimeFrom,
                createdTimeTo: createdTimeTo,
                updatedTimeFrom: updatedTimeFrom,
                updatedTimeTo: updatedTimeTo
            }, v => !!v))

        }
    }

    const handleSortModeChange = (event: SelectChangeEvent) => {
        const sortOrder = event.target.value.split('__')
        const sortBy = sortOrder[0]
        const order = sortOrder[1]
        setSelectedSort(event.target.value as string);
        const cleanedFilters = pickBy(selectedFilters, v => !!v)
        setSelectedFilters({...cleanedFilters, order, sortBy})
        setQueryParams({...cleanedFilters, order, sortBy})
    };

    const onSelectExtraFilter = (filterName: string, value: string, isDate?: boolean) => {
        const extraFilters = selectedExtraFilters
        if (filterName in extraFilters && !isDate) {
            if (extraFilters[filterName].includes(value)) { // removing filter if already exists
                extraFilters[filterName] = extraFilters[filterName].filter(f => f !== value)
            } else {

                extraFilters[filterName].push(value)
            }
        } else if (!(filterName in extraFilters) || isDate) {
            extraFilters[filterName] = [value]
        }

        setSelectedExtraFilters(extraFilters)
        setQueryParams({...selectedFilters, ...extraFilters})
    }

    const onSelectMultipleExtraFilters = (filterName: string, values: string[]) => {
        const extraFilters = selectedExtraFilters
        extraFilters[filterName] = values
        setSelectedExtraFilters(extraFilters)
        setQueryParams({...selectedFilters, ...extraFilters})
    }
    const onSelectFilter = (selectedFilterName: string, type: string) => {
        let cleanExtra = false
        const newFilters = filters.map(item => {
            if (item.fullName === selectedFilterName) {
                if (item.isSelected && type === 'descriptor_type' && !_.isEmpty(selectedExtraFilters)) {
                    cleanExtra = true
                }
                item.isSelected = !item.isSelected
            }
            return item
        })

        if (cleanExtra) {
            cleanExtraFilters()
            // setFilters(newFilters)
        } else {
            setFilters(newFilters)
            updateFiltersAndQueryParams(type, newFilters)
        }

    }

    const onSelectDateFilter = (name: string, value: DateTimeRange) => {
        const newFilters = filters.map(item => {
            if (item.fullName === name) {
                item.value = value
                item.isSelected = !(value.from === null && value.to === null)
            }
            return item
        })
        setFilters(newFilters)
        updateFiltersAndQueryParams('date', newFilters)

    }

    const onChangeOffset = (offset: number) => {
        const cleanedFilters = pickBy(selectedFilters, v => !!v)
        setSelectedFilters({...cleanedFilters, size: offset})
        setQueryParams({...cleanedFilters, size: offset.toString()})
    }


    const onChangePage = (page: number) => {
        const cleanedFilters = pickBy(selectedFilters, v => !!v)
        setSelectedFilters({...cleanedFilters, page})
        setQueryParams({...cleanedFilters, page: page.toString()})
    }

    const onClickMARC21 = (item: BasicDescriptorInfo) => {
        setSelectedNlpId(item.nlpId)
        setSelectedSourceName(item.sourceName)
        setOpenSeeMARC21RecordDialog(true)
    }

    const cleanExtraFilters = () => {
        const updatedFilters = _.omit({...selectedFilters}, [...EXTRA_FILTERS, 'descriptorType']);
        setQueryParams(updatedFilters)
        setSelectedFilters(updatedFilters)
        setSelectedExtraFilters({})
    }

    const handleDeleteExtraFilter = (name: string, value: string) => {
        const extraFilters = selectedExtraFilters
        extraFilters[name] = extraFilters[name].filter(f => f !== value)
        setSelectedExtraFilters(extraFilters)
        setSelectedFilters({...selectedFilters, ...extraFilters})
        setQueryParams({...selectedFilters, ...extraFilters})

    }
    const handleDeleteFilter = (filterValue: string, type: string) => {
        const newFilters = filters.map(item => {
            if (item.fullName === filterValue) {
                item.isSelected = false
                item.value = null
            }
            return item
        })
        setFilters(newFilters)
        updateFiltersAndQueryParams(type, newFilters)

        if (type === 'descriptor_type' && !_.isEmpty(selectedExtraFilters)) {
            // removing all extra filters if descriptor type was deleted
            cleanExtraFilters()

        }

    }

    const handleDeleteSearchedText = () => {
        const cleanedFilters = omit(selectedFilters, ['searchedText']);
        setSelectedFilters({...cleanedFilters})
        setQueryParams({...cleanedFilters})
    }

    const renderSelectedFilters = () => {
        const selectedQueryFilter = queryParams.get('searchedText') ? <Chip key={queryParams.get('searchedText')}
                                                                            className={'filter-chip'}
                                                                            label={`${translateKey('searchedText', language)}: ${queryParams.get('searchedText')}`}
                                                                            onDelete={handleDeleteSearchedText}/> : null
        const descriptorTypeFilters = filters.filter(item => item.isSelected && item.type === 'descriptor_type')
            .map(item =>
                <Chip key={item.fullName}
                      className={'filter-chip'}
                      label={`${translateKey('descriptorType', language)}: ${translateKey(item.fullName, language)}`}
                      onDelete={() => handleDeleteFilter(item.fullName, item.type)}/>)

        const sourceFilters = filters.filter(item => item.isSelected && item.type === 'source' && item.label !== undefined)
            .map(item =>
                <Chip
                    key={item.fullName}
                    className={'filter-chip'}
                    label={`${translateKey('Files', language)}:  ${translateKey(item.fullName, language)}`}
                    onDelete={() => handleDeleteFilter(item.fullName, item.type)}/>)

        const dateFilters = filters.filter(item => item.isSelected && item.type === "date" && (item.value?.from || item.value?.to))
            .map(item =>
                <Chip key={item.fullName} className={'filter-chip'}
                      label={`${translateKey(item.fullName, language)}: ${item.value?.from?.toLocaleDateString() ?? '...'} - ${item.value?.to?.toLocaleDateString() ?? '...'}`}
                      onDelete={() => handleDeleteFilter(item.fullName, item.type)}/>)


        let extraFiltersChipsData = Object.entries(selectedExtraFilters).reduce((accumulator: {
            [key: string]: string
        }[], [key, values]) => {
            values.forEach(val => {
                accumulator.push({name: key, value: val});
            });
            return accumulator;
        }, []);

        const extraFilters = extraFiltersChipsData.map(item =>
            <Chip key={item.value}
                  className={'filter-chip'}
                  label={`${translateKey(item.name, language)}: ${item.value}`}
                  onDelete={() => handleDeleteExtraFilter(item.name, item.value)}/>
        )
        if (selectedQueryFilter === null && sourceFilters.length === 0 && descriptorTypeFilters.length === 0 && dateFilters.length === 0) {
            return <Box className={'selected-filters'}>
                <Typography
                    className={'selected-filters-label'}>{translateKey('selectedFilters', language)}: {translateKey('None', language)}</Typography>
            </Box>
        }

        return <Box className={'selected-filters'}>
            <Typography className={'selected-filters-label'}>
                {translateKey('selectedFilters', language)}:</Typography>{selectedQueryFilter}{sourceFilters}{descriptorTypeFilters}{dateFilters}{extraFilters}
        </Box>
    }

    const renderSortSelect = () => <FormControl className={'sort-box'}>

        <FormLabel id={'sort-select-label'}
                   aria-label={translateKey('sortByReader', language)}>{translateKey('sortBy:', language)}
            <Select labelId={'sort-select-label'} size={'small'} value={selectedSort}
                    onChange={handleSortModeChange}
                    variant={'outlined'}>
                {Object.entries(SORT_MODES).map(([key, value]) => <MenuItem
                    value={value}
                    key={value}>{translateKey(value, language)}</MenuItem>)}
            </Select></FormLabel>
    </FormControl>


    const renderSortRadioGroup = () => <FormControl style={{padding: 16}}>
        <Box className={'row-space-between'}>
            <FormLabel id="sort-radio-buttons-group-label"
                       className={'upper-case bold-text'}>{translateKey('sort', language)}</FormLabel>
            <CloseIcon onClick={() => setShowSortDrawer(false)} aria-label={'close'}/>
        </Box>
        <RadioGroup
            aria-labelledby="sort-radio-buttons-group-label"
            value={selectedSort}
            name="radio-buttons-group"
            onChange={handleSortModeChange}
        >
            {Object.entries(SORT_MODES).map(([key, value]) => <FormControlLabel key={key} value={value}
                                                                                control={<Radio/>}
                                                                                label={translateKey(value, language)}/>)}
        </RadioGroup>
    </FormControl>

    const renderDescriptorsList = (dataToRender: BasicDescriptorInfo[], isAlternate: boolean = false) => <List>
        {dataToRender.map((item: BasicDescriptorInfo) => {
                return displayMode === 'cards' ?
                    <ListItem key={item.nlpId}>
                        <Suspense fallback={<Box sx={{width: '100%'}}>
                            <LinearProgress/>
                        </Box>}><DescriptorCard item={item} navigateToDescriptorDetails={navigateToDescriptorDetails}
                                                isAlternate={isAlternate}/>
                        </Suspense>
                    </ListItem> : <><ListItem disablePadding key={item.nlpId}
                                              className={isAlternate ? 'list-item-alternate' : 'list-item'}>
                        <ListItemButton onClick={() => navigateToDescriptorDetails(item.nlpId)}
                                        className={'list-item-name'}>
                            <Typography dangerouslySetInnerHTML={{
                                __html: isAlternate ? `<b>${highlightName(item.preferredName, searchedText)}</b> [${
                                    translateKey(item.type,
                                        language)}] <br><small>${translateKey('tracing', language).toLowerCase()}</small>` : `<b>${highlightName(item.preferredName, searchedText)}</b> <br>[${
                                    translateKey(item.type,
                                        language)}]`
                            }}/>


                        </ListItemButton>
                        <Button variant={'text'} size={'small'} className={'marc21-btn-list-view'}
                                onClick={() => onClickMARC21(item)}
                                startIcon={<InsertDriveFileIcon/>}>MARC 21</Button>

                    </ListItem>

                    </>
            }
        )
        }

    </List>

    const renderSimilarRecords = () => {
        if (!!similarRecords && (!!similarRecords.DBN || !!similarRecords.MESH)) {
            return <DescriptorSimilarObjects records={similarRecords}/>
        } else {
            return <Typography
                style={{marginLeft: 15, marginTop: 10}}>{translateKey('noResultsFound', language)}</Typography>
        }
    }

    const renderFiltersDrawer = () => <Drawer anchor={"left"} open={showFilterDrawer} variant={'temporary'}
                                              onClose={() => setShowFilterDrawer(false)}
    >

        <Box overflow={'scroll'} height={'100%'}>

            <Suspense fallback={<Box sx={{width: '100%'}}>
                <LinearProgress/>
            </Box>}><FiltersBox filters={filters} extraFilters={selectedExtraFilters} onSelectFilter={onSelectFilter}
                                onSelectExtraFilter={onSelectExtraFilter}
                                onSelectMultipleExtraFilters={onSelectMultipleExtraFilters}
                                mobileView
                                onCloseFilters={() => setShowFilterDrawer(false)}
                                onSelectDateFilter={onSelectDateFilter} aggregations={aggregations}/></Suspense>
        </Box>
    </Drawer>

    const renderSortDrawer = () => <Drawer anchor={"top"} open={showSortDrawer}
                                           onClose={() => setShowSortDrawer(false)}
    >
        {renderSortRadioGroup()}
    </Drawer>


    const renderPaginationBox = () => <Suspense fallback={<Box sx={{width: '100%'}}>
        <LinearProgress/>
    </Box>}><PaginationBox pages={totalPages}
                           currentPage={(selectedFilters as DescriptorsListQueryParams).page ?? 0}
                           offset={(selectedFilters as DescriptorsListQueryParams).size ?? INITIAL_PAGE_SIZE}
                           onChangeOffset={onChangeOffset}
                           onChangePage={onChangePage}/></Suspense>

    const renderDisplayModeToggle = () => <ToggleButtonGroup
        value={displayMode}
        exclusive
        onChange={(event, value) => {
            setDisplayMode(value)
            setCookie('displayMode', value)
        }}
        aria-label="display mode"
        size={'small'}
        className={'display-mode-group'}
    >
        <ToggleButton value="cards" aria-label="cards">
            <GridViewIcon color={'primary'}/>
        </ToggleButton>
        <ToggleButton value="list" aria-label="list">
            <FormatListBulletedIcon color={'primary'}/>
        </ToggleButton>
    </ToggleButtonGroup>

    const renderDesktopView = () => <>
        {loading ? <Box sx={{width: '100%'}}>
            <LinearProgress/>
        </Box> : <>
            <h1 className="screen-reader-text">{translateKey('browseDatabaseScreenReaderHeader', language)}</h1>

            <EmptyDivider size={'medium'}/>

            <Grid container>
                <Grid item md={0} lg={2}/>
                <Grid item md={3} lg={3}>
                    <Suspense fallback={<Box sx={{width: '100%'}}>
                        <LinearProgress/>
                    </Box>}><FiltersBox filters={filters} extraFilters={selectedExtraFilters}
                                        onSelectExtraFilter={onSelectExtraFilter}
                                        onSelectFilter={onSelectFilter}
                                        onSelectMultipleExtraFilters={onSelectMultipleExtraFilters}
                                        onSelectDateFilter={onSelectDateFilter}
                                        aggregations={aggregations}/></Suspense>
                </Grid>
                <Grid item md={6} lg={5}>
                    <Box className={'row-space-between header-box'}>
                        <Typography textAlign={'left'} variant={'h2'}
                                    className={'h1-header'}>{translateKey('Files', language)}</Typography>

                        {renderDisplayModeToggle()}
                    </Box>
                    {renderSelectedFilters()}
                    {!!result.length && <Box className={'sort-results-box'}>
                        {renderSortSelect()}
                        {alternateNamesTotalRecords > 0 ? <Typography
                            className={'results-info'}>{translateKey('results', language)}: <b>{totalRecords}</b> (+{alternateNamesTotalRecords} {translateKey('tracing', language)})
                        </Typography> : <Typography
                            className={'results-info'}>{translateKey('results', language)}: <b>{totalRecords}</b>
                        </Typography>}
                    </Box>}

                    {!!alternateNamesResult.length && renderDescriptorsList(alternateNamesResult, true)}
                    {!!result.length ? renderDescriptorsList(result) : renderSimilarRecords()}
                    {!!result.length && renderPaginationBox()}
                </Grid>

            </Grid></>
        }</>

    const renderMobileView = () => <Box>
        <Box className={'mobile-descriptors-list'}>
            <h1 className="screen-reader-text">{translateKey('browseDatabaseScreenReaderHeader', language)}</h1>

            {!!result.length && <Button className={'filter-mobile-btn'} variant={"outlined"} size={'large'}
                                        onClick={() => setShowFilterDrawer(true)}>{translateKey('filterVerb', language)}</Button>}
            {!!result.length && <Button className={'filter-mobile-btn'} variant={"contained"} size={'large'}
                                        onClick={() => setShowSortDrawer(true)}>{translateKey('sortVerb', language)}</Button>}
        </Box>
        <Box className={'padded-mobile-box'}>
            {renderSelectedFilters()}</Box>
        <Box className={'padded-mobile-box'}>

            <Typography>{translateKey('results', language)}: {totalRecords}</Typography>
        </Box>

        {!!alternateNamesResult.length && renderDescriptorsList(alternateNamesResult, true)}

        {!!result.length ? renderDescriptorsList(result) : renderSimilarRecords()}

        {!!result.length && renderPaginationBox()}
        {!!result.length && renderFiltersDrawer()}
        {!!result.length && renderSortDrawer()}
    </Box>


    return <Box>
        <SEO
            description={'Przeglądaj bazę danych Deskryptorów Biblioteki Narodowej i MeSH-POL (Medical Subject Headings-POL).'}/>
        <EmptyDivider size={'small'}/>
        <Suspense fallback={<Box sx={{width: '100%'}}>
            <LinearProgress/>
        </Box>}>
            <Grid container>
                <Grid item md={2}/>


                <BreadcrumbsRow content={translateKey('Browsing', language)}/>

            </Grid>
            {mobileView ? renderMobileView() : renderDesktopView()}
            {selectedNlpId && selectedSourceName &&
                <MARC21RecordDialog nlpId={selectedNlpId} open={openSeeMARC21RecordDialog}
                                    sourceName={selectedSourceName}
                                    onClose={onCloseSeeMARC21RecordDialog}/>}
        </Suspense>
    </Box>

}

export default DescriptorsList