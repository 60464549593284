import React, {Suspense, useContext, useState, lazy, useEffect} from "react";
import {
    Box,
    BoxProps, Button, Dialog, DialogContent, Drawer, FormControl, FormLabel,
    Grid, IconButton, LinearProgress, List, ListItem,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {LanguageContext} from "context/LanguageContext";
import {LanguageType} from "consts/khw-consts";
import {useNavigate} from "react-router-dom";
import {translateKey} from "utils/fieldsFormatters";
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from "@mui/icons-material/Search";
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import logo from 'assets/logo.jpg'

const AutocompleteSearch = lazy(() => import("components/common/AutocompleteSearch"));
const DialogTitleWithCloseIcon = lazy(() => import("components/common/DialogTitleWithCloseIcon"));
const Footer = lazy(() => import("components/common/Footer"));

interface ContainerWithSearchProps extends BoxProps {
    showSearch?: boolean
    alwaysShowFooter?: boolean
}

const ContainerWithSearch = ({
                                 showSearch = true,
                                 alwaysShowFooter = false,
                                 children
                             }: ContainerWithSearchProps) => {
    const {language, setLanguage} = useContext(LanguageContext);
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const navigate = useNavigate()

    const [openMenu, setOpenMenu] = useState(false)
    const [openSearchModal, setOpenSearchModal] = useState(false)
    const [currentFontSizeLevel, setCurrentFontSizeLevel] = useState(0)

    useEffect(() => {
        document.documentElement.lang = language
    }, [language])

    useEffect(() => {
        if (currentFontSizeLevel === 0) {
            document.body.className = ''
        } else if (currentFontSizeLevel === 1) {
            document.body.className = 'font-plus'

        } else {
            document.body.className = 'font-plus-plus'

        }
    }, [currentFontSizeLevel])

    const handleLangClick = (e: SelectChangeEvent) => {
        const {value} = e.target
        if (setLanguage) {
            setLanguage(value as LanguageType)
        }
    };
    const onFontSizeChange = (mode: string) => {
        if (mode === 'increase') {
            setCurrentFontSizeLevel(currentFontSizeLevel < 2 ? currentFontSizeLevel + 1 : 2)
        } else {
            setCurrentFontSizeLevel(currentFontSizeLevel > 0 ? currentFontSizeLevel - 1 : 0)
        }
        if (mobileView) {
            setOpenMenu(false)
        }
    }
    const toggleMenuDrawer = () => {
        setOpenMenu(!openMenu)
    }
    const renderSearchInput = () => <Suspense fallback={<Box sx={{width: '100%'}}>
        <LinearProgress/>
    </Box>}><AutocompleteSearch showSearchBtn viewMode={'desktop_nav'} showPlaceholder/></Suspense>

    const renderLogo = () => <Box className={'logo-box'} aria-label={'Deskryptory Biblioteki Narodowej - Strona główna'}
    >
        <img src={logo} alt={'Deskryptory Biblioteki Narodowej - logo'} onClick={() => navigate('/')}
             aria-label={'Przejdź do strony głównej Deskryptorów Biblioteki Narodowej'}
             role={'button'} tabIndex={0}
             className={'logo'}/></Box>

    const renderFilesIcon = () => <Box className={'files-redirect-box'} tabIndex={0}
                                       onClick={() => navigate('/descriptors')}>
        <Typography color={'secondary'}>{translateKey('browseDatabase', language).toUpperCase()}</Typography></Box>

    const renderLangSelect = (extraAction?: () => void) => {

        const onChange = (e: SelectChangeEvent) => {
            handleLangClick(e)
            if (extraAction) {
                extraAction()
            }
        }

        return <FormControl><FormLabel id={'lang-select'} aria-label={translateKey('selectLanguage', language)}><Select
            value={language}
            className={'select lang-select'}
            size={'small'} onChange={onChange}
            variant={'outlined'}
        >
            <MenuItem value={'pl'}
                      className={'lang-button'}>PL</MenuItem>
            <MenuItem value={'en'}
                      className={'lang-button'}>EN</MenuItem>
        </Select></FormLabel></FormControl>
    }

    const renderSearchDialog = () => <Dialog open={openSearchModal} fullWidth={true}>
        <Suspense fallback={<Box sx={{width: '100%'}}>
            <LinearProgress/>
        </Box>}><DialogTitleWithCloseIcon title={'Wyszukaj deskryptor'} extraClass={'search-modal-title'}
                                          onClose={() => setOpenSearchModal(false)}/></Suspense>

        <DialogContent>
            <Typography textAlign={"center"}
                        style={{marginBottom: 20, marginTop: 0}}>{translateKey('searchPlaceholderTextTitle', language)}
            </Typography>
            <Suspense fallback={<Box sx={{width: '100%'}}>
                <LinearProgress/>
            </Box>}><AutocompleteSearch showSearchBtn showPlaceholder={false} showInListCTAButtons
                                        viewMode={'mobile_nav'}
                                        extraAction={() => {
                                            setOpenSearchModal(false);
                                            setOpenMenu(false)
                                        }}/></Suspense>
        </DialogContent>
    </Dialog>


    const renderDesktopView = () => <Grid container className={'toolbar'} role={'navigation'}>
        <Grid item md={2}>{renderLogo()}</Grid>
        <Grid item md={1}>{showSearch && renderFilesIcon()}</Grid>
        <Grid item md={7} textAlign={"center"}>{showSearch && renderSearchInput()}</Grid>
        <Grid item md={2} textAlign={'right'} className={'lang-font-actions'}>
            <IconButton onClick={() => onFontSizeChange('decrease')} className={'font-button'}
                        title={translateKey('smallerFont', language)}
                        aria-label={translateKey('smallerFont', language)}><TextDecreaseIcon
                fontSize={'large'}
                color={'secondary'}/></IconButton>
            <IconButton onClick={() => onFontSizeChange('increase')} className={'font-button'}
                        aria-label={translateKey('largerFont', language)}
                        title={translateKey('largerFont', language)}><TextIncreaseIcon
                fontSize={'large'}

                color={'secondary'}/></IconButton>
            {renderLangSelect()}
        </Grid>
    </Grid>


    const renderDrawer = () => <Drawer
        className={'mobile-menu-drawer'}
        sx={{width: 200}}
        anchor={'left'}
        open={openMenu}
        onClose={toggleMenuDrawer}
    >
        <List>
            <ListItem>
                <Typography onClick={() => {
                    navigate('/descriptors');
                    setOpenMenu(false)
                }}>{translateKey('Files', language)}</Typography>
            </ListItem>

            <ListItem><Button title={translateKey('smallerFont', language)}
                              aria-label={translateKey('smallerFont', language)}
                              onClick={() => onFontSizeChange('decrease')}
                              startIcon={<TextDecreaseIcon
                                  color={'primary'}/>}>{translateKey('smallerFont', language)}</Button></ListItem>
            <ListItem><Button title={translateKey('largerFont', language)}
                              aria-label={translateKey('largerFont', language)}
                              onClick={() => onFontSizeChange('increase')}
                              startIcon={<TextIncreaseIcon
                                  color={'primary'}/>}>{translateKey('largerFont', language)}</Button></ListItem>
            <ListItem>
                {renderLangSelect(() => setOpenMenu(false))}
            </ListItem>
        </List>


    </Drawer>

    const renderSearchBtn = () => <Button variant={'outlined'} startIcon={<SearchIcon/>} className={'mobile-search-btn'}
                                          size={'large'}
                                          onClick={() => setOpenSearchModal(true)}>{translateKey('search', language)}</Button>


    const renderMobileView = () => <Grid container className={'toolbar'} role={'navigation'}>
        <Grid item sm={3} xs={4} className={'logo-mobile-box'}>
            {renderLogo()}
        </Grid>
        <Grid item sm={2} xs={0}/>
        <Grid item sm={5} xs={5} justifyContent={'center'}>
            {showSearch && renderSearchBtn()}
            {renderSearchDialog()}
        </Grid>
        <Grid item sm={1}/>
        <Grid item sm={1}>
            <IconButton onClick={toggleMenuDrawer}><MenuIcon
                className={'menu-icon'}/></IconButton>
            {renderDrawer()}
        </Grid>


    </Grid>

    return <Box>
        {mobileView ? renderMobileView() : renderDesktopView()}
        <Box className={'content'} role={'main'}>{children}</Box>
        <Footer alwaysShowFooter={alwaysShowFooter}/>
    </Box>
}

export default ContainerWithSearch