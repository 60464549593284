import React, {Suspense, useContext, lazy} from "react";
import {Box, LinearProgress, Paper, Typography, useMediaQuery, useTheme} from "@mui/material";
import {translateKey} from "utils/fieldsFormatters";
import {LanguageContext} from "context/LanguageContext";

const AutocompleteSearch = lazy(() => import("../common/AutocompleteSearch"));

const HomePage = () => {

    const {language} = useContext(LanguageContext);
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    return <Box className={'homepage-container'}>
        <Paper className={'paper-container homepage-paper'}>
            <Typography className={'big-text homepage-header'} variant={'h1'}>
                {translateKey('appTitle', language)}

            </Typography>

            <Suspense fallback={<Box sx={{width: '100%'}}>
                <LinearProgress/>
            </Box>}><AutocompleteSearch size={'large'} showCTAButtons
                                        showInListCTAButtons showPlaceholder={mobileView ? false : true}
                                        viewMode={mobileView ? 'mobile_homepage' : 'desktop_homepage'}/></Suspense>

        </Paper>
    </Box>
}

export default HomePage