import {Box, Card, CardHeader, Chip, List, ListItem, Typography} from "@mui/material";
import {translateKey} from "utils/fieldsFormatters";
import React, {useContext, useEffect, useState} from "react";
import {SimilarObjectsDict} from "consts/khw-consts";
import {LanguageContext} from "context/LanguageContext";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";

interface DescriptorSimilarObjectsProps {
    records: SimilarObjectsDict
}

const DescriptorSimilarObjects = ({records}: DescriptorSimilarObjectsProps) => {
    const {language} = useContext(LanguageContext);
    const navigate = useNavigate()
    const [cookies] = useCookies(['source']);
    const [sources, setSources] = useState(['DBN', 'MESH'])

    useEffect(() => {
        if (cookies.source && cookies.source !== '-1') {
            setSources([cookies.source])
        } else if (!cookies.source) {
            setSources(['DBN'])
        } else {
            setSources(['DBN', 'MESH'])

        }
    }, [cookies])

    const navigateToDescriptorDetails = (nlpId: string) => {
        navigate(`/descriptor-details/${nlpId}`)
    }
    const onKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>, nlpId: string) => {
        if (event.key === 'Enter' || event.key === 'Space') {
            event.preventDefault()
            navigateToDescriptorDetails(nlpId)
        }
    }

    return <Box className={'similar-objects-box'}>
        <Typography fontWeight={"bold"}>{translateKey('didYouMean', language)}?</Typography>
        {Object.entries(records).filter(([group, _]) => sources.includes(group)).map(([group, items]) => <Card
            key={group}
            className={'similar-objects-card'}><CardHeader
            className={'similar-card-header'}
            title={translateKey(group, language)}/>
            <List>
                {items.map(item => <ListItem key={item.id} className={'similar-object-item'}
                                             onKeyDown={(event) => onKeyDown(event, item.id)}>
                    <Typography className={'similar-name'} onClick={() => navigateToDescriptorDetails(item.id)}
                                tabIndex={0}>{item.preferredName}</Typography>
                    <Chip className={'type-chip'} color={'secondary'} label={translateKey(item.type, language)}/>
                </ListItem>)}
            </List></Card>)}
    </Box>
}
export default DescriptorSimilarObjects