import {Box} from "@mui/material";
import React, {useContext} from "react";
import {SectionHeaderTypography} from "./common/SectionHeaderTypography";
import {SectionProps} from "./Section1";
import {SectionTypography} from "./common/SectionTypography";
import {LanguageContext} from "context/LanguageContext";
import {translateKey} from "utils/fieldsFormatters";

export const Section5 = ({title}: SectionProps) => {
    const {language} = useContext(LanguageContext);

    const sectionParagraphs = ['documentationSection5_1', 'documentationSection5_2', 'documentationSection5_3', 'documentationSection5_4', 'documentationSection5_5', 'documentationSection5_6', 'documentationSection5_7', 'documentationSection5_8', 'documentationSection5_9', 'documentationSection5_10', 'documentationSection5_11', 'documentationSection5_12','documentationSection5_13']

    return <Box className={'docs-section'}>
        <SectionHeaderTypography>{title}</SectionHeaderTypography>
        {sectionParagraphs.map((paragraph, idx) => <SectionTypography key={`section-5-${idx}`}>{translateKey(paragraph, language)}</SectionTypography>)}


    </Box>
}