import {BasicDescriptorInfo} from "consts/khw-consts";
import BoxAccordion from "../common/BoxAccordion";
import React, {useState} from "react";
import {
    List,
    ListItem,
    ListItemButton,
    Pagination,
    Typography
} from "@mui/material";

interface AssociatedTitlesCardProps {
    data: BasicDescriptorInfo[]
}

const AssociatedTitlesCard = ({data}: AssociatedTitlesCardProps) => {
    const [page, setPage] = useState(1)
    const pageSize = 10

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    return <BoxAccordion header={'associatedTitles'} showBorderedCard
                         closedOnInit>
        <List>{data.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize ).map(item =>
            <ListItem key={item.nlpId}>
                <ListItemButton
                    href={`${process.env.REACT_APP_KHW_DESCRIPTOR_DETAILS_URL}/${item.nlpId}`}><Typography>&#8226; </Typography><Typography
                    className={'associated-title-link'}
                >{item.preferredName}</Typography></ListItemButton></ListItem>)}</List>
        <Pagination count={Math.ceil(data.length / pageSize)} page={page} onChange={handleChange} color={"secondary"}
                    className={'titles-pagination'}/>
    </BoxAccordion>
}
export default AssociatedTitlesCard