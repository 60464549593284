import React from 'react';
import { Helmet } from 'react-helmet-async';
import {DBN_URL} from "../../consts/khw-consts";
import logo from "../../assets/logo.jpg";

interface SEOProps {
    description: string;
    url?: string;
}
const SEO = ({description, url}: SEOProps) => {
    const title = 'Deskryptory Biblioteki Narodowej (DBN)'
    return (
        <Helmet>
            { /* Standard metadata tags */}
            <title>{title}</title>
            <link rel="canonical" href={url ? url : DBN_URL}/>
            <meta name='description' content={description}/>
            { /* End standard metadata tags */}
            { /* Facebook tags */}
            <meta property="og:type" content='website'/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:image" content={logo}/>
            { /* End Facebook tags */}
        </Helmet>
    )
}

export default SEO