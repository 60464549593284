import {Box} from "@mui/material";
import React, {useContext} from "react";
import {SectionHeaderTypography} from "./common/SectionHeaderTypography";
import {SectionTypography} from "./common/SectionTypography";
import {translateKey} from "utils/fieldsFormatters";
import {LanguageContext} from "context/LanguageContext";

export interface SectionProps {
    title: string
}

export const Section7 = ({title}: SectionProps) => {
    const {language} = useContext(LanguageContext);


    return <Box className={'docs-section'}>
        <SectionHeaderTypography>{title}</SectionHeaderTypography>
        <SectionTypography>{translateKey('documentationSection7_1', language)}</SectionTypography>
    </Box>

}
