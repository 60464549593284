import axios from "axios";
import {RECORDS_NUMBER, SearchEngineContext} from "consts/search-engine-consts";
import {FileType} from "consts/khw-consts";

export const fetchFields = (language: string, context: SearchEngineContext) => {
    const url = `${process.env.REACT_APP_SEARCH_ENGINE_API_URL}/fetch-fields`
    return axios.get(url, {
        params: {
            lang: language,
            context
        }
    })
}

export const fetchFieldsForExport = (language: string, context: SearchEngineContext) => {
    const url = `${process.env.REACT_APP_SEARCH_ENGINE_API_URL}/fetch-fields-for-export`
    return axios.get(url, {
        params: {
            lang: language,
            context

        }
    })
}

export const searchRecords = (query: string, page: number, context: SearchEngineContext, uuid: string) => {
    const url = `${process.env.REACT_APP_SEARCH_ENGINE_API_URL}/search-records?page=${page}`
    return axios.post(url, {query}, {params: {context, uuid}})
}

export const exportRecordsToCsv = (
    query: string, records_number: RECORDS_NUMBER, fields: string[], filename: string, context: SearchEngineContext,
    uuid: string
) => {
    const url = `${process.env.REACT_APP_SEARCH_ENGINE_API_URL}/export-records`
    if (fields.length === 0) {
        return axios.post(url, {query, records_number, filename}, {params: {context, uuid}})

    }
    return axios.post(url, {query, records_number, fields, filename}, {params: {context, uuid}})
}

export const fetchHistory = (uuid: string) => {
    const url = `${process.env.REACT_APP_SEARCH_ENGINE_API_URL}/history`
    return axios.get(url, {params: {uuid}})
}

export const saveDataset = (name: string, fields: string[], uuid: string) => {
    const url = `${process.env.REACT_APP_SEARCH_ENGINE_API_URL}/save-dataset`
    return axios.post(url, {name, fields}, {params: {uuid}})
}
export const getJobStatus = (job_id: string) => {
    const url = `${process.env.REACT_APP_SEARCH_ENGINE_API_URL}/job-status`
    return axios.get(url, {
        params: {job_id}
    })
}

export const downloadSearchEngineRecord = (recordId: string, fileType: FileType, searchContextCookie: SearchEngineContext) => {
    let url = ''
    if (searchContextCookie === SearchEngineContext.DBN) {
        url = `${process.env.REACT_APP_API_URL}/data-bn/bibs/${recordId}`
    } else if (searchContextCookie === SearchEngineContext.INTEGRATED_CATALOGUE) {
        url = `${process.env.REACT_APP_API_URL}/data-bn/networks/${recordId}`
    } else {
        url = `${process.env.REACT_APP_API_URL}/data-bn/authorities/${recordId}`
    }
    return axios.get(url, {
        params: {
            fileType: 'MARC_PRETTY_PRINT'
        }
    })

}

export const downloadWorkDescription = (isbn: string[]) => {
    const url = `${process.env.REACT_APP_API_URL}/cover-importer/description`
    return axios.post(url, {
            recordsToMatchDescriptions: [{
                id: 'string',
                isbnList: isbn
            }]
        }
    )

}
export const checkIfCoverExists = (isbn: string) => {
    const url = `${process.env.REACT_APP_API_URL}/cover-importer/cover`
    return axios.get(url, {
            params:
                {isbn: isbn},
            responseType: "arraybuffer"
        },
    )

}