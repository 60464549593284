import {Box, LinearProgress} from "@mui/material";
import React, {Suspense, useCallback, useContext, useEffect, useState} from "react";
import {
    fetchDescriptorData,
    fetchDescriptorsList,
    fetchWikidataData,
    getDescriptorDetailsByName
} from "services/khwApi";
import {
    BasicDescriptorInfo,
    DataAssociatedObject,
    DESCRIPTOR_RELATED_DOWN_FIELDS,
    DESCRIPTOR_RELATED_FIELDS,
    DESCRIPTOR_RELATED_UP_FIELDS,
    DescriptorInfo,
    DescriptorLinkObject,
    DescriptorType,
    DescriptorWikidataInfo,
    RelationsData,
    WikidataImage
} from "consts/khw-consts";

import {useParams} from "react-router-dom";
import {LanguageContext} from "context/LanguageContext";
import {translateKey} from "utils/fieldsFormatters";
import {Event, Organization, Person, Place, Thing} from "schema-dts";
import {JsonLd} from "react-schemaorg";
import {renderViafUrl, renderWikidataUrl} from "utils/renderUrls";
import EmptyDivider from "components/common/EmptyDivider";
import DescriptorBasicInfoCard from "./DescriptorBasicInfoCard";
import DescriptorDetailInfoCard from "./DescriptorDetailInfoCard";
import DescriptorStatisticsCard from "./DescriptorStatisticsCard";
import DescriptorWikidataInfoCard from "./DescriptorWikidataInfoCard";
import BreadcrumbsRow from "../common/BreadcrumbsRow";
import AssociatedTitlesCard from "./AssociatedTitlesCard";
import {renderCanonicalUrl, renderSEODescriptionForDescriptorDetailView} from "../../utils/renderSEOTags";
import SEO from "../common/SEO";

const DescriptorDetailView = () => {
    let {recordId} = useParams(); // selected descriptor nlpId or MMS ID
    const [recordData, setRecordData] = useState<DescriptorInfo | null>(null)
    const [wikidataData, setWikidataData] = useState<DescriptorWikidataInfo | null>(null)
    const [wikidataIdFromLodService, setWikidataIdFromLodService] = useState<string>('')
    const {language} = useContext(LanguageContext);
    const [associations, setAssociations] = useState<RelationsData | null>(null)
    const [image, setImage] = useState<WikidataImage | null>(null)
    const [loading, setLoading] = useState(false)
    const [authorData, setAuthorData] = useState<any | { nlpId: string, href: string, type: DescriptorType }>(null)
    const [associatedTitles, setAssociatedTitles] = useState<BasicDescriptorInfo[]>([])

    const showDetails = process.env.REACT_APP_SHOW_DESCRIPTOR_DETAILS === 'true'
    const showWikidata = process.env.REACT_APP_SHOW_DESCRIPTOR_WIKIDATA === 'true'
    const showStatisticsManifestations = process.env.REACT_APP_SHOW_DESCRIPTOR_STATISTICS_MANIFESTATIONS === 'true'
    const showStatisticsSubject = process.env.REACT_APP_SHOW_DESCRIPTOR_STATISTICS_SUBJECT_OF_PUBLICATION === 'true'
    const showStatisticsRecent = process.env.REACT_APP_SHOW_DESCRIPTOR_STATISTICS_RECENT_PUBLICATIONS_ON === 'true'
    const showStatisticsAssociations = process.env.REACT_APP_SHOW_DESCRIPTOR_STATISTICS_ASSOCIATIONS === 'true'
    const showAssociatedTitles = process.env.REACT_APP_SHOW_DESCRIPTOR_ASSOCIATED_TITLES === 'true'
    const showStatistics = showStatisticsManifestations || showStatisticsSubject || showStatisticsRecent || showStatisticsAssociations

    const fetchViafId = (externalIds: DataAssociatedObject[]) => {

        if (externalIds) {
            return externalIds.find(item => item.dataAssociated === 'viaf')?.stringValue.split('/').at(-1) ?? ''
        }
        return ''
    }

    const fetchWikidataId = (externalIds: DataAssociatedObject[]) => {
        if (externalIds) {

            return externalIds?.find((item: {
                dataAssociated: string;
            }) => item.dataAssociated === 'wikidata')?.stringValue ?? ''
        }
        return ''
    }

    const getCurrentPathWithoutLastPart = () => {
        const fullUrlPath = window.location.href
        return fullUrlPath.slice(0, fullUrlPath.lastIndexOf('/'))
    }


    useEffect(() => {

        if (!recordId) {
            return
        }

        setLoading(true)
        let isApiSubscribed = true
        fetchDescriptorData(recordId).then(response => {
            if (isApiSubscribed) {
                setRecordData(response.data)
                const viafId = fetchViafId(response.data.externalIds)
                const wikidataId = fetchWikidataId(response.data.externalIds)

                if (recordId) {
                    setLoading(false)
                    const {nlpId, type} = response.data
                    fetchWikidataData(nlpId, type, language, viafId, wikidataId).then(response => {
                            setWikidataData(response.data.bindings)
                            setWikidataIdFromLodService(response.data.id)
                            setImage(response.data.image as WikidataImage)
                            setLoading(false)

                        }
                    ).catch(error => {
                            console.log(error)
                            setLoading(false)
                        }
                    )

                } else {
                    setWikidataData(null)
                    setImage(null)
                    setLoading(false)

                }
            }
        }).catch(error => {
            console.log(error)
            setLoading(false)
        })
        return () => {
            isApiSubscribed = false
        }
    }, [recordId, language])

    const fetchAssociatedTitles = useCallback((searchedText: string) => {
        // searching for another works of the author
        fetchDescriptorsList({
            descriptorType: DescriptorType.AUTHOR_TITLE,
            sourceName: 'DBN',
            searchedText
        }).then(response => {
            if (response.data.result.length > 0) {
                setAssociatedTitles(response.data.result
                    .filter((item: BasicDescriptorInfo) => item.preferredName !== recordData?.preferredName && item.preferredName.includes(searchedText)))
            }
        })
    }, [recordData?.preferredName])

    useEffect(() => {
            if (!recordData) return
            const {preferredName, type} = recordData
            if (type === DescriptorType.AUTHOR_TITLE) {
                let splittedName;
                if (preferredName.includes('.')) splittedName = preferredName.split('.')
                else splittedName = preferredName.split(')')

                if (splittedName.length > 1) {
                    const linkContent = splittedName[0]
                    getDescriptorDetailsByName(linkContent).then(response => {
                        if (!!response.data.detailsByName.length) {
                            const {nlpId, type} = response.data.detailsByName[0]
                            const href = `${process.env.REACT_APP_KHW_DESCRIPTOR_DETAILS_URL}/${nlpId}`
                            setAuthorData({nlpId, href, type})
                            fetchAssociatedTitles(linkContent)

                        }
                    })
                }
            } else if ([DescriptorType.PERSONAL, DescriptorType.EVENT, DescriptorType.CORPORATE].includes(type)) {
                fetchAssociatedTitles(preferredName)
            }
        },
        [recordData, fetchAssociatedTitles]
    )

    const canDisplayStatistics = () => {
        return (recordData && recordData.type === DescriptorType.PERSONAL && showStatistics) || (associations && showStatisticsAssociations) || (recordData && recordData.type === DescriptorType.GEOGRAPHIC && recordData.jsonFieldValues.location)
    }

    useEffect(() => {
        const fetchAssociationsByType = (type: string[]) => {
            if (recordData) {
                let tmpList: DescriptorLinkObject[] = []
                Object.keys(recordData?.jsonFieldValues).filter((key) => type.includes(key)).forEach(key => tmpList.push(recordData.jsonFieldValues[key] as unknown as DescriptorLinkObject))
                return tmpList
            }
        }


        if (!recordData) {
            return
        }
        const {nlpId, type, preferredName} = recordData
        const associations: RelationsData = {
            mainDescriptor: {nlpId, type, preferredName, dataAssociated: ''},
            relatedHorizontally: fetchAssociationsByType(DESCRIPTOR_RELATED_FIELDS)?.flat() ?? null,
            relatedUp: fetchAssociationsByType(DESCRIPTOR_RELATED_UP_FIELDS)?.flat() ?? null,
            relatedDown: fetchAssociationsByType(DESCRIPTOR_RELATED_DOWN_FIELDS)?.flat() ?? null,
        }
        if (!associations.relatedDown?.length && !associations.relatedUp?.length && !associations.relatedHorizontally?.length) {
            setAssociations(null)
        } else {
            setAssociations(associations)
        }
    }, [recordData])


    const getDescriptorLinkObjectNames = (data: DescriptorLinkObject[]) => {
        return data.map((item) => item.preferredName)
    }

    const getDataAssociatedObjectNames = (data: DataAssociatedObject[]) => {
        return data.map((item) => item.stringValue)
    }

    const renderSchemaOrgContainer = () => {
        if (!recordData || !wikidataData) return

        const {type, nlpId, preferredName, alternateNames, jsonFieldValues, externalIds} = recordData
        const viafObject = externalIds?.find(item => item.dataAssociated === 'viaf') ?? null
        const viafUrl = viafObject ? renderViafUrl(viafObject.stringValue) : ''
        const wikidataUrl = renderWikidataUrl(wikidataIdFromLodService)

        if (type === DescriptorType.PERSONAL) {

            return <JsonLd<Person> item={{
                "@context": "https://schema.org",
                "@type": "Person",
                identifier: nlpId,
                name: preferredName,
                alternateName: alternateNames,
                sameAs: [viafUrl, wikidataUrl],
                birthDate: jsonFieldValues.dateOfBirth as string,
                birthPlace: jsonFieldValues.placeOfBirth as string,
                deathDate: jsonFieldValues.dateOfDeath as string,
                deathPlace: jsonFieldValues.placeOfDeath as string,
                gender: !!jsonFieldValues.gender ? (jsonFieldValues.gender.at(-1) as DataAssociatedObject).stringValue as string : '',
                jobTitle: !!jsonFieldValues.occupation ? getDescriptorLinkObjectNames(jsonFieldValues.occupation as DescriptorLinkObject[]) : '',
                affiliation: !!jsonFieldValues.affiliation ? (jsonFieldValues.affiliation as DescriptorLinkObject[]).map(item => `${item.preferredName} [${item.dataAssociated}]`) : ''
            }}/>
        } else if (type === DescriptorType.CORPORATE) {
            const locationCountry = !!jsonFieldValues.associatedPlaceCountry ? getDescriptorLinkObjectNames(jsonFieldValues.associatedPlaceCountry as DescriptorLinkObject[]).join(' | ') : ''
            const locationPlace = !!jsonFieldValues.associatedPlaceResidenceOrHeadquarters ? getDescriptorLinkObjectNames(jsonFieldValues.associatedPlaceResidenceOrHeadquarters as DescriptorLinkObject[]).join(' | ') : ''
            return <JsonLd<Organization> item={{
                "@context": "https://schema.org",
                "@type": "Organization",
                identifier: nlpId,
                name: preferredName,
                alternateName: alternateNames,
                sameAs: [viafUrl, wikidataUrl],
                location: [locationCountry, locationPlace],
                address: !!jsonFieldValues.associatedPlaceAddress ? getDataAssociatedObjectNames(jsonFieldValues.associatedPlaceAddress as DataAssociatedObject[]) : '',
                parentOrganization: !!jsonFieldValues.corporateBroader ? getDescriptorLinkObjectNames(jsonFieldValues.corporateBroader as DescriptorLinkObject[]) : '',
                subOrganization: !!jsonFieldValues.corporateNarrower ? getDescriptorLinkObjectNames(jsonFieldValues.corporateNarrower as DescriptorLinkObject[]) : '',
                foundingDate: jsonFieldValues.startOfActivity as string
            }}/>
        } else if (type === DescriptorType.EVENT) {
            const locationCountry = !!jsonFieldValues.associatedPlaceCountry ? getDescriptorLinkObjectNames(jsonFieldValues.associatedPlaceCountry as DescriptorLinkObject[]).join(' | ') : ''
            const locationPlace = !!jsonFieldValues.associatedPlaceResidenceOrHeadquarters ? getDescriptorLinkObjectNames(jsonFieldValues.associatedPlaceResidenceOrHeadquarters as DescriptorLinkObject[]).join(' | ') : ''
            return <JsonLd<Event> item={{
                "@context": "https://schema.org",
                "@type": "Event",
                identifier: nlpId,
                name: preferredName,
                alternateName: alternateNames,
                sameAs: [viafUrl, wikidataUrl],
                location: [locationCountry, locationPlace],
                startDate: jsonFieldValues.startOfActivity as string,
                endDate: jsonFieldValues.endOfActivity as string,
            }}/>
        } else if (type === DescriptorType.GEOGRAPHIC) {
            return <JsonLd<Place> item={{
                "@context": "https://schema.org",
                "@type": "Place",
                identifier: nlpId,
                name: preferredName,
                alternateName: alternateNames,
                sameAs: [viafUrl, wikidataUrl],
            }}/>
        } else if (type === DescriptorType.SUBJECT) {
            return <JsonLd<Thing> item={{
                "@context": "https://schema.org",
                "@type": "Thing",
                identifier: nlpId,
                name: preferredName,
                alternateName: alternateNames,
                sameAs: [viafUrl, wikidataUrl],
            }}/>
        }
    }


    return loading ? <Box sx={{width: '100%'}}>
            <LinearProgress/>
        </Box> :
        <Box className={'detail-view'}>
            <SEO
                description={'Przeglądaj bazę danych Deskryptorów Biblioteki Narodowej i MeSH-POL (Medical Subject Headings-POL).'}/>
            {recordData && recordId && <SEO
                description={renderSEODescriptionForDescriptorDetailView(recordData.sourceName, recordData?.type, recordData.preferredName, recordData.alternateNames)}
                url={renderCanonicalUrl(recordId)}/>
            }

            <h1 className="screen-reader-text">{translateKey('descriptorViewScreenReaderHeader', language)}</h1>
            {recordData && renderSchemaOrgContainer()}

            <EmptyDivider size={"small"}/>
            {recordData && <BreadcrumbsRow content={recordData.preferredName}
                                           descriptorType={translateKey(recordData.type, language)}/>}
            <EmptyDivider size={"small"}/>

            {recordData && <><DescriptorBasicInfoCard data={recordData} image={image} wikidata={wikidataData}
                                                      authorData={authorData}/>

                {showDetails && <><EmptyDivider/><Suspense fallback={<Box sx={{width: '100%'}}>
                    <LinearProgress/>
                </Box>}><DescriptorDetailInfoCard data={recordData}/></Suspense> </>}

                {showAssociatedTitles && associatedTitles.length > 0 && <><EmptyDivider/><Suspense
                    fallback={<Box sx={{width: '100%'}}>
                        <LinearProgress/>
                    </Box>}><AssociatedTitlesCard data={associatedTitles}/></Suspense> </>}

                {canDisplayStatistics() &&
                    <><EmptyDivider/><DescriptorStatisticsCard descriptorName={recordData.preferredName}
                                                               descriptorType={recordData.type}
                                                               coords={recordData.jsonFieldValues.location_points as string[]}
                                                               associations={associations}/></>}

                {showWikidata && wikidataIdFromLodService && wikidataData && <>
                    <EmptyDivider/><DescriptorWikidataInfoCard wikidataId={wikidataIdFromLodService}
                                                               data={wikidataData}/><EmptyDivider/></>}

            </>
            }
        </Box>
}

export default DescriptorDetailView