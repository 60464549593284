import {Box} from "@mui/material";
import React, {useContext} from "react";
import {SectionTypography} from "./common/SectionTypography";
import {SectionHeaderTypography} from "./common/SectionHeaderTypography";
import {SectionProps} from "./Section1";
import {translateKey} from "utils/fieldsFormatters";
import {LanguageContext} from "context/LanguageContext";

export const Section3 = ({title}: SectionProps) => {
    const sectionParagraphs = ['documentationSection3_1', 'documentationSection3_2', 'documentationSection3_3']
    const {language} = useContext(LanguageContext);


    return <Box className={'docs-section'}>
        <SectionHeaderTypography>{title}</SectionHeaderTypography>
        {sectionParagraphs.map((paragraph, idx) =>
            <SectionTypography key={`section-3-${idx}`}>{translateKey(paragraph, language)}</SectionTypography>)}

    </Box>
}