import {Box} from "@mui/material";
import React, {useContext} from "react";
import {SectionHeaderTypography} from "./common/SectionHeaderTypography";
import {SectionProps} from "./Section1";
import {SectionTypography} from "./common/SectionTypography";
import {LanguageContext} from "context/LanguageContext";
import {translateKey} from "utils/fieldsFormatters";
import image from "assets/documentation-graph.png"
export const Section4 = ({title}: SectionProps) => {
    const {language} = useContext(LanguageContext);

    const sectionParagraphsBeforeImage = ['documentationSection4_1', 'documentationSection4_2', 'documentationSection4_3', 'documentationSection4_4', 'documentationSection4_5', 'documentationSection4_6', 'documentationSection4_7', 'documentationSection4_8']
    const sectionParagraphsAfterImage = ['documentationSection4_9', 'documentationSection4_10', 'documentationSection4_11', 'documentationSection4_12', 'documentationSection4_13', 'documentationSection4_14', 'documentationSection4_15']

    return <Box className={'docs-section'}>
        <SectionHeaderTypography>{title}</SectionHeaderTypography>
        {sectionParagraphsBeforeImage.map((paragraph, idx) => <SectionTypography
            key={`section-4-1-${idx}`}>{translateKey(paragraph, language)}</SectionTypography>)}
        <img src={image} width={'45%'} alt={translateKey('FRBRGraphAlt', language)}/>
        {sectionParagraphsAfterImage.map((paragraph, idx) => <SectionTypography
            key={`section-4-2-${idx}`}>{translateKey(paragraph, language)}</SectionTypography>)}


    </Box>
}