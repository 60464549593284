import {Typography, TypographyProps} from "@mui/material";
import {PropsWithChildren} from "react";

export const SectionTypography = (props: PropsWithChildren<TypographyProps>) => {
    const {children} = props

    return <Typography variant={'body2'} className={'section-typography'} dangerouslySetInnerHTML={{__html:children as string}}/>

}

