import {GridToolbarContainer} from "@mui/x-data-grid";
import {Alert, Button, Snackbar} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {translateKey} from "utils/fieldsFormatters";
import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "context/LanguageContext";
import ExportDialog from "./ExportDialog";
import {exportRecordsToCsv, getJobStatus} from "services/searchEngineApi";
import {FileToDownloadType, RECORDS_NUMBER, SearchEngineContext} from "consts/search-engine-consts";
import {useCookies} from "react-cookie";

const ExportGridToolbar = () => {
    const {language} = useContext(LanguageContext);
    const [openDialog, setOpenDialog] = useState(false)
    const [exportInProgress, setExportInProgress] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [intervalId, setIntervalId] = useState<any | null>(null)
    const [fileToDownloadData, setFileToDownloadData] = useState<FileToDownloadType | null>(null)
    const [cookies] = useCookies(['searchContext', 'uuid']);

    const onExport = async (recordsNumber: RECORDS_NUMBER, selectedFields: string[], filename: string) => {
        const query = await localStorage.getItem('query')

        if (query) {
            setExportInProgress(true)
            exportRecordsToCsv(JSON.parse(query), recordsNumber, selectedFields, filename, cookies.searchContext ?? SearchEngineContext.DBN, cookies.uuid).then(response => {
                    setExportInProgress(true)
                    setOpenSnackbar(true)
                    setIntervalId(setInterval(function () {
                        checkJobStatus(response.data.job_id)
                    }, process.env.REACT_APP_CHECK_JOB_STATUS_INTERVAL_IN_MS))
                }
            )
        }
    }
    const checkJobStatus = (jobId: string) => {
        getJobStatus(jobId).then(response => {
                setFileToDownloadData({
                    status: response.data.status,
                    link: response.data.result ?? ''
                })
                if (response.data.status === 'SUCCESS') {
                    setExportInProgress(false)
                }
            }
        ).catch(error => {
        })
    }
    useEffect(() => {
        if (!exportInProgress) {
            clearInterval(intervalId)
        }
    }, [exportInProgress, intervalId])

    const onExportBtnClick = () => {
        setOpenDialog(true)
    }

    const onCloseExportDialog = () => {
        setOpenDialog(false)
        setFileToDownloadData(null)
    }

    return (
        <GridToolbarContainer>
            <Button startIcon={<FileDownloadIcon/>}
                    onClick={onExportBtnClick}>{translateKey('exportAsCsv', language)}</Button>
            <ExportDialog open={openDialog} onClose={onCloseExportDialog} onExport={onExport}
                          exportInProgress={exportInProgress} fileToDownloadData={fileToDownloadData}/>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: 'right'}}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
            ><Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{width: '100%'}} variant={'filled'}>
                {translateKey('fileGeneratingInProgress', language)}
            </Alert></Snackbar>
        </GridToolbarContainer>

    );
}
export default ExportGridToolbar