import {Page, Text, View, Document, StyleSheet, Font, Image, Link} from '@react-pdf/renderer';
import {
    ATTRIBUTES_ORDER,
    DescriptorInfo,
    DescriptorWikidataInfo, TimelineOutputData,
    WorksAboutData
} from "consts/khw-consts";
import _ from 'lodash';
import React, {useEffect, useState} from "react";
import {formatDate, formatValue, translateKey} from "utils/fieldsFormatters";
import {COLORS} from "styles/colors";
import {fetchChartData, fetchWorksAboutData} from "services/khwApi";
import {groupWorksByYear} from "utils/groupWorksByYear";
import {renderViafUrl} from "utils/renderUrls";
import Code2000 from "assets/fonts/Code2000.ttf"

Font.register({
    family: "Roboto",
    fonts: [
        {
            src:
                "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
            fontWeight: 'normal'

        },
        {
            src:
                "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
            fontWeight: 'medium'

        },
        {
            src:
                "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
            fontWeight: 'bold'

        },
        {
            src:
                "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
            fontWeight: 'light'
        },
        {
            src:
                "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-italic-webfont.ttf",
            // fontWeight: 'italic',
            fontStyle: 'italic'
        }
    ]
});

Font.register({
    family: 'Code2000',
    src: Code2000
})
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Roboto',
        fontWeight: 'light',
        fontSize: 12,
        padding: 10
    },
    paperSection: {
        padding: 10,
        marginTop: 5,
        marginBottom: 5,
        borderColor: COLORS.light,
        borderWidth: 3,
        borderRadius: 5,
    },

    sectionHeader: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 14,
        textTransform: "uppercase",

    },
    sectionSubheader: {
        fontSize: 10,
        textAlign: 'center',
        fontStyle: 'italic',
        marginBottom: 15

    },
    section1: {
        flexDirection: "row",
        justifyContent: 'space-between'
    },
    basicInfoBox: {
        marginTop: 15,
        display: "flex",
        flexDirection: "row",

    },
    basicInfoSection: {
        marginLeft: 10
    },
    descriptorName: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    row: {
        flexDirection: "row"
    },
    label: {
        fontWeight: 'normal',
        flexWrap: 'nowrap',
        width: 100
    },
    labelWider: {
        fontWeight: 'normal',
        flexWrap: 'nowrap',
        width: 200
    },
    wrapText: {
        flexWrap: "wrap",
        width: 350
    },

    dataInColumn: {
        flexDirection: "column"
    },
    chartsSection: {
        flexGrow: 0.5,
        justifyContent: 'space-between',
        textAlign: 'center',
    },
    chartsSectionCenter: {
        flexGrow: 1,
        justifyContent: 'space-between',
        textAlign: 'center',
        paddingBottom: 20
    },
    chart: {
        width: '70%',
        alignSelf: 'center',
    },
    chartTitle: {
        flexWrap: 'wrap',
        fontWeight: 'medium'
    },
    flexGrowSection: {
        flexGrow: 1
    },
    workAboutCard: {
        padding: 5,
        marginTop: 5,
        marginBottom: 5,
        borderColor: COLORS.gray,
        borderWidth: 2,
        borderRadius: 5,
    },
    labelNarrow: {
        fontWeight: 'normal',
        flexWrap: 'nowrap',
        width: 75
    },
    mainCreator: {
        fontSize: 11
    },
    workTitle: {
        fontWeight: 'normal',
        fontSize: 13,
        // marginTop: 5,
        marginBottom: 5
    },
    workHost: {
        fontStyle: 'italic',
        color: COLORS.lightDarker,
        fontSize: 11,
        marginBottom: 5,
    },
    workAboutText: {
        flexWrap: "wrap",
        width: 450
    },
    floatRightYear: {
        width: '100%',
        justifyContent: "flex-end",
        marginTop: 5
    },
    bold: {
        fontWeight: 'normal'
    },
    timelineGroup: {
        flexDirection: 'row',
        padding: 5,
        paddingBottom: 10,
        marginBottom: 5,
        borderBottom: 1,
        borderBottomColor: 'grey',
        borderBottomStyle: 'solid'
    },
    year: {
        width: 50,
        fontWeight: "bold",
        color: COLORS.lightDarker
    },
    titlesList: {
        flexWrap: "wrap",
        width: 475
    }
});

interface DescriptorPDFProps {
    data: DescriptorInfo
    image: string
    wikidata: DescriptorWikidataInfo | null
    language: 'pl' | 'en'
    chartsImages: { [index: string]: string } | null
}

const DescriptorPDF = ({data, image, wikidata, language, chartsImages}: DescriptorPDFProps) => {

    const {
        nlpId,
        preferredName,
        type,
        createdTime,
        updatedTime,
        alternateNames,
        externalIds,
        mmsId,
        controlNumber,
        jsonFieldValues
    } = data

    const [worksAbout, setWorksAbout] = useState<WorksAboutData[]>([])
    const [works, setWorks] = useState<TimelineOutputData>({})

    useEffect(() => {
        const offset = 10
        const page = 0
        fetchWorksAboutData(preferredName, page, offset).then(response => {
            setWorksAbout(response.data.works_about)

        }).catch(err => console.log(err))

        fetchChartData(preferredName, 'works_for_timeline').then(response => {
            if (!response.length) {
                return
            }
            setWorks(groupWorksByYear(response))

        }).catch(err => console.log(err))
    }, [preferredName])

    const renderRows = () => ATTRIBUTES_ORDER.map(attribute => renderRow(attribute))
    const renderMissingRows = () => Object.keys(jsonFieldValues).filter(jsonField => !ATTRIBUTES_ORDER.includes(jsonField)).map(renderRow)

    const renderRow = (attribute: string) => {
        const jsonField = jsonFieldValues[attribute]
        if (!jsonField) return
        const label = translateKey(attribute, language)
        const {simpleValue, complexValues, arrayValue} = formatValue(attribute, jsonField)
        if (complexValues) {
            const data = complexValues.map(value => <Link key={value.nlpId}
                                                          src={`${process.env.REACT_APP_KHW_DESCRIPTOR_DETAILS_URL}/${value.nlpId}`}>{value.preferredName} {value.dataAssociated}</Link>)
            return <View key={attribute} style={styles.row}><Text
                style={styles.labelWider}>{label}</Text><View style={styles.dataInColumn}>{data}</View></View>
        } else if (arrayValue) {
            const data = arrayValue.map(value => <Text key={value} style={styles.wrapText}>{value}</Text>)
            return <View key={attribute} style={styles.row}><Text
                style={styles.labelWider}>{label}</Text><View style={styles.dataInColumn}>{data}</View></View>
        }
        return <View key={attribute} style={styles.row}><Text
            style={styles.labelWider}>{label}</Text><Text>{simpleValue}</Text></View>

    }

    const renderWikidataRows = () => {
        if (!wikidata) return
        return Object.entries(wikidata).map(([key, value]) => {
            const label = translateKey(key, language, 'wikidata')
            const uniqueKey = `wikidata-${key}`
            if (key.toLowerCase().includes('date')) {
                const formattedDate = formatDate(value)
                if (formattedDate){
                    return <View key={uniqueKey} style={styles.row}><Text
                        style={styles.labelWider}>{label}</Text><Text style={styles.wrapText}>{formattedDate}</Text></View>
                }
                return <></>
            }
            return <View key={uniqueKey} style={styles.row}><Text
                style={styles.labelWider}>{label}</Text><Text style={styles.wrapText}>{value}</Text></View>
        })
    }

    const renderPagesWithCharts = () => chartsImages && _.chunk(Object.entries(chartsImages), 2).map((pair, idx) => {
        let [chart1Title, chart1Data] = pair[0]
        let chart2Title, chart2Data = null
        if (pair[1]) {
            [chart2Title, chart2Data] = pair[1]
        }
        let headerDetails = idx <= 2 ? translateKey('manifestationData', language) : translateKey('worksData', language)
        return <Page key={idx}
                     size="A4" style={[styles.page]}>
            <View style={[styles.paperSection, chart2Title ? styles.chartsSectionCenter : styles.chartsSection]}
                  fixed={true}>
                <View key={idx}>
                    <Text
                        style={styles.sectionHeader}>{translateKey('statisticsData', language)} - {headerDetails}</Text>
                    <Text style={styles.sectionSubheader}>{translateKey('statisticsPdfTooltip', language)}</Text>
                </View>
                <View wrap={false} key={chart1Title}>
                    <Text
                        style={styles.chartTitle}>{translateKey(`charts.${_.camelCase(chart1Title)}ChartTitle`, language)}</Text>
                    <Image src={`data:image/png;base64,${chart1Data}`}
                           style={styles.chart}/>
                </View>
                {chart2Title && <View wrap={false} key={chart2Title}>
                    <Text
                        style={styles.chartTitle}>{translateKey(`charts.${_.camelCase(chart2Title)}ChartTitle`, language)}</Text>
                    <Image
                        src={`data:image/png;base64,${chart2Data}`}
                        style={styles.chart}/>
                </View>}
            </View></Page>
    })

    const renderWorksAboutPages = () => worksAbout.map(item => <View style={styles.workAboutCard} wrap={false}
                                                                     key={item.work_id}>
        <Text style={styles.mainCreator}>{item.work_main_creator.join(' | ')}</Text>
        <Text style={styles.workTitle}>{item.work_title}</Text>
        <Text style={styles.workHost}>{item.work_host_item_full}</Text>
        <View style={styles.row}><Text
            style={styles.labelNarrow}>{translateKey('expression_form', language)}:</Text><Text
            style={styles.workAboutText}>{item.expression_form.join(' | ')}</Text></View>
        <View style={styles.row}><Text
            style={styles.labelNarrow}>{translateKey('work_subject', language)}:</Text><Text
            style={styles.workAboutText}>{item.work_subject.join(' | ')}</Text></View>
        {item.work_other_creator.length > 0 && <View style={styles.row}><Text
            style={styles.labelNarrow}>{translateKey('cocreator', language)}:</Text><Text
            style={styles.workAboutText}>{item.work_other_creator.join(' | ')}</Text></View>}
        <View
            style={[styles.row, styles.floatRightYear]}><Text>{translateKey('lastPublicationYear', language)}: </Text><Text
            style={styles.bold}>{item.manifestation_last_publication_year}</Text></View>
    </View>)

    const renderTimelineInfo = () => Object.entries(works).map(([year, items]) => <View style={styles.timelineGroup}

                                                                                        key={year}>
        <Text style={styles.year}>{year}</Text>
        <Text style={styles.titlesList}>{items.map(work => work.work_title_pref).join('\n')}</Text>

    </View>)

    return <Document>
        <Page size="A4" style={styles.page}>
            <View style={[styles.paperSection, styles.section1]}>
                <Text>{translateKey(type, language)}</Text>
                <Text>{translateKey('creationDate', language)}: {createdTime}{'\n'}{translateKey('modificationDate', language)}: {updatedTime}</Text>
            </View>
            <View style={[styles.paperSection]}>
                <Link style={styles.descriptorName}
                      src={`${process.env.REACT_APP_KHW_DESCRIPTOR_DETAILS_URL}/${nlpId}`}>{preferredName}</Link>
                <Text style={styles.bold}>{translateKey('preferredName', language)}</Text>
                <View style={styles.basicInfoBox}>
                    <Image
                        src={image}
                        style={{height: 150}}/>
                    <View style={styles.basicInfoSection}>
                        {alternateNames.length > 0 && <View style={styles.row}><Text style={styles.label}
                        >{translateKey('alternativeNames', language)}:</Text>
                            <View style={{...styles.row, width: 350, flexWrap: "wrap"}}>
                                {alternateNames.length === 1 &&
                                    <Text style={{fontFamily: 'Code2000'}}>{"\u00A0\u00A0"}{alternateNames[0]}</Text>}
                                {alternateNames.length > 1 && alternateNames.slice(0, -1).map(name => <Text key={name}
                                                                                                            style={{fontFamily: 'Code2000'}}>{name}{"\u00A0\u00A0|\u00A0\u00A0"}</Text>)}
                                {alternateNames.length > 1 &&
                                    <Text style={{fontFamily: 'Code2000'}}>{alternateNames?.at(-1)}</Text>}
                            </View>
                        </View>}

                        <View style={styles.row}><Text
                            style={styles.label}>{translateKey('nlpId', language)}:</Text><Text>(PL){nlpId}</Text></View>
                        <View
                            style={styles.row}><Text
                            style={styles.label}>{translateKey('mmsId', language)}:</Text><Text>{mmsId}</Text></View>
                        {controlNumber && <View style={styles.row}><Text style={styles.label}
                        >{translateKey('controlNumber', language)}:</Text><Text>{controlNumber}</Text></View>}
                        {externalIds && externalIds.map((exId, index) => {
                            return exId.dataAssociated === 'viaf' ? <View key={index} style={styles.row}>
                                <Text key={index}
                                      style={styles.label}>{translateKey(exId.dataAssociated, language)}:</Text><Link
                                src={renderViafUrl(exId.stringValue)}>{renderViafUrl(exId.stringValue)}</Link>
                            </View> : <View key={index} style={styles.row}>
                                <Text
                                    style={styles.label}>{translateKey(exId.dataAssociated, language)}:</Text><Link
                                src={exId.stringValue}>{exId.stringValue}</Link>
                            </View>
                        })}</View>


                </View>
            </View>
            <View style={[styles.paperSection]}>
                <Text style={styles.sectionHeader}>{translateKey('detailInfo', language)}</Text>
                <Text style={styles.sectionSubheader}>{translateKey('detailInfoTooltip', language)}</Text>
                {renderRows()}
                {renderMissingRows()}

            </View>
            {wikidata && <View style={[styles.paperSection]}>
                <Text style={styles.sectionHeader}>{translateKey('wikidataInfo', language)}</Text>
                <Text style={styles.sectionSubheader}>{translateKey('wikidataPdfTooltip', language)}</Text>
                {renderWikidataRows()}
            </View>}
        </Page>
        {!!chartsImages && renderPagesWithCharts()}
        {worksAbout.length > 0 && <Page size="A4" style={styles.page}>
            <View style={[styles.paperSection, styles.flexGrowSection]}>
                <Text style={styles.sectionHeader}>{translateKey('worksAboutData', language)}</Text>
                {renderWorksAboutPages()}
            </View>
        </Page>}
        {!!works && <Page size="A4" style={styles.page}>
            <View style={[styles.paperSection, styles.flexGrowSection]}>
                <Text style={styles.sectionHeader}>{translateKey('timeline', language)}</Text>
                {renderTimelineInfo()}
            </View>
        </Page>}
    </Document>
}
export default DescriptorPDF