import ReactGA from 'react-ga4';
import {Box, createTheme, LinearProgress, ThemeProvider} from "@mui/material";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import React, {Suspense, useEffect} from "react";
import {COLORS} from "styles/colors";
import {LanguageProvider} from "./context/LanguageContext";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import DescriptorDetailView from "./components/descriptorInfo/DescriptorDetailView";
import AdditionalInfoBar from "components/common/AdditionalInfoBar";
import ContainerWithSearch from "components/containers/ContainerWithSearch";
import DescriptorsList from "components/descriptorsList/DescriptorsList";
import Sitemap from "./components/footer/Sitemap";
import MARCRecordsSearch from "./components/marc-record-search-engine/MARCRecordsSearch";
import HomePage from "./components/home/HomePage";
import Documentation from "./components/footer/Documentation";
import {useCookies} from "react-cookie";
import {SearchEngineContext} from "./consts/search-engine-consts";
import PageNotFound from "./components/404/PageNotFound";
import ScreenLoading from "./components/home/ScreenLoading";
import {HelmetProvider} from "react-helmet-async";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE);
ReactGA.initialize([{
    trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE,
    gaOptions: {siteSpeedSampleRate: 100}
}]);

const theme = createTheme({
    typography: {
        fontFamily: 'Noto Sans',
        allVariants: {
            color: COLORS.dark
        }
    },
    palette: {
        primary: {
            main: COLORS.dark,
            contrastText: COLORS.white,

        },
        secondary: {
            main: COLORS.light,
            contrastText: COLORS.dark,
        },
        background: {
            default: COLORS.gray,
            paper: COLORS.white
        },
    },

});

const queryClient = new QueryClient()

const App = () => {
    const [cookies, setCookies] = useCookies(['source', 'searchContext', 'displayMode', 'uuid']);
    const helmetContext = {};

    useEffect(() => {
        if (!cookies.source) setCookies('source', 'DBN')
        if (!cookies.searchContext) setCookies('searchContext', SearchEngineContext.DBN)
        if (!cookies.displayMode) setCookies('displayMode', 'cards')
        if (!cookies.uuid) setCookies('uuid', crypto.randomUUID())
    },)

    return <HelmetProvider context={helmetContext}>

        <Suspense fallback={<Box sx={{width: '100%'}}>
            <LinearProgress/>
        </Box>}>
            <LanguageProvider>
                <ScreenLoading/>
                <QueryClientProvider client={queryClient}>

                    <ThemeProvider theme={theme}>
                        <AdditionalInfoBar/>

                        <BrowserRouter>

                            <Routes>
                                <Route path="/" element={<ContainerWithSearch showSearch={false}
                                                                              alwaysShowFooter><HomePage/></ContainerWithSearch>}/>
                                <Route path="/descriptors"
                                       element={<ContainerWithSearch><DescriptorsList/></ContainerWithSearch>}/>
                                <Route path="/descriptor-details/:recordId"
                                       element={<ContainerWithSearch><DescriptorDetailView/></ContainerWithSearch>}/>
                                <Route path="/documentation"
                                       element={<ContainerWithSearch
                                           alwaysShowFooter><Documentation/></ContainerWithSearch>}/>
                                <Route path="/sitemap"
                                       element={<ContainerWithSearch
                                           alwaysShowFooter><Sitemap/></ContainerWithSearch>}/>
                                <Route path="/search-engine"
                                       element={<ContainerWithSearch
                                           showSearch={false}><MARCRecordsSearch/></ContainerWithSearch>}/>
                                <Route path="*"
                                       element={<ContainerWithSearch><PageNotFound/></ContainerWithSearch>}/>
                            </Routes>
                        </BrowserRouter>
                    </ThemeProvider>
                </QueryClientProvider>
            </LanguageProvider></Suspense>
    </HelmetProvider>

}

export default App